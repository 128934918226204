import React, {useCallback, useEffect, useMemo, useState} from "react"
import WordpressWorkspace from "components/WordpressWorkspace"
import {useMutation, useQuery} from "@apollo/client"
import {LinearProgress, Typography, Box, IconButton} from "@mui/material"
import EXTEND_LAB_SESSIONS_TIME from "api/apollo/mutations/EXTEND_LAB_SESSIONS_TIME"
import FINISH_LAB_SESSION from "api/apollo/mutations/FINISH_LAB_SESSION"
import Dialog from "components/Dialog"
import GET_LAB_SESSION from "api/apollo/queries/GET_LAB_SESSION"
import {LINKS} from "consts/links"
import {
  ExtendLabSessionTimeMutation,
  ExtendLabSessionTimeMutationVariables,
  FinishLabSessionMutation,
  FinishLabSessionMutationVariables,
  GetLabSessionQuery,
  GetLabSessionQueryVariables
} from "generated/graphql"
import {FullScreen, useFullScreenHandle} from "react-full-screen"
import {Helmet} from "react-helmet-async"
import {useDispatch} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import {useIdleTimer} from "react-idle-timer"
import CustomErrorClass from "store/slices/notifier/customErrorClass"
import {customErrors} from "store/slices/notifier/errorObject"
import {handleError} from "store/slices/notifier/notifier"
import sessionLoadingPic from "assets/svg/sessionLoading.svg"
import Header from "./components/Header"
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import hexToRgba from "utils/hexToRgba"
import {kpNeutralsColors} from "consts"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"

const timeout = 12e5

type ModalType = "CLOSE_LAB" | "EXTEND_LAB_SESSION_TIME" | "LOW_ACTIVITY"

interface Props {
  isStudent?: boolean
  navigatePath?: string
}

export default function WPLabWindow(props: Props) {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fullScreen = useFullScreenHandle()

  const [timer, setTimer] = useState(1000)
  const [iframeWasLoaded, setIframeWasLoaded] = useState(false)
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [expirationDate, setExpirationDate] = useState(null)
  const [isExpired, setIsExpired] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [remainingTime, setRemainingTime] = useState({
    minutes: Math.floor((timeout / 1000 / 60) % 60),
    seconds: Math.floor((timeout / 1000) % 60)
  })
  const [expanded, setExpanded] = useState(false)
  // const [showLib, setShowLib] = useState(true)

  const {data, error, stopPolling} = useQuery<
    GetLabSessionQuery,
    GetLabSessionQueryVariables
  >(GET_LAB_SESSION, {
    variables: {
      labSessionId: params.labSessionId
    },
    pollInterval: 2000
  })

  const [finishLabSession] = useMutation<
    FinishLabSessionMutation,
    FinishLabSessionMutationVariables
  >(FINISH_LAB_SESSION)

  const [extendLabSessionTimeMutation] = useMutation<
    ExtendLabSessionTimeMutation,
    ExtendLabSessionTimeMutationVariables
  >(EXTEND_LAB_SESSIONS_TIME)

  const modalTitle = useMemo(() => {
    switch (modalType) {
      case "CLOSE_LAB":
        return "Save Progress And Close"
      case "EXTEND_LAB_SESSION_TIME":
        return "Extend the time"
      case "LOW_ACTIVITY":
        return "Attention!"
      default:
        return null
    }
  }, [modalType])

  const isInstanceReady = useMemo(() => {
    return data?.getLabSession?.instance?.status === "running" || false
  }, [data])

  const instance = useMemo(() => {
    return data?.getLabSession?.instance || null
  }, [data])

  const onIdle = () => {
    handleFinishLabSession()
  }

  const onActive = () => {
    setModalType(null)
  }

  const onPrompt = () => {
    setModalType("LOW_ACTIVITY")
  }

  const onAction = () => {
    if (showOverlay) {
      setShowOverlay(false)
    }
  }

  const {activate, getRemainingTime} = useIdleTimer({
    onIdle,
    onAction,
    onPrompt,
    onActive,
    timeout,
    promptBeforeIdle: 3e5,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime({
        minutes: Math.floor((getRemainingTime() / 1000 / 60) % 60),
        seconds: Math.floor((getRemainingTime() / 1000) % 60)
      })
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (!isExpired) {
      if (timer < 100) {
        setModalType("EXTEND_LAB_SESSION_TIME")
      }
      if (timer <= -300000) {
        handleFinishLabSession()
        setIsExpired(true)
      }
    }
  }, [timer, isExpired])

  useEffect(() => {
    if (isInstanceReady) {
      stopPolling()
    }
  }, [isInstanceReady])

  useEffect(() => {
    return () => {
      stopPolling()
    }
  }, [])

  useEffect(() => {
    if (error || ["deleted", "failed"].includes(instance?.status) || data?.getLabSession?.isFinished) {
      dispatch(handleError(error || new CustomErrorClass(customErrors.INSTANCE_UNAVAILABLE)))
      navigate(props.navigatePath || LINKS.dashboard)
    }
  }, [error, instance, data])

  const handleFullScreen = useCallback(() => {
    if (fullScreen.active) {
      setIsFullScreen(false)
      fullScreen.exit()
    } else {
      setIsFullScreen(true)
      fullScreen.enter()
    }
  }, [fullScreen.active])

  async function handleFinishLabSession() {
    await finishLabSession({
      variables: {
        labSessionId: params.labSessionId
      }
    })
    navigate(props.navigatePath || LINKS.dashboard)
  }

  const handleClose = async (closeType: string) => {
    if (closeType === "confirm") {
      await handleFinishLabSession()
      return
    }

    if (closeType === "continue") {
      setModalType(null)
      return
    }

    if (closeType === "extendLabSessionTime") {
      await extendLabSessionTimeMutation({
        variables: {
          labSessionId: params.labSessionId
        }
      }).then(res => {
        setExpirationDate(res.data.extendLabSessionTime.expirationDate)
        setModalType(null)
      }).catch(err => {
        dispatch(handleError(err))
        handleFinishLabSession()
      })
    }
  }

  useEffect(() => {
    if ((remainingTime.minutes < 20) &&
      (remainingTime.minutes % 5 === 0) &&
      !showOverlay
    ) {
      setShowOverlay(true)
    }
  }, [remainingTime])

  return (
    <>
      <Helmet>
        <title>
          {props.isStudent ? "WordPress Lab" : "Student WordPress Lab"}
        </title>
      </Helmet>
      <Box position="relative" maxHeight="100vh" overflow="auto">
        {!isInstanceReady ? (
          <Box
            width="100%"
            height="100vh"
            minHeight="560px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={4}>
            <Box
              position="relative"
              width="50vh"
              height="50vh"
              minWidth="300px"
              minHeight="300px">
              <img
                alt="Exam Session Loading"
                src={sessionLoadingPic}
                width="100%"
                height="100%"
              />
            </Box>
            <Typography variant="h6" align="center" fontWeight={700}>
              We are currently preparing your test environment and it may take few minutes.<br/>
              Please don't refresh or close the tab.
            </Typography>
            <Box width="30%">
              <LinearProgress/>
            </Box>
          </Box>
        ) : (
          <FullScreen handle={fullScreen}>
            <Box
              position="relative"
              width="100%"
              height="100vh"
              minHeight="560px"
              bgcolor="white.main"
              overflow="hidden"
              sx={{
                p: expanded ? 0 : 3,
                transition: ".3s"
              }}
            >
              <Box
                width="100%"
                height="100%"
                minHeight="100%"
                display="flex"
                flexDirection="column"
                sx={{
                  gap: expanded ? 0 : 1,
                  transition: ".3s"
                }}
                position="relative"
              >
                <Box
                  position="relative"
                  sx={{
                    height: expanded ? "0px" : "98px",
                    maxHeight: expanded ? "0px" : "98px",
                    overflow: "hidden",
                    transition: ".3s"
                  }}
                >
                  <Header
                    id={params?.labSessionId}
                    name={data?.getLabSession?.createdByUser?.username}
                    expirationDate={expirationDate || data?.getLabSession?.expirationDate}
                    instance={instance}
                    onFinish={() => setModalType("CLOSE_LAB")}
                    onTimerChange={setTimer}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow={1}
                >
                  <Box
                    sx={{
                      height: "100%",
                      position: "relative"
                    }}
                  >
                    <Box
                      position="absolute"
                      zIndex={1}
                      width="100%"
                      left={0}
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <IconButton
                        sx={{
                          top: expanded ? 0 : -24,
                          p: "12px",
                          backgroundColor: "#ededed",
                          transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                          color: "rgba(28, 27, 31, 1)",
                          transition: ".3s",
                          "&:hover": {
                            backgroundColor: "#ededed"
                          },
                          "& .MuiTouchRipple-root": {
                            display: "none"
                          }
                        }}
                        onClick={() => setExpanded(!expanded)}>
                        <ArrowUpwardIcon/>
                      </IconButton>
                    </Box>
                    <WordpressWorkspace
                      expanded={expanded}
                      iframeWasLoaded={iframeWasLoaded}
                      setIframeWasLoaded={setIframeWasLoaded}
                      instance={instance}
                    />
                    <Box
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        right: expanded ? 24 : 36,
                        bottom: expanded? 24 : 36,
                        cursor: "pointer",
                        background: hexToRgba(kpNeutralsColors.lightGray, .3),
                        borderRadius: "8px",
                        p: "2px",
                        "&:hover": {
                          background: hexToRgba(kpNeutralsColors.lightGray, .6),
                          transition: ".3s"
                        },
                        transition: ".3s"
                      }}
                      onClick={handleFullScreen}
                    >
                      {isFullScreen ? (
                        <FullscreenExitIcon color="white"/>
                      ) : (
                        <FullscreenIcon color="white"/>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Dialog
                open={modalType === "CLOSE_LAB"}
                title={modalTitle}
                actions={[{
                  label: "Close",
                  color: "secondary",
                  variant: "outlined",
                  onClick: () => handleClose("continue"),
                  sx: {
                    mr: "auto"
                  }
                }, {
                  label: "SAVE SESSION",
                  color: "lightBlue",
                  variant: "contained",
                  onClick: () => handleClose("confirm")
                }]}>
                <Box>
                  <Typography variant="body1">
                    All your current progress will be securely saved, allowing you to resume your work at a later time. Would you like to proceed with closing the lab?
                  </Typography>
                </Box>
              </Dialog>
              <Dialog
                open={modalType === "EXTEND_LAB_SESSION_TIME"}
                title={modalTitle}
                actions={[{
                  label: "Close",
                  color: "secondary",
                  variant: "outlined",
                  onClick: () => handleClose("confirm"),
                  sx: {
                    mr: "auto"
                  }
                }, {
                  label: "Yes",
                  color: "lightBlue",
                  variant: "contained",
                  onClick: () => handleClose("extendLabSessionTime")
                }]}>
                <Box minWidth="24rem">
                  <Typography variant="body1">
                    Do you want to extend the time?
                  </Typography>
                </Box>
              </Dialog>
              <Dialog
                open={modalType === "LOW_ACTIVITY"}
                title={modalTitle}
                actions={[{
                  label: "Close Lab",
                  color: "secondary",
                  variant: "outlined",
                  onClick: () => handleClose("confirm"),
                  sx: {
                    mr: "auto"
                  }
                },
                  {
                    label: "Keep Working",
                    color: "lightBlue",
                    variant: "contained",
                    onClick: () => activate()
                  }]}>
                <Box>
                  <Typography variant="h6" align="center" mb={1} color="error">
                    0{remainingTime.minutes}:{remainingTime.seconds < 10 && "0"}{remainingTime.seconds}
                  </Typography>
                  <Typography variant="body1">
                    We've noticed you haven't been active in the WordPress lab for past minutes. To manage our lab resources efficiently, we need to check if you're still there.
                    Please click on the 'Keep Working' button within the next 5 minutes to continue your session.
                    If no action is taken, your current lab session will be automatically closed to free up resources for other students.
                  </Typography>
                </Box>
              </Dialog>
            </Box>
          </FullScreen>
        )}
      </Box>
    </>
  )
}
