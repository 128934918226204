import {useLazyQuery, useMutation} from "@apollo/client"
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Switch,
  Typography,
  Select,
  useTheme,
  CircularProgress
} from "@mui/material"
import ASSIGN_COURSES from "api/apollo/mutations/ASSIGN_COURSES"
import ASSIGN_LAB from "api/apollo/mutations/ASSIGN_LAB"
import ASSIGN_PRACTICE_TEST from "api/apollo/mutations/ASSIGN_PRACTICE_TEST"
import GET_EXAM_CODES from "api/apollo/queries/GET_EXAM_CODES"
import GET_LICENSES from "api/apollo/queries/GET_LICENSES"
import DataTable from "components/DataTable"
import {DataTableSchema} from "components/DataTable/types.t"
import {Formik} from "formik"
import {
  AssignCoursesMutation,
  AssignCoursesMutationVariables,
  AssignLabMutation,
  AssignLabMutationVariables,
  AssignPracticeTestMutation,
  AssignPracticeTestMutationVariables,
  GetExamCodesQuery,
  GetExamCodesQueryVariables,
  GetLicensesQuery,
  GetLicensesQueryVariables,
  LearnDashCourse,
  License,
  User
} from "generated/graphql"
import {uniqBy} from "lodash"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import isWordPressExam from "utils/isWordPressExam"
import * as Yup from "yup"

interface Props {
  students?: DeepPartial<User>[]
  onAfterSubmit: () => void
  schoolId?: string
}

const fieldsInitialValue = {
  courseFields: {
    courseLicenseId: "",
    courseIds: []
  },
  practiceFields: {
    practiceLicenseId: ""
  },
  labFields: {
    labLicenseId: ""
  }
}

export default function AssignInventoryForm(props: Props) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const currentSchool = useSelector((store) => store.schoolSlice.currentSchool)

  const schoolId = useMemo(() => {
    return props.schoolId || currentSchool
  }, [props.schoolId, currentSchool])

  const [showCourseFields, setShowCourseFields] = useState(false)
  // const [showAssignCoursesSection, setShowAssignCoursesSection] = useState(true)
  const [showPracticeFields, setShowPracticeFields] = useState(false)
  const [showLabFields, setShowLabFields] = useState(false)
  const [courseLicensesByExamCode, setCourseLicensesByExamCode] = useState<Partial<License>[]>([])
  const [practiceLicensesByExamCode, setPracticeLicensesByExamCode] = useState<Partial<License>[]>([])
  const [courses, setCourses] = useState<Partial<LearnDashCourse>[]>([])

  const [licensesQueryFetch, licensesQuery] = useLazyQuery<
    GetLicensesQuery,
    GetLicensesQueryVariables
  >(GET_LICENSES, {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  })

  const [examCodesQueryFetch, examCodesQuery] = useLazyQuery<
    GetExamCodesQuery,
    GetExamCodesQueryVariables
  >(GET_EXAM_CODES)

  const [assignCourses, {loading: assignCoursesLoading}] = useMutation<
    AssignCoursesMutation,
    AssignCoursesMutationVariables
  >(ASSIGN_COURSES)

  const [assignPracticeTest, {loading: assignPracticeTestLoading}] = useMutation<
    AssignPracticeTestMutation,
    AssignPracticeTestMutationVariables
  >(ASSIGN_PRACTICE_TEST)

  const [assignLab, {loading: assignLabLoading}] = useMutation<
    AssignLabMutation,
    AssignLabMutationVariables
  >(ASSIGN_LAB)

  const formInitialValues = useMemo(() => {
    return {
      courseProgram: "",
      courseLicenseId: "",
      courseIds: [],
      practiceExamCode: "",
      practiceLicenseId: "",
      labLicenseId: ""
    }
  }, [])

  const loading = useMemo(() => {
    return assignLabLoading || assignCoursesLoading || assignPracticeTestLoading || false
  }, [assignLabLoading, assignCoursesLoading, assignPracticeTestLoading])

  const licenses = useMemo(() => {
    return licensesQuery.data?.licenses?.items || []
  }, [licensesQuery.data])

  const examCodes = useMemo(() => {
    return examCodesQuery.data?.exams?.items || []
  }, [examCodesQuery.data])

  const filteredCourseLicenses = useMemo(() => {
    return licenses.filter(i => new Date(i.expirationDate) >= new Date() && i.coursesRemain > 0)
  }, [licenses])

  const filteredPracticeLicenses = useMemo(() => {
    return licenses.filter(i => new Date(i.expirationDate) >= new Date() && i.practiceVouchersRemain > 0)
  }, [licenses])

  const filteredLabLicenses = useMemo(() => {
    return licenses.filter(i => new Date(i.expirationDate) >= new Date() && i.labsRemain > 0)
  }, [licenses])

  const filteredCourseExamCodes = useMemo(() => {
    return (examCodes || []).filter(i => {
      return !!filteredCourseLicenses.find(license => {
        if (!license.isUniversalLicense) {
          return license.examCodes.includes(i.code)
        }

        return true
      })
    })
  }, [examCodes, filteredCourseLicenses])

  const filteredPracticeExamCodes = useMemo(() => {
    return (examCodes || []).filter(i => {
      return !!filteredPracticeLicenses.find(license => {
        if (!license.isUniversalLicense) {
          return license.examCodes.includes(i.code)
        }

        return true
      })
    })
  }, [examCodes, filteredPracticeLicenses])

  const filteredLabExamCodes = useMemo(() => {
    return examCodes.filter(i => {
      if (!i?.displayName?.value?.toLowerCase()?.includes("wordpress")) return false
      return filteredLabLicenses.some(license => {
        return license.isUniversalLicense || license.examCodes.includes(i.code)
      })
    })
  }, [examCodes, filteredLabLicenses])

  const filteredPrograms = useMemo(() => {
    return uniqBy([...filteredPracticeExamCodes, ...filteredCourseExamCodes, ...filteredLabExamCodes], (obj) => obj.code)
  }, [filteredPracticeExamCodes, filteredCourseExamCodes, filteredLabExamCodes])

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    const mutations = []

    const handleAssignCourses = () => (
      assignCourses({
        variables: {
          students: props.students.map(i => i._id),
          licenseId: data.courseLicenseId,
          courseIds: data.courseIds
        }
      })
    )

    const handleAssignPracticeTest = () => (
      assignPracticeTest({
        variables: {
          studentIds: props.students.map(i => i._id),
          examCode: data.courseProgram,
          licenseId: data.practiceLicenseId
        }
      })
    )

    const handleAssignLab = () => (
      assignLab({
        variables: {
          studentIds: props.students.map(i => i._id),
          licenseId: data.labLicenseId
        }
      })
    )

    showCourseFields && mutations.push(handleAssignCourses())
    showPracticeFields && mutations.push(handleAssignPracticeTest())
    showLabFields && mutations.push(handleAssignLab())

    if (mutations.length) {
      Promise.all(mutations).then(() => {
        dispatch(notifyUser({
          message: "INVENTORY_ASSIGN_SUCCESS",
          variant: "success"
        }))
        props.onAfterSubmit()
      }).catch(err => {
        dispatch(handleError(err))
      })
    }
  }

  useEffect(() => {
    examCodesQueryFetch()
    licensesQueryFetch({
      variables: {
        schoolId
      }
    })
  }, [schoolId])

  const tableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Last Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "lastName"
      },
      {
        type: "text",
        headerText: "First Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "firstName"
      }
    ]
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        courseProgram: Yup.string().required("Program is required"),
        courseLicenseId: showCourseFields && Yup.string().required("Inventory is required"),
        courseIds: showCourseFields && Yup.array().min(1, "Courses is required"),
        // practiceExamCode: showPracticeFields && Yup.string().required("Practice Test is required"),
        practiceLicenseId: showPracticeFields && Yup.string().required("Inventory is required"),
        labLicenseId: showLabFields && Yup.string().required("Inventory is required")
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        dirty
      }) => (
        <form onSubmit={handleSubmit}>
          <Box width="40vw">
            <Box px={4}>
              <Typography variant="h5" textAlign="center" p={2}>
                Assign Inventory
              </Typography>
              <Box>
                <Box mt={2} display="flex" alignItems="center">
                  <Typography variant="h6" mr={2}>
                    Selected students:
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {props.students?.length}
                  </Typography>
                </Box>
                <Box width="100%">
                  <DataTable schema={tableSchema} data={props.students} fullWidth/>
                </Box>
                <Box mt={2}>
                  <Typography variant="h6" mb={2}>
                    Program
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel error={!!(touched.courseProgram && errors.courseProgram)}>
                      Select Program
                    </InputLabel>
                    <Select
                      name="courseProgram"
                      onBlur={handleBlur}
                      label="Select Program"
                      error={!!(touched.courseProgram && errors.courseProgram)}
                      value={values.courseProgram || ""}
                      onChange={e => {
                        handleChange(e)

                        const code = e.target.value

                        setFieldValue("courseLicenseId", "")
                        setFieldValue("courseIds", [])
                        setFieldValue("practiceLicenseId", "")
                        setFieldValue("labLicenseId", "")

                        if (isWordPressExam(examCodes, code)) {
                          setShowLabFields(false)
                        }

                        const isPractice = filteredPracticeExamCodes.some(i => i.code === code)
                        const isCourse = filteredCourseExamCodes.some(i => i.code === code)

                        // setShowAssignCoursesSection(isCourse)
                        if (isCourse) {
                          const courseLicenses = filteredCourseLicenses.filter(i => {
                            if (i.isUniversalLicense) {
                              return true
                            }

                            return i.examCodes.includes(e.target.value)
                          }) as Partial<License>[]

                          !courseLicenses.length && setShowCourseFields(false)
                          setCourseLicensesByExamCode(courseLicenses)
                        } else {
                          setCourseLicensesByExamCode([])
                          setShowCourseFields(false)
                        }

                        if (isPractice) {
                          const practiceLicenses = filteredPracticeLicenses.filter(i => {
                            if (i.isUniversalLicense) {
                              return true
                            }
                            return i.examCodes.includes(code)
                          }) as Partial<License>[]

                          !practiceLicenses.length && setShowPracticeFields(false)
                          setPracticeLicensesByExamCode(practiceLicenses)
                        } else {
                          setPracticeLicensesByExamCode([])
                          setShowPracticeFields(false)
                        }
                      }}>
                      {licensesQuery.loading && (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" py={2}>
                          <CircularProgress size={24}/>
                        </Box>
                      )}
                      {(filteredPrograms || []).map(i => (
                        <MenuItem key={i.code} value={i.code}>
                          {`${i.displayCode} - ${i.displayName.value}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={4}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h6" mr={1}>
                      Assign Courses
                    </Typography>
                    <Switch
                      disabled={!(values.courseProgram && courseLicensesByExamCode.length)}
                      checked={showCourseFields}
                      onChange={(_e, checked) => {
                        setShowCourseFields(checked)
                        setValues({
                          ...values,
                          ...fieldsInitialValue.courseFields,
                          courseProgram: values.courseProgram
                        })
                      }}
                      inputProps={{"aria-label": "controlled"}}
                    />
                  </Box>
                  {showCourseFields && (
                    <Box>
                      <Box mb={2}>
                        <FormControl fullWidth>
                          <InputLabel error={!!(touched.courseLicenseId && errors.courseLicenseId)}>
                            Select Inventory
                          </InputLabel>
                          <Select
                            disabled={!values.courseProgram && !courseLicensesByExamCode.length}
                            name="courseLicenseId"
                            onBlur={handleBlur}
                            label="Select Inventory"
                            error={!!(touched.courseLicenseId && errors.courseLicenseId)}
                            value={values.courseLicenseId || ""}
                            onChange={(e) => {
                              handleChange(e)
                              setFieldValue("courseIds", [])
                              setCourses(filteredCourseLicenses.find(i => {
                                return i._id === e.target.value
                              })?.courses || [])
                            }}>
                            {(courseLicensesByExamCode || []).map(i => (
                              <MenuItem key={i._id} value={i._id} disabled={!i.coursesRemain}>
                                {i.name}
                                <span style={{
                                  marginLeft: theme.spacing(.5),
                                  color: i.coursesRemain ? theme.palette.success.main : theme.palette.error.main
                                }}>
                              (Availability: {i.coursesRemain || 0})
                            </span>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl fullWidth>
                          <InputLabel error={!!(touched.courseIds && errors.courseIds)}>
                            Select Available Courses:
                          </InputLabel>
                          <Select
                            multiple
                            renderValue={(selected) => {
                              if (!Array.isArray(selected)) return ""
                              return selected.map(val => courses.find(i => i.id === val)?.title.raw || val).join(", ")
                            }}
                            onBlur={handleBlur}
                            disabled={!values.courseLicenseId || !courses.length}
                            name="courseIds"
                            label="Select Available Courses:"
                            error={!!(touched.courseIds && errors.courseIds)}
                            value={values.courseIds}
                            onChange={handleChange}>
                            {courses.map(i => (
                              <MenuItem key={i.id} value={i.id}>
                                <Checkbox size="small" checked={values.courseIds.indexOf(i.id) > -1}/>
                                <ListItemText primary={i.title.raw}/>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                  <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h6" mr={1}>
                      Assign Practice
                    </Typography>
                    <Switch
                      disabled={!(values.courseProgram && practiceLicensesByExamCode.length)}
                      checked={showPracticeFields}
                      onChange={(_e, checked) => {
                        setShowPracticeFields(checked)
                        setValues({
                          ...values,
                          ...fieldsInitialValue.practiceFields,
                          courseProgram: values.courseProgram
                        })
                      }}
                      inputProps={{"aria-label": "controlled"}}
                    />
                  </Box>
                  {showPracticeFields && (
                    <Box>
                      <Box mb={2}>
                        <FormControl fullWidth>
                          <InputLabel error={!!(touched.practiceLicenseId && errors.practiceLicenseId)}>
                            Select Inventory
                          </InputLabel>
                          <Select
                            disabled={!values.courseProgram}
                            name="practiceLicenseId"
                            onBlur={handleBlur}
                            label="Select Inventory"
                            error={!!(touched.practiceLicenseId && errors.practiceLicenseId)}
                            value={values.practiceLicenseId || ""}
                            onChange={handleChange}>
                            {(practiceLicensesByExamCode || []).map(i => (
                              <MenuItem key={i._id} value={i._id} disabled={!i.practiceVouchersRemain}>
                                {i.name}
                                <span style={{
                                  marginLeft: theme.spacing(.5),
                                  color: i.practiceVouchersRemain ? theme.palette.success.main : theme.palette.error.main
                                }}>
                                  (Availability: {i.practiceVouchersRemain || 0})
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                  {isWordPressExam(examCodes, values.courseProgram) && (
                    <>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Typography variant="h6" mr={1}>
                          WordPress Lab
                        </Typography>
                        <Switch
                          disabled={!filteredLabLicenses.length}
                          checked={showLabFields}
                          onChange={(_e, checked) => {
                            setShowLabFields(checked)
                            setValues({
                              ...values,
                              ...fieldsInitialValue.labFields,
                              courseProgram: values.courseProgram
                            })
                          }}
                          inputProps={{"aria-label": "controlled"}}
                        />
                      </Box>
                      {showLabFields && (
                        <Box>
                          <Box mb={2}>
                            <FormControl fullWidth>
                              <InputLabel error={!!(touched.labLicenseId && errors.labLicenseId)}>
                                Select Inventory
                              </InputLabel>
                              <Select
                                name="labLicenseId"
                                onBlur={handleBlur}
                                label="Select Inventory:"
                                disabled={!filteredLabLicenses.length}
                                error={!!(touched.labLicenseId && errors.labLicenseId)}
                                value={values.labLicenseId || ""}
                                onChange={handleChange}>
                                {(filteredLabLicenses || []).map(i => (
                                  <MenuItem key={i._id} value={i._id} disabled={!i.labsRemain}>
                                    {i.name}
                                    <span style={{
                                      marginLeft: theme.spacing(.5),
                                      color: i.labsRemain ? theme.palette.success.main : theme.palette.error.main
                                    }}>
                                      (Availability: {i.labsRemain || 0})
                                    </span>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" p={4}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!dirty || loading || !(showLabFields || showCourseFields || showPracticeFields)}>
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}
