import {gql} from "@apollo/client"

export default gql`
  query GetMyProgress($schoolId: GraphQLObjectId, $examCode: String!) {
    me {
      _id
      studentProgress(schoolId: $schoolId, examCode: $examCode) {
        progress {
          answerRate
          averageObjectiveScore
          objective {
            _id
            createdAt
            examCode
            objectiveCode
            title {
              value
              en
            }
            updatedAt
            weight
          }
          objectiveRate
          successRate
        }
      }
    }
  }
`
