import {TestingGroup} from "generated/graphql"
import {startCase} from "lodash"
import makeStyles from "@mui/styles/makeStyles"
import {Box, Button, Link, Typography} from "@mui/material"
import formatDate from "utils/formatDate"
import CopyToClipboard from "components/CopyToClipboard"
import {LINKS} from "consts/links"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import {useTheme} from "@mui/styles"
import TextWithViewMore from "components/TextWithViewMore"

const useStyles = makeStyles(() => ({
  img: {
    display: "flex",
    width: "48px",
    height: "48px",
    transition: ".3s",
    "&:hover": {
      opacity: .6,
      transition: ".3s"
    }
  }
}))

interface Props {
  examGroup?: Partial<TestingGroup>
  canceledBy?: string
  cancelReason?: string
  createdAt?: string
  displayName?: string
  examCode?: string
  isTesting?: boolean
  duration?: string
  status?: string
  mode?: string
  retake?: boolean
  disableAi?: boolean
  voucherCode?: string
  isCompleted?: boolean
  isPassed?: boolean
  certificateId?: string
  type?: string
  updatedAt?: string
  handleResetCertificate?: () => void
}

export default function ExamCard({
  examGroup,
  createdAt,
  displayName,
  examCode,
  isTesting,
  duration,
  status,
  mode,
  canceledBy,
  cancelReason,
  retake,
  disableAi,
  voucherCode,
  isCompleted,
  isPassed,
  certificateId,
  updatedAt,
  handleResetCertificate,
  type
}: Props) {
  const s = useStyles()
  const theme = useTheme()

  return (
    <Box p={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          paddingBottom: 1,
          marginBottom: 2,
          borderBottom: theme => `1px solid ${theme.palette.lightGrey.main}`
        }}>
        <Box>
          <img
            alt={displayName || "Exam logo"}
            src={`/${isTesting ? "practiceDetailsIcons" : "examIcons"}/${examCode}.svg`}
            onError={(e) => {
              (e.target as HTMLImageElement).src = "/examIcons/unknown.png"
            }}
            style={{
              flexShrink: 0,
              height: 96,
              width: 96,
              objectFit: "contain"
            }}
          />
        </Box>
        <Box>
          <Typography color="primary" variant="subtitle2">
            {examCode?.toUpperCase() || "Unknown"}
          </Typography>
          <Typography color="primary" variant="subtitle2" fontWeight="bold">
            {displayName || "Unknown"}
          </Typography>
        </Box>
      </Box>
      <Box>
        {isTesting && mode && (
         <>
           <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
             <Typography whiteSpace="nowrap">
               Type:
             </Typography>
             <Typography whiteSpace="nowrap">
               {mode === "Practice Mode" ? "Practice Test" : "Exam Simulator"}
             </Typography>
           </Box>
           <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
             <Typography whiteSpace="nowrap">
               Mode:
             </Typography>
             <Typography whiteSpace="nowrap">
               {mode}
             </Typography>
           </Box>
           {disableAi && (
             <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
               <Typography whiteSpace="nowrap">
                 AI Helper:
               </Typography>
               <Typography
                 whiteSpace="nowrap"
                 sx={{
                   color: theme.palette.success.main
                 }}>
                 Enabled
               </Typography>
             </Box>
           )}
         </>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
          <Typography whiteSpace="nowrap">
            Date:
          </Typography>
          <Typography whiteSpace="nowrap">
            {createdAt ? formatDate(createdAt, "full-en") : "Unknown"}
          </Typography>
        </Box>
        {duration && (
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
            <Typography whiteSpace="nowrap">
              Duration:
            </Typography>
            <Typography whiteSpace="nowrap">
              {duration}
            </Typography>
          </Box>
        )}
        {examGroup?.proctoring && (
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
            <Typography whiteSpace="nowrap">
              Proctoring Mode:
            </Typography>
            <Typography whiteSpace="nowrap">
              {startCase(examGroup.proctoring)}
            </Typography>
          </Box>
        )}
        {examGroup?.teacher && (
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
            <Typography whiteSpace="nowrap">
              Teacher:
            </Typography>
            <Typography whiteSpace="nowrap">
              {examGroup.teacher?.fullName}
            </Typography>
          </Box>
        )}
        {examGroup?.proctor && (
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
            <Typography whiteSpace="nowrap">
              Proctor:
            </Typography>
            <Typography whiteSpace="nowrap">
              {examGroup.proctor.fullName}
            </Typography>
          </Box>
        )}
        {!isTesting && (
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
            <Typography whiteSpace="nowrap">
              Retake:
            </Typography>
            <Typography whiteSpace="nowrap">
              {retake ? "Yes" : "No"}
            </Typography>
          </Box>
        )}
        {(!isTesting || !duration) && status && (
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
            <Typography whiteSpace="nowrap">
              Status:
            </Typography>
            <Typography whiteSpace="nowrap">
              {status}
            </Typography>
          </Box>
        )}
        {!isTesting && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={.5}>
            <Typography whiteSpace="nowrap">
              Voucher Code:
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography>
                {voucherCode ? `${voucherCode.slice(0, 3)}...${voucherCode.slice(-3)}` : "N/A"}
              </Typography>
              <CopyToClipboard value={voucherCode}/>
            </Box>
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
          <Typography whiteSpace="nowrap">
            {isPassed && !isTesting && certificateId ? "Certificate" : "Passed"}:
          </Typography>
          {!isCompleted ? (
            "N/A"
          ) : (
            <Typography
              style={{
                marginLeft: 5,
                display: "flex",
                alignItems: "center",
                color: !isCompleted ? (
                  theme.palette.primary.main
                ) : (
                  isPassed
                ) ? (
                  theme.palette.success.main
                ) : (
                  theme.palette.error.main
                )}
              }>
                {isPassed && !isTesting && certificateId ? (
                  <Box display="flex" gap={1} alignItems="center">
                    <Link
                      target="_blank"
                      href={`${LINKS.certificate}/${certificateId}`}
                      underline="always"
                      variant="body2"
                      fontSize="1rem">
                      <img
                        alt="certificate"
                        className={s.img}
                        src="/static/blue-certificate.png"
                      />
                    </Link>
                    {type === "examAdmin" && (
                      <Box display="flex" flexDirection="column" gap={1} pr={3} mt={1}>
                        <Button
                          variant="outlined"
                          color="warning"
                          sx={{minWidth: "unset"}}
                          onClick={handleResetCertificate}>
                          <RotateLeftIcon fontSize="medium"/>
                          Regenerate
                        </Button>
                        <Typography variant="body2" color="grey">
                          Date: {formatDate(updatedAt)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : isPassed ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </Typography>
          )}
        </Box>
        {cancelReason && (
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={.5}>
            <Typography whiteSpace="nowrap">
              Reason:
            </Typography>
            <Typography maxWidth={500}>
              <span style={{whiteSpace: "break-spaces", display: "flex"}}>
                <TextWithViewMore text={cancelReason}/>
              </span>
            </Typography>
          </Box>
        )}
        {canceledBy && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography whiteSpace="nowrap">
              Canceled By:
            </Typography>
            <Typography whiteSpace="nowrap">

            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
