import {DatePicker} from "@mui/x-date-pickers"
import {debounce} from "lodash"
import React, {useCallback, useEffect, useState} from "react"
import {Box, FormHelperText, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment"
import {Moment} from "moment"

type Value = Moment | null

interface Props {
  label?: string
  helperText?: string
  error?: null | boolean | string | string[]
  touched?: boolean
  value?: Value
  onChange: (value: Value) => void
  onBlur?: any
  name: string
  minDate?: Value
  maxDate?: Value
}

export default function DateField({
  name,
  helperText,
  label,
  error,
  touched,
  onChange,
  onBlur,
  value: valueProp,
  minDate,
  maxDate
}: Props) {
  const [value, setValue] = useState<Value>(valueProp || null)

  useEffect(() => {
    onChange(value)
  }, [value])

  const handleChange = useCallback(
    debounce((newValue: Value) => {

      setValue(newValue?.startOf("day") || null)
    }, 1000, {leading: true, trailing: true}),
    []
  )

  return (
    <Box position="relative" width="100%">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={label}
          value={value}
          onChange={handleChange}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          renderInput={props => (
            <TextField
              {...props}
              name={name}
              fullWidth
              error={!!(touched && error)}
              onBlur={onBlur}
              helperText={helperText}
            />
          )}
        />
      </LocalizationProvider>
      {!!(touched && error && !helperText) && (
        <FormHelperText
          error
          sx={{
            position: "absolute",
            width: "fit-content",
            maxWidth: "100%",
            bottom: "-8px",
            px: "4px",
            ml: "8px",
            mr: "8px",
            background: "white"
          }}>
          {typeof error === "string" ? (
            error
          ) : typeof error === "object" ? (
            error[0]
          ) : null}
        </FormHelperText>
      )}
    </Box>
  )
}
