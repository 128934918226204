import {
  Box,
  Checkbox,
  SxProps,
  TableCell,
  Theme
} from "@mui/material"

interface Props {
  id: string
  disabled?: boolean
  checked: boolean
  onClick: (id: string) => void
  sx?: SxProps<Theme>
  showDivider?: boolean
  colSpan?: number
}

export default function CheckboxCell({
  id,
  disabled,
  checked,
  onClick,
  sx,
  showDivider,
  colSpan
}: Props) {
  return (
    <TableCell
      colSpan={colSpan}
      align="center"
      key={id}
      padding="checkbox"
      sx={{
        textAlign: "center",
        ...sx
      }}>
      <Checkbox
        color="primary"
        disabled={disabled}
        onChange={() => onClick(id)}
        checked={checked || false}
      />
      {showDivider && (
        <Box sx={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          width: "1px",
          borderRight: theme => `1px solid ${theme.palette.lightGrey.main}`,
          height: "50%"
        }}/>
      )}
    </TableCell>
  )
}
