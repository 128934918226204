import EqualizerIcon from "@mui/icons-material/Equalizer"
import {LINKS} from "consts/links"
import React, {useEffect, useMemo, useState} from "react"
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Paper,
  Typography, useMediaQuery
} from "@mui/material"
import useAuth from "hooks/useAuth"
import NavSection from "./components/NavSection"
import {makeStyles, useTheme} from "@mui/styles"
import KpLogo from "components/KpLogo"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import {isRouteAllowed} from "utils/security"
import {useSelector} from "store"
import sections from "./sections"
import useMainPageLink from "hooks/useMainPageLink"
import {useLazyQuery} from "@apollo/client"
import GET_MY_INVITATIONS from "api/apollo/queries/GET_MY_INVITATIONS"
import {GetMyInvitationsQuery, GetMyInvitationsQueryVariables} from "generated/graphql"
import getPastDateISO from "utils/getPastDateISO"
import client from "api/apollo"
import cn from "classnames"
import {headerHeight, mobileHeaderHeight, sidebarWidth} from "consts"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: "100%"
  },
  contactUsButton: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignContent: "center",
    color: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      borderColor: theme.palette.white.main,
      background: theme.palette.white.main,
      color: theme.palette.primary.main
    }
  },
  goBackButton: {
    color: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    minWidth: "50%",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  avatar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    background: theme.palette.white.main,
    padding: theme.spacing(2)
  }
}))

const icon = <EqualizerIcon fontSize="small" style={{color: "white"}}/>

const districtNavList = [
  {
    title: "Schools",
    path: LINKS.districtAdmin,
    icon
  }, {
    title: "Exam Sessions",
    path: LINKS.districtAdminExamSessions,
    icon
  }, {
    title: "Practice Sessions",
    path: LINKS.districtAdminPracticeSessions,
    icon
  }, {
    title: "Exam Passing Rate",
    path: LINKS.districtAdminExamPassingRate,
    icon
  }, {
    title: "Inventory",
    path: LINKS.districtAdminLicenseList,
    icon
  }, {
    title: "License Inventory Logs",
    path: LINKS.districtAdminLicenseLogs,
    icon
  },
  {
    title: "District Personnel",
    path: LINKS.districtAdminPersonnel,
    icon
  },
  {
    title: "Students",
    path: LINKS.districtAdminStudents,
    icon
  },
  {
    title: "Extended Time",
    path: LINKS.districtAdminExtendedTime,
    icon
  },
  {
    title: "WP Labs",
    path: LINKS.districtAdminLabList,
    icon
  }
]

interface DashboardSidebarProps {
  onMobileClose: () => void
  openMobile: boolean
  type?: "district" | "admin"
}

export default function Sidebar({
  onMobileClose,
  type,
  openMobile
}: DashboardSidebarProps) {
  const s = useStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const location = useLocation()
  const {user} = useAuth()
  const auth = useAuth()
  const navigate = useNavigate()
  const [invitationsQueryFetch] = useLazyQuery<
    GetMyInvitationsQuery,
    GetMyInvitationsQueryVariables
  >(GET_MY_INVITATIONS, {
    fetchPolicy: "network-only"
  })
  const [hasInvitations, setHasInvitations] = useState(false)

  useEffect(() => {
    const observer = client.watchQuery({
      query: GET_MY_INVITATIONS
    })

    const subscription = observer.subscribe({
      next({data}) {
        setHasInvitations(Boolean(data.me?.invitations?.length))
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const roles = useSelector(state => state.userSlice.roles)
  const fakeRoles = useSelector(state => state.userSlice.fakeRoles)
  const {
    currentSchool,
    name: schoolName,
    logo: schoolLogo,
    districtName: schoolDistrictName
  } = useSelector(state => state.schoolSlice)
  const {
    currentDistrict,
    name: districtName,
    logo: districtLogo
  } = useSelector(state => state.districtSlice)

  const {isStudent, mainPageLink} = useMainPageLink()

  const [firstName, lastName] = useMemo(() => {
    return [
      user?.firstName,
      user?.lastName
    ]
  }, [user])

  const sectionsFiltered = useMemo(() => {
    const rolesList = fakeRoles?.length ? fakeRoles : roles

    if (type === "district") {
      return [
        {
          title: "Management",
          items: districtNavList
        }
      ]
    } else {
      return sections.map((section) => {
        const allowedItems = section.items.reduce((acc, item) => {
          if (isRouteAllowed(item.groups, rolesList)) {
            if (item.path === LINKS.myClassrooms) {
              item.showNotification = hasInvitations
            }

            acc.push(item)
          }

          return acc
        }, [] as typeof section.items)

        return {
          ...section,
          items: allowedItems
        }
      })
    }
  }, [roles, currentSchool, fakeRoles, type, hasInvitations])

  const avatar = useMemo(() => {
    if (isStudent) return user?.school?.logo || ""
    if (currentDistrict && !currentSchool) return districtLogo || ""
    return schoolLogo || ""
  }, [user, isStudent, currentDistrict, districtLogo, schoolLogo, currentSchool])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  useEffect(() => {
    (roles || fakeRoles) && isStudent && invitationsQueryFetch({
      variables: {
        createdAfter: getPastDateISO(7)
      }
    }).then(res => {
      setHasInvitations(Boolean(res.data?.me?.invitations?.length))
    })
  }, [isStudent, roles, fakeRoles])

  const content = (
    <Box className={cn(s.root, "scrollbar-hidden")}>
      {auth?.isAuthenticated ? (
        <>
          {isMobile && (
            <Box display="flex" justifyContent="center" p={2}>
              <RouterLink to={mainPageLink}>
                <KpLogo height="auto" width="250px"/>
              </RouterLink>
            </Box>
          )}
          <Box p={2}>
            <Paper className={s.avatar}>
              <Box display="flex" alignItems="center" width="100%" gap={2}>
                <Box height="48px" width="48px">
                  <Avatar
                    variant="rounded"
                    src={(avatar && !avatar.includes("https://")) ? (
                      `${process.env.REACT_APP_AWS_S3_URL}${avatar}`
                    ) : (
                      "/static/kp_logo.png"
                    )}
                    sx={{
                      width: "100%",
                      height: "100%"
                    }}
                    color="white.main"
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography color="textPrimary" variant="body1" fontWeight="medium">
                    {(currentDistrict && currentSchool) && !isStudent
                      ? schoolName
                      : [firstName, lastName].filter((_) => _).join(" ")
                    }
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2">
                    {(!isStudent && (districtName || (currentDistrict && currentSchool ? schoolDistrictName : schoolName))) || (user?.school?.name || "Hello")}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Divider/>
          <Box p={2}>
            {sectionsFiltered.map((section) =>
              section.items.length ? (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3
                    }
                  }}
                  {...section}
                />
              ) : null
            )}
          </Box>
          <Divider />
          <Box p={2}>
            <Typography color="white.main" variant="subtitle2" align="center">
              Need Help?
            </Typography>
            <Button
              className={s.contactUsButton}
              href="mailto:support@knowledge-pillars.com"
              variant="outlined"
              startIcon={<MailOutlineIcon/>}>
              Contact us
            </Button>
          </Box>
        </>
      ) : (
        <Box p={3} display="flex" justifyContent="center">
          <Button
            className={s.goBackButton}
            color="primary"
            variant="outlined"
            startIcon={<NavigateBeforeIcon/>}
            onClick={() => {
              navigate("/")
            }}>
            Back
          </Button>
        </Box>
      )}
    </Box>
  )

  return isMobile ? (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
      PaperProps={{
        className: "scrollbar-hidden",
        sx: {
          width: `${sidebarWidth}px`,
          paddingTop: {
            xs: `${mobileHeaderHeight}px`,
            md: `${headerHeight}px`
          },
          background: "linear-gradient(#2192C0,  #002955)"
        }
      }}>
      {content}
    </Drawer>
  ) : (
    <Drawer
      open
      anchor="left"
      sx={{
        width: `${sidebarWidth}px`,
        height: `calc(100vh - ${headerHeight}px)`,
        position: "sticky",
        top: `${headerHeight}px`
      }}
      PaperProps={{
        className: "scrollbar-hidden",
        sx: {
          bgcolor: "primary.main",
          overflowY: "scroll",
          height: "100%",
          position: "relative",
          width: "100%"
        }
      }}
      variant="persistent">
      {content}
    </Drawer>
  )
}
