import {gql} from "@apollo/client"

export default gql`
  query GetMyClassroomsRequests {
    me {
      invitations (targetEntity: classroom) {
        _id
        classroom {
          _id
          school {
            name
            _id
            logo
          }
          name
        }
        createdAt
        decidedAt
        comment
        failedReason
        createdByUserId
        createdByUserFullName
        status
        updatedAt
        user {
          _id
          firstName
          fullName
          lastName
          email
        }
      }
      waitingList {
        _id
        classroom {
          _id
          name
          school {
            _id
            name
            logo
          }
        }
        createdAt
        decidedAt
        failedReason
        status
        updatedAt
        student {
          _id
          firstName
          fullName
          email
          lastName
        }
      }
    }
  }
`
