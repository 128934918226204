import React from "react"
import formatDate from "utils/formatDate"
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from "@mui/material"

type ExamsListCardItem = {
  _id: string
  title: string
  subtitle: string
  examCode: string
  expired: boolean
  expirationDate: string
}

interface Props {
  data: ExamsListCardItem
  onActivate: (examName: string) => void
  onStart: (_id: string, examCode: string) => void
  variant: "primary" | "secondary"
}

export default function ExamItem({
  data,
  onStart,
  onActivate,
  variant
}: Props) {
  const handleStartOrActivateExamHandler = () => {
    return data.expired ? onActivate(data.subtitle) : onStart(data._id, data.examCode)
  }

  return (
    <Grid
      item
      xs={12}
      sm={variant === "secondary" ? 4 : 6}
      md={variant === "secondary" ? 3 : 4}
    >
      <Box
        sx={{
          height: "100%",
          bgcolor: (theme) => theme.palette.white.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "20px",
          pt: 3,
          overflow: "hidden"
        }}>
        <Box px={3}>
          <Typography variant="body1" fontWeight={900}>
            {data.title}
          </Typography>
          <Typography variant="body1" fontWeight={900} mt="2px">
            {data.subtitle}
          </Typography>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column"
        }}>
          <Box
            px={3}
            mb={3}
            mt={1.5}
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Divider/>
            <Box
              mt={4}
              mb={variant === "secondary" ? 0 : 4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                aspectRatio: 1,
                width: "70%",
                mx: "auto"
              }}>
              <img
                src={`/practiceDetailsIcons/${data.examCode}.svg`}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = "/practiceDetailsIcons/unknown.png"
                }}
                alt={data.subtitle}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain"
                }}
              />
            </Box>
            {variant === "primary" && (
              <Box
                sx={{
                  width: "100%",
                  mx: "auto"
                }}>
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  color={data.expired ? "secondary" : "primary"}
                  onClick={handleStartOrActivateExamHandler}>
                  {data.expired ? "Activate new key" : "START TEST"}
                </Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              bgcolor: (theme) => data.expired ? theme.palette.error.main : theme.palette.success.main,
              py: 1.25
            }}>
            <Typography variant="body1" color="white.main">
              {data.expired ? "Expired" : "Valid until"} {formatDate(data.expirationDate)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
