import {gql} from "@apollo/client"

export default gql`
  query GetLabSession($labSessionId: GraphQLObjectId!) {
    getLabSession(labSessionId: $labSessionId) {
      _id
      createdAt
      createdByUser {
        _id
        username
        totalTimeSpentInLabs
      }
      districtId
      expirationDate
      finishedAt
      instance {
        _id
        errorMessage
        password
        status
        wordpressAdminUrl
        userId
        username
      }
      instanceId
      isExpired
      isFinished
      isInstanceReady
      schoolId
      progressChannel
      progressFound
      progressOfUser {
        _id
        username
        totalTimeSpentInLabs
      }
    }
  }
`
