import Requests from "pages/MyClassrooms/components/Requests"
import React from "react"
import StudentVouchersList from "components/StudentVouchersList"
import {Box} from "@mui/material"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"

export default function DashboardExamsSelection() {
  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Overview"
      title="Overview"
      breadcrumbs={[
        {
          text: "Dashboard"
        }
      ]}>
      <Box>
        <Requests/>
        <Box mt={3}>
          <StudentVouchersList/>
        </Box>
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
