import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import GET_EXAM_SESSION_DETAILS from "api/apollo/queries/GET_EXAM_SESSION_DETAILS"
import StudentVouchersList from "components/StudentVouchersList"
import {LINKS} from "consts/links"
import React, {useEffect, useMemo, useState} from "react"
import {useLazyQuery} from "@apollo/client"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {
  ExamSession,
  GetExamSessionDetailsQuery,
  GetExamSessionDetailsQueryVariables,
  GetTestingSessionDetailsQuery,
  GetTestingSessionDetailsQueryVariables,
  ObjectiveScoreData,
  TestingSession
} from "generated/graphql"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Paper,
  Typography
} from "@mui/material"
import {useDispatch} from "react-redux"
import Info from "pages/SessionDetails/components/Info"
import ObjectiveResults from "pages/SessionDetails/components/ObjectiveResults"
import {Link as RouterLink, useParams} from "react-router-dom"
import useMainPageLink from "hooks/useMainPageLink"
import GET_TESTING_SESSION_DETAILS from "api/apollo/queries/GET_TESTING_SESSION_DETAILS"
import {useSelector} from "store"
import {handleError} from "store/slices/notifier/notifier"
import {Roles} from "types/access"
import TaskResults from "./components/TaskResults"

interface Props {
  isPractice?: boolean
  breadcrumbs: {
    text: string
    path?: "VAR" | string
  }[]
  isDistrict?: boolean
}

export default function SessionDetails({
  isPractice,
  isDistrict,
  breadcrumbs
}: Props) {
  const dispatch = useDispatch()
  const params = useParams()
  const {isStudent} = useMainPageLink()

  const {roles} = useSelector(state => state.userSlice)

  const [session, setSession] = useState<DeepPartial<TestingSession | ExamSession> | null>(null)
  const [objectives, setObjectives] = useState<ObjectiveScoreData[]>([])

  const [testingSessionQueryFetch, testingSessionQuery] = useLazyQuery<
    GetTestingSessionDetailsQuery,
    GetTestingSessionDetailsQueryVariables
  >(GET_TESTING_SESSION_DETAILS, {
    fetchPolicy: "no-cache"
  })

  const [examSessionQueryFetch] = useLazyQuery<
    GetExamSessionDetailsQuery,
    GetExamSessionDetailsQueryVariables
  >(GET_EXAM_SESSION_DETAILS, {
    fetchPolicy: "no-cache"
  })

  const loading = useMemo(() => {
    return testingSessionQuery.loading || false
  }, [testingSessionQuery.loading])

  const pageTitle = useMemo(() => {
    return isPractice ? "Practice Test Details" : "Exam Details"
  }, [isPractice])

  const breadcrumbsPathBack = useMemo(() => {
    if (isStudent) {
      return isPractice ? "/practice-results" : "/my-results"
    }

    return isPractice ? "/student-practice-results" : "/student-results"
  }, [isStudent, isPractice])

  const title = useMemo(() => {
    if (session) {
      const name = loading || !session ? "Loading..." : session?.exam?.displayName?.value || "Unknown"
      const code = session?.exam?.displayCode?.toUpperCase() || ""

      return name + ((loading || !code) ? "" : ` (${code})${isPractice ? " - Practice Test" : ""}`)
    } else {
      return ""
    }
  }, [isPractice, session, loading])

  const showTaskResults = useMemo(() => {
    return !!(isPractice || roles?.includes(Roles.Admin))
  }, [isPractice, roles])

  useEffect(() => {
    const sessionId = params?.id

    if (sessionId) {
      if (isPractice) {
        testingSessionQueryFetch({
          variables: {
            testingSessionId: sessionId
          }
        }).then(res => {
          setSession(res.data?.getTestingSession || null)
          setObjectives(res.data?.getTestingSession.objectiveScoring || [])
        }).catch(err => {
          dispatch(handleError(err))
        })
      } else {
        examSessionQueryFetch({
          variables: {
            examSessionId: sessionId
          }
        }).then(res => {
          setSession(res.data?.getExamSession || null)
          setObjectives(res.data?.getExamSession.objectiveScoring || [])
        }).catch(err => {
          dispatch(handleError(err))
        })
      }
    }
  }, [params?.id, isPractice])

  return (
    <LayoutWithBreadcrumbs
      card={false}
      helmetTitle={`${pageTitle} | KP Platform`}
      title={pageTitle}
      breadcrumbs={[...breadcrumbs.map(i => {
        if (i.path === "VAR") {
          return {
            ...i,
            path: breadcrumbsPathBack
          }
        } else {
          return i
        }
      }), {
        text: title
      }]}>
      {(loading || !session) ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={32}>
          <CircularProgress/>
        </Box>
      ) : (
        <Box sx={{
          display: "flex",
          flexDirection: "column"
        }}>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: 2.5
            }}>
            <Info
              isPractice={isPractice}
              data={session}
            />
            <ObjectiveResults
              objectives={objectives}
              isPassed={session._isPassed}
              isPractice={isPractice}
              examCode={session.exam.code}
              examName={session.exam.displayName.value}
            />
            {showTaskResults && (
              <TaskResults
                examCode={session.exam.code}
                tasks={session._tasks}
                givenAnswers={session.givenAnswers}
              />
            )}
          </Paper>
          <Paper
            sx={{
              mt: 4,
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: 2.5
            }}>
            <Box>
              <Typography variant="h5" fontWeight={900}>
                Available tests
              </Typography>
              <Divider sx={{mt: 4, mb: 2}}/>
              <Box>
                <StudentVouchersList compact/>
              </Box>
              <Divider sx={{my: 4}}/>
              <Box>
                <Link to={LINKS.dashboard} component={RouterLink} underline="none">
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBackIcon/>}
                    sx={{px: 2}}>
                    BACK TO DASHBOARD
                  </Button>
                </Link>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </LayoutWithBreadcrumbs>
  )
}
