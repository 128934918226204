import GET_USER from "api/apollo/queries/GET_USER"
import GET_USER_LAB_DETAILS from "api/apollo/queries/GET_USER_LAB_DETAILS"
import client from "api/apollo"
import {
  GetUserQuery,
  GetUserQueryVariables,
  GetUserLabDetailsQuery,
  GetUserLabDetailsQueryVariables
} from "generated/graphql"
import {IUserInfo} from "types/common"
import {
  IFetchUserServiceMethods,
  IUserService
} from "types/services/user.service.t"

const FetchUserServiceMethods: IFetchUserServiceMethods = {
  fetchUserInfo: async () => {
    const {data} = await client.query<GetUserQuery, GetUserQueryVariables>({
      query: GET_USER,
      fetchPolicy: "network-only"
    })

    return data.me as unknown as IUserInfo
  },
  fetchUserLabDetails: async () => {
    const {data} = await client.query<GetUserLabDetailsQuery, GetUserLabDetailsQueryVariables>({
      query: GET_USER_LAB_DETAILS,
      fetchPolicy: "network-only"
    })

    return data.me as Partial<IUserInfo>
  }
}

const userService: IUserService = {
  ...FetchUserServiceMethods
}

export default userService
