import {Box, LinearProgress, Typography} from "@mui/material"
import hexToRgba from "utils/hexToRgba"
import {kpSecondaryColors} from "consts"

interface Props {
  label?: string
  value?: number
  width?: string | number
  onlyProgress?: boolean
}

export default function ScoreLinear({
  label = "Score",
  value = 0,
  width = "100%",
  onlyProgress
}: Props) {
  const roundedValue = Math.round(value)

  return (
    <Box>
      {!onlyProgress && (
        <Typography
          variant="subtitle2"
          whiteSpace="nowrap"
          mb={1}
          sx={{color: theme => hexToRgba(theme.palette.primary.main, .8)}}>
          {label}: {roundedValue}%
        </Typography>
      )}
      <Box width={width}>
        <LinearProgress
          sx={{
            height: 8,
            borderRadius: "5px",
            backgroundColor: theme => hexToRgba(theme.palette.primary.main, .1),
            "& .MuiLinearProgress-bar": {
              borderRadius: "5px",
              backgroundColor: theme => {
                return (roundedValue < 75 && !onlyProgress) ? theme.palette.error.main : kpSecondaryColors.green
              }
            }
          }}
          variant="determinate"
          value={roundedValue}
        />
      </Box>
    </Box>
  )
}
