import makeStyles from "@mui/styles/makeStyles"
import SessionResultProgress from "components/SessionResultProgress"
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Link
} from "@mui/material"
import {LINKS} from "consts/links"
import {
  ExamSession,
  ExamSessionStatus,
  TestingSession,
  TestingSessionStatus
} from "generated/graphql"
import useAuth from "hooks/useAuth"
import {useMemo} from "react"
import {Link as RouterLink} from "react-router-dom"
import formatDate from "utils/formatDate"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import getSessionDuration from "utils/getSessionDuration"
import getExamSessionStatusName from "utils/getExamSessionStatusName"
import {kpSecondaryColors} from "consts"

interface Props {
  isPractice: boolean
  data: DeepPartial<ExamSession | TestingSession>
}

const useStyles = makeStyles(() => ({
  certificateIcon: {
    display: "flex",
    width: "48px",
    height: "48px",
    transition: ".3s",
    "&:hover": {
      opacity: .6,
      transition: ".3s"
    }
  }
}))

export default function Info({
  isPractice,
  data
}: Props) {
  const s = useStyles()

  const {user} = useAuth()

  const status = useMemo(() => {
    let color = null

    if ([TestingSessionStatus.Finished, ExamSessionStatus.Finished].includes(data.status)) {
      color = "success.main"
    }

    if ([ExamSessionStatus.Canceled].includes(data.status as ExamSessionStatus)) {
      color = "error.main"
    }

    return {
      name: getExamSessionStatusName(data.status),
      color
    }
  }, [data.status])

  const canRetake = useMemo(() => {
    return isPractice && (data.user._id === user._id)
  }, [isPractice, data.user, user])

  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "white.main",
          borderRadius: "20px",
          pr: 3,
          pl: 2,
          py: 3,
          gap: 1
        }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              maxWidth: 160,
              width: "100%"
            }}>
            <img
              src={`/${isPractice ? "practiceDetailsIcons" : "examIcons"}/${data.exam.code}.svg`}
              onError={(e) => {
                (e.target as HTMLImageElement).src = "/examIcons/unknown.png"
              }}
              alt={data.exam.displayCode}
              style={{
                width: "100%",
                aspectRatio: 1,
                objectFit: "contain"
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5" fontWeight={900}>
              {data.exam.displayName?.value} ({data.exam.displayCode}) {isPractice && " - Practice Test"}
            </Typography>
            <Typography mt={1}>
              Candidate: {data.user.fullName}
            </Typography>
            {(!isPractice && data._isPassed && "certificate" in data && data.certificate) ? (
              <Box mt={2}>
                <Link
                  target="_blank"
                  href={`${LINKS.certificate}/${data.certificate?._id}`}
                  underline="always"
                  variant="body2"
                  fontSize="1rem">
                  <img
                    alt="certificate"
                    className={s.certificateIcon}
                    src="/static/blue-certificate.png"
                  />
                </Link>
              </Box>
            ) : canRetake && (
              <Box mt={2}>
                <Link to={LINKS.dashboard} component={RouterLink} underline="none">
                  <Button
                    startIcon={<RestartAltIcon/>}
                    variant="square"
                    color="lightBlue"
                    size="small">
                    Retake
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 2.5
          }}>
          <SessionResultProgress
            size={180}
            passingScore={data.exam.passingScoreRate}
            value={data._score}
          />
        </Paper>
      </Box>
      <Grid
        container
        p={3}
        sx={{
          bgcolor: "white.main",
          borderRadius: "20px",
          textAlign: "center",
          "& > div": {
            borderRight: `1px solid ${kpSecondaryColors.lightGrey}`
          },
          "& > div:last-of-type": {
            borderRight: "none"
          }
        }}>
        <Grid item xs={isPractice ? 12 / 7 : 2.4}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Exam Code
          </Typography>
          <Typography variant="body1">
            {data.exam.displayCode}
          </Typography>
        </Grid>
        <Grid item xs={isPractice ? 12 / 7 : 2.4}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Score
          </Typography>
          <Typography variant="body1">
            {data._score}
          </Typography>
        </Grid>
        <Grid item xs={isPractice ? 12 / 7 : 2.4}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Status
          </Typography>
          <Typography variant="body1" color={status.color}>
            {status.name}
          </Typography>
        </Grid>
        <Grid item xs={isPractice ? 12 / 7 : 2.4}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Date
          </Typography>
          <Typography variant="body1">
            {formatDate(data.startDate || data.createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={isPractice ? 12 / 7 : 2.4}>
          <Typography variant="body1" fontWeight={900} mb={1}>
            Time Used
          </Typography>
          <Typography variant="body1">
            {getSessionDuration(data.startDate || data.createdAt, data.finishDate)}
          </Typography>
        </Grid>
        {isPractice && (
          <Grid item xs={12 /7}>
            <Typography variant="body1" fontWeight={900} mb={1}>
              Show Answers
            </Typography>
            <Typography variant="body1">
              {"options" in data && data.options?.showResult !== "end" ? "Task level" : "End of test"}
            </Typography>
          </Grid>
        )}
        {isPractice && (
          <Grid item xs={12 / 7}>
            <Typography variant="body1" fontWeight={900} mb={1}>
              Resubmission
            </Typography>
            <Typography variant="body1">
              {"options" in data && data.options?.resubmissionAllowed ? "Allowed" : "Disabled"}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
