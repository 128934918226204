import {Instance} from "generated/graphql"
import React, {useRef, useState} from "react"
import {useDispatch, useSelector} from "store"
import {handleError} from "store/slices/notifier/notifier"
import CustomErrorClass from "store/slices/notifier/customErrorClass"
import {customErrors} from "store/slices/notifier/errorObject"
import {Box, CircularProgress} from "@mui/material"

interface Props {
  iframeWasLoaded: boolean
  setOpenHint?: (hintStatus: boolean) => void
  setIframeWasLoaded: (iframeStatus: boolean) => void
  instance?: Instance
  expanded?: boolean
}

export default function WordpressWorkspace({
  setOpenHint,
  iframeWasLoaded,
  setIframeWasLoaded,
  instance,
  expanded
}: Props) {
  const dispatch = useDispatch()
  const gridIframe = useRef<HTMLIFrameElement>(null)

  const sessionInstance = useSelector(state => state.practiceSession.currentSession?.instance)

  const [loading, setLoading] = useState(true)

  const handleIframeLoaded = () => {
    setLoading(false)
    if (iframeWasLoaded) return
    setIframeWasLoaded(true)
    setOpenHint(true)
  }

  const handleIframeError = () => {
    setLoading(false)
    dispatch(handleError(new CustomErrorClass(customErrors.CANT_LOAD_IFRAME)))
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        background: "#ffffff",
        height: "100%",
        borderRadius: expanded ? 0 : "15px",
        border: theme => expanded ? "none" : `1px solid ${theme.palette.kpNeutralColors.light}`,
        transition: ".3s",
        overflow: "hidden"
      }}
    >
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
            color: "white",
            backgroundColor: "white",
            zIndex: 1250
          }}
        >
          <CircularProgress color="primary"/>
        </Box>
      )}
      {(instance?.wordpressAdminUrl || sessionInstance?.url) && (
        <iframe
          src={instance?.wordpressAdminUrl || sessionInstance.url}
          frameBorder={0}
          title="wp-exam"
          className="test"
          style={{
            overflow: "hidden",
            height: "100%",
            width: "100%",
            position: "absolute"
          }}
          width="100%"
          height="100%"
          ref={gridIframe}
          onLoad={handleIframeLoaded}
          onError={handleIframeError}
        />
      )}
    </Box>
  )
}

