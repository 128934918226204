import {Box, Typography} from "@mui/material"
import MD from "components/MD"
import {isEqual} from "lodash"
import {useEffect, useMemo, useState} from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd"

interface Props {
  description?: string
  code?: string
  codeLang?: string
  questions: Array<{
    id: string
    content: string
    position: number
  }>
  options: Array<{
    id: string
    content: string
  }>
  onChange?: (
    value: Array<{
      id: string
      position: number
    }>
  ) => void
  config?: {
    fontFamily?: string
    codeBgColor?: string
    optionBgColor?: string
  }
  answer?: Array<{
    id: string
    position: number
  }> | null
  givenAnswer?: Array<{
    id?: string
    position?: number
  }> | null
  inactive?: boolean
}

export default function MatchTask({
  description,
  code,
  codeLang,
  questions,
  options,
  onChange,
  config: configProp,
  answer,
  givenAnswer,
  inactive
}: Props) {
  const [localOptions, setLocalOptions] = useState<Props["options"] | null>(null)
  const [values, setValues] = useState<Array<{
    id: string
    content: string
    position: number
    destination: string | null
  }>>(null)

  const config: Props["config"] = useMemo(() => ({
    fontFamily: configProp?.fontFamily || "Roboto, sans-serif",
    codeBgColor: configProp?.codeBgColor || "rgba(32, 146, 192, 0.04)",
    optionBgColor: configProp?.optionBgColor || "rgba(0, 40, 85, 0.04)"
  }), [])

  const correctAnswer = useMemo(() => {
    if (questions && answer && values) {
      return questions.map((i, num) => {
        const answerRef = Array.from(answer).sort((
          a,
          b
        ) => {
          return a.position - b.position
        })[num]
        const valueRef = values.find(i => i.id === answerRef.id)

        return {
          ...i,
          value: {
            id: valueRef.id,
            content: valueRef.content
          }
        }
      })
    } else {
      return null
    }
  }, [questions, answer, values])

  const MDStyles = useMemo(() => {
    return {
      "& .wmde-markdown code": {
        backgroundColor: "transparent"
      },
      "& .wmde-markdown pre > code": {
        px: 2,
        py: 1.5,
        borderRadius: "4px",
        backgroundColor: config.codeBgColor
      },
      "& .wmde-markdown pre .copied": {
        top: 10,
        right: 10
      }
    }
  }, [config])

  useEffect(() => {
    if (options?.length && !localOptions?.length) {
      setLocalOptions(options)
    }
  }, [options, localOptions])

  useEffect(() => {
    if (localOptions && !values) {
      setValues(localOptions.map((i, num) => ({
        id: i.id,
        content: i.content,
        position: num + 1,
        destination: null
      })))
    }
  }, [localOptions, values])

  useEffect(() => {
    if (givenAnswer?.length) {
      setValues(current => {
        const newValues = givenAnswer.map((i, num) => {
          const ref = localOptions?.find(option => option.id === i.id)
          const questionRef = questions?.find(question => {
            return question.position === num + 1
          })

          return {
            id: i.id,
            content: ref?.content || "",
            position: num + 1,
            destination: questionRef?.id || null
          }
        })


        if (isEqual(newValues, current)) {
          return current
        }

        return newValues
      })
    }
  }, [givenAnswer, localOptions, questions])

  useEffect(() => {
    if (onChange) {
      onChange(questions.map(i => ({
        id: values?.find(v => v.destination === i.id)?.id || null,
        position: i.position
      })).filter(i => !!i.id))
    }
  }, [values])

  const handleChange = (result: DropResult) => {
    if (inactive) return

    const {source, destination} = result
    const draggableId = result.draggableId.startsWith("used") ? (
      result.draggableId.split("-")[1]
    ) : result.draggableId

    if (!destination) return

    setValues(prev => {
      if (!prev) return null

      const updated = [...prev]

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return prev
      }

      if (source.droppableId === destination.droppableId) {
        const list = updated
        const [movedItem] = list.splice(source.index, 1)

        list.splice(destination.index, 0, movedItem)

        return list.map((i, num) => ({
          ...i,
          position: num + 1
        }))
      } else if (destination.droppableId !== "matchTaskDroppableContainer") {
        const list = updated.map(i => {
          if (i.id === draggableId) {
            return {
              ...i,
              destination: destination.droppableId
            }
          } else {
            return i
          }
        })

        return [...list]
      } else {
        const list = updated.map(i => {
          if (i.id === draggableId) {
            return {
              ...i,
              destination: null
            }
          } else {
            return i
          }
        })

        const [movedItem] = list.splice(list.findIndex(i => i.id === draggableId), 1)

        list.splice(destination.index, 0, movedItem)

        return [...list]
      }
    })
  }

  const Question = useMemo(() => ({
    answer,
    droppableId,
    text,
    value,
    bgColor,
    borderColor = "rgba(0, 40, 85, 0.1)"
  }: {
    droppableId: string
    text: string
    answer?: boolean
    value?: Omit<typeof values[0], "position" | "destination">
    bgColor?: string
    borderColor?: string
  }) => {
    return (
      <Droppable
        droppableId={`${droppableId}${answer ? "-answer" : ""}`}
        isDropDisabled={answer || inactive || !!value}>
        {(provided, snapshot) => (
          <Box
            position="relative"
            width="100%"
            pb={1}
            display="contents"
            sx={{
              pointerEvents: inactive ? "none" : "auto"
            }}>
            <Box
              width="100%"
              maxWidth="290px"
              height="100%"
              p={1.5}
              borderRadius="4px"
              bgcolor={bgColor || answer ? "rgba(39, 174, 96, 1)" : ((snapshot.isDraggingOver || value) ? (
                "rgba(0, 40, 85, 1)"
              ) : config.optionBgColor)}
              border={`1px solid ${borderColor}`}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                transition: ".15s"
              }}>
              <Typography
                fontFamily={config.fontFamily}
                sx={{
                  color: (snapshot.isDraggingOver || value) && "white.main",
                  transition: ".15s"
                }}>
                {text}
              </Typography>
            </Box>
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              position="relative"
              width={answer ? "100%" : "290px"}
              borderRadius="4px"
              bgcolor={bgColor || config.optionBgColor}
              border={`1px ${value ? "solid" : "dashed"} ${borderColor}`}
              display="flex"
              alignItems="center"
              justifyContent="center">
              {value ? (
                <Draggable
                  draggableId={`used-${value.id}${answer ? "-answer" : ""}`}
                  index={0}
                  isDragDisabled={answer || inactive}>
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      width={snapshot.isDragging ? "200px" : "100%"}
                      height="100%"
                      borderRadius="4px"
                      bgcolor={bgColor || "#E4E8EA"}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        transition: ".15s"
                      }}>
                      <Typography
                        px={2}
                        fontFamily={config.fontFamily}>
                        {value.content}
                      </Typography>
                    </Box>
                  )}
                </Draggable>
              ) : (
                <Typography
                  position="absolute"
                  top="50%"
                  left="50%"
                  px={2}
                  fontFamily={config.fontFamily}
                  fontSize="12px"
                  whiteSpace="nowrap"
                  sx={{
                    transform: "translate3d(-50%, -50%, 0)",
                    opacity: snapshot.isDraggingOver ? 0 : 1,
                    color: "rgba(0, 40, 85, .3)",
                    pointerEvents: "none",
                    transition: ".15s"
                  }}>
                  Move your answer here
                </Typography>
              )}
              {provided.placeholder}
            </Box>
          </Box>
        )}
      </Droppable>
    )
  }, [config, MDStyles])

  return (
    <Box position="relative" display="inline-block" minWidth="700px" maxWidth="1000px">
      <Box mb={4}>
        <Box display="flex" gap={1} sx={MDStyles}>
          <Typography fontWeight="700">
            Question:
          </Typography>
          <MD
            fontFamily={config.fontFamily}
            value={description}
          />
        </Box>
        {code && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              mt: 1.5,
              borderRadius: "4px",
              ...MDStyles
            }}>
            <MD
              fontFamily={config.fontFamily}
              value={"```" + ((codeLang + "\n") || "") + code}
              fullWidth
            />
          </Box>
        )}
      </Box>
      <Box position="relative" width="100%">
        {questions && values && (
          <DragDropContext onDragEnd={handleChange}>
            <Box
              position="relative"
              width="auto"
              display="grid"
              gridTemplateColumns="max-content 280px"
              gap={4}
              sx={{
                pointerEvents: inactive ? "none" : "auto"
              }}>
              <Box
                display="grid"
                gridTemplateColumns="max-content 1fr"
                gap={1}>
                {questions.map(i => {
                  const value = values.find(v => v.destination === i.id)

                  return (
                    <Question
                      key={i.id}
                      droppableId={i.id}
                      text={i.content}
                      value={value}
                    />
                  )
                })}
              </Box>
              <Droppable
                droppableId="matchTaskDroppableContainer"
                isDropDisabled={inactive}>
                {provided => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    position="relative"
                    width="100%"
                    height="100%"
                    py={1}
                    px={1.5}
                    borderRadius="4px"
                    border="1px solid rgba(0, 40, 85, 0.2)"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center">
                    {values.map((i, num) => (
                      <Draggable
                        key={i.id}
                        draggableId={i.destination ? `${i.id}-hidden` : i.id}
                        isDragDisabled={inactive || !!i.destination}
                        index={num}>
                        {provided => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            width="100%"
                            minWidth="200px"
                            maxWidth="260px"
                            py={.5}
                            display={i.destination && "none"}>
                            <Box
                              py={.75}
                              px={1.5}
                              bgcolor="#F7F7F7"
                              borderRadius="4px"
                              border="1px solid rgba(0, 40, 85, 1)"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                transition: ".15s"
                              }}>
                              <Typography
                                fontFamily={config.fontFamily}
                                sx={{
                                  color: "rgba(0, 40, 85, 1)"
                                }}>
                                {i.content}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
            {answer && (
              <Box
                position="relative"
                width="calc(100% - 312px)"
                mt={4}
                py={2}
                pl={2.5}
                pr={2}
                borderRadius="6px"
                bgcolor="rgba(39, 174, 96, 0.1)"
                overflow="hidden">
                <Box
                  position="absolute"
                  width={4}
                  height="100%"
                  top={0}
                  left={0}
                  bgcolor="rgba(39, 174, 96, 1)"
                />
                <Typography mb={1} fontFamily={config.fontFamily}>
                  <strong>
                    Right answer:
                  </strong>
                </Typography>
                <Box
                  display="grid"
                  gridTemplateColumns="max-content 1fr"
                  gap={1}>
                  {correctAnswer.map(i => (
                    <Question
                      key={i.id}
                      droppableId={i.id}
                      answer
                      text={i.content}
                      value={i.value}
                      bgColor="rgba(39, 174, 96, 0.1)"
                      borderColor="rgba(39, 174, 96, 0.2)"
                    />
                  ))}
                </Box>
              </Box>
            )}
          </DragDropContext>
        )}
      </Box>
    </Box>
  )
}
