import {useMutation, useQuery} from "@apollo/client"
import {
  Box,
  Button, Divider,
  Typography
} from "@mui/material"
import DELETE_JOIN_REQUEST from "api/apollo/mutations/DELETE_JOIN_REQUEST"
import RESPOND_INVITATION from "api/apollo/mutations/RESPOND_INVITATION"
import GET_MY_CLASSROOMS_REQUESTS from "api/apollo/queries/GET_MY_CLASSROOMS_REQUESTS"
import DataTable from "components/DataTable"
import {DataTableSchema} from "components/DataTable/types.t"
import {
  DeleteJoinRequestMutation,
  DeleteJoinRequestMutationVariables,
  GetMyClassroomsRequestsQuery,
  GetMyClassroomsRequestsQueryVariables,
  Invitation,
  InvitationStatus,
  RespondInvitationMutation,
  RespondInvitationMutationVariables,
  WaitingList
} from "generated/graphql"
import React, {useMemo} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import formatDate from "utils/formatDate"
import GET_MY_INVITATIONS from "api/apollo/queries/GET_MY_INVITATIONS"
import getPastDateISO from "utils/getPastDateISO"
import KPLogo from "assets/icons/KPLogo"

interface Props {
  onUpdate?: () => void
}

export default function Requests({
  onUpdate
}: Props) {
  const dispatch = useDispatch()

  const requestsQuery = useQuery<
    GetMyClassroomsRequestsQuery,
    GetMyClassroomsRequestsQueryVariables
  >(GET_MY_CLASSROOMS_REQUESTS, {fetchPolicy: "network-only"})

  const [respondInvitation] = useMutation<
    RespondInvitationMutation,
    RespondInvitationMutationVariables
  >(RESPOND_INVITATION, {
    refetchQueries: [{
      query: GET_MY_INVITATIONS,
      variables: {createdAfter: getPastDateISO(7)},
      fetchPolicy: "network-only"
    }]
  })

  const [deleteJoinRequest] = useMutation<
    DeleteJoinRequestMutation,
    DeleteJoinRequestMutationVariables
  >(DELETE_JOIN_REQUEST, {
    refetchQueries: [{
      query: GET_MY_INVITATIONS,
      variables: {createdAfter: getPastDateISO(7)},
      fetchPolicy: "network-only"
    }]
  })

  const requestsData = useMemo(() => {
    return [...(requestsQuery.data?.me?.invitations || []), ...(requestsQuery.data?.me?.waitingList || [])]
  }, [requestsQuery.data])

  const requestsError = useMemo(() => {
    return !requestsQuery.data && (requestsQuery.error || null)
  }, [requestsQuery.data, requestsQuery.error])

  const tableSchema: DataTableSchema<Invitation | WaitingList> = useMemo(() => {
    return [
      {
        type: "custom",
        headerText: "School",
        content: data => {
          const icon = data?.classroom?.school?.logo
          const name = data?.classroom?.school?.name
          return (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2">
                {name}
              </Typography>
              {icon ? (
                <Box width={24} height={24} borderRadius="8px" overflow="hidden">
                  <img
                    src={`${process.env.REACT_APP_AWS_S3_URL}${icon}`}
                    alt={name}
                    className="object-cover"
                  />
                </Box>
              ) : (
                <KPLogo/>
              )}
            </Box>
          )
        }
      },
      {
        type: "text",
        headerText: "Classroom",
        fieldName: "classroom.name",
        align: "center"
      },
      {
        type: "text",
        headerText: "Teacher",
        fieldName: "createdByUserFullName",
        align: "center"
      },
      {
        type: "custom",
        headerText: "Invited On",
        align: "center",
        content: data => {
          return (
            <Box textAlign="center">
              {"createdAt" in data ? formatDate(data.createdAt) : ""}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "Invite",
        align: "center",
        content: data => (
          <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
            {data.__typename === "WaitingList" ? (
              <Button
                color="error"
                variant="textSquare"
                size="small"
                onClick={() => handleCancelRequest(data._id)}
              >
                Cancel request
              </Button>
            ) : (
              <>
                <Button
                  color="error"
                  variant="textSquare"
                  size="small"
                  onClick={() => handleManageInvitation("REJECT", data._id)}
                >
                  Decline
                </Button>
                <Button
                  color="success"
                  variant="square"
                  size="small"
                  onClick={() => handleManageInvitation("ACCEPT", data._id)}
                >
                  Accept
                </Button>
              </>
            )}
          </Box>
        )
      }
    ]
  }, [])

  const handleManageInvitation = (action: "ACCEPT" | "REJECT", id: string) => {
    respondInvitation({
      variables: {
        decision: action === "ACCEPT" ? InvitationStatus.Accepted : InvitationStatus.Rejected,
        invitationId: id
      }
    }).then(() => {
      requestsQuery.refetch()
      onUpdate && onUpdate()
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleCancelRequest = (id: string) => {
    deleteJoinRequest({
      variables: {
        joinRequestId: id
      }
    }).then(() => {
      requestsQuery.refetch()
      onUpdate && onUpdate()
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  return (
    <Box mb={requestsData?.length ? 3 : 0}>
      {!!requestsData?.length && (
        <Box>
          <Typography color="textSecondary" variant="h6" mb={2}>
            INVITATIONS
          </Typography>
          <DataTable
            fullWidth
            schema={tableSchema}
            data={requestsData}
            loading={requestsQuery.loading}
            error={!!requestsError}
          />
          <Divider sx={{my: 2.5}}/>
        </Box>
      )}
    </Box>
  )
}
