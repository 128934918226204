import {Box, Button, InputLabel, Typography} from "@mui/material"
import PasswordInput from "components/PasswordInput"
import {Formik, FormikHelpers} from "formik"
import CrossIcon from "@mui/icons-material/Clear"
import CheckmarkIcon from "@mui/icons-material/Done"
import {useMemo, useState} from "react"
import * as Yup from "yup"
import {useMutation} from "@apollo/client"
import {
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables,
  UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables
} from "generated/graphql"
import CHANGE_USER_PASSWORD from "api/apollo/mutations/CHANGE_USER_PASSWORD"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import {useDispatch} from "store"
import UPDATE_MY_PASSWORD from "api/apollo/mutations/UPDATE_MY_PASSWORD"
import {getApiCallHeaders} from "api/rest"

interface Props {
  userId?: string
}

const passwordRequirements = [
  "Must contain at least 8 characters",
  "Must contain at least one number",
  "Must contain at least one lowercase letter",
  "Must contain at least one uppercase letter"
]

export default function UpdateUserPassword({userId}: Props) {
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [updatePassword] = useMutation<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >(CHANGE_USER_PASSWORD)

  const [updateMyPassword] = useMutation<
    UpdateMyPasswordMutation,
    UpdateMyPasswordMutationVariables
  >(UPDATE_MY_PASSWORD)

  const formInitialValues = useMemo(() => {
    return {
      newPassword: "",
      confirmPassword: ""
    }
  }, [])

  const handleSubmit = async (
    data: typeof formInitialValues,
    {resetForm}: FormikHelpers<typeof formInitialValues>
  ) => {
    if (!data || !Object.values(data)?.length) return

    setIsSubmitting(true)

    try {
      if (userId) {
        await updatePassword({
          variables: {
            userId,
            newPassword: data.newPassword
          }
        })
      } else {
        await updateMyPassword({
          context: {
            headers: await getApiCallHeaders()
          },
          variables: {
            newPassword: data.newPassword
          }
        })
      }

      dispatch(notifyUser({message: "Password updated"}))

      resetForm()
    } catch (err) {
      dispatch(handleError(err))
    } finally {
      setIsSubmitting(false)
    }
  }

  const fullValidatorForSchema = (schema) => (values) => schema.validate(values, {
    abortEarly: false
  }).then(() => ({})).catch(({inner}) => inner.reduce((memo, {path, message}) => ({
    ...memo,
    [path]: (memo[path] || []).concat(message)
  }), {}))

  return (
    <Box width="100%" maxWidth="440px">
      <Typography variant="h5" mt={2} mb={4}>
        Change Password
      </Typography>
      <Formik
        validate={fullValidatorForSchema(Yup.object().shape({
          newPassword: Yup.string()
          .min(8, "Must contain at least 8 characters")
          .matches(/[0-9]/, "Must contain at least one number")
          .matches(/[a-z]/, "Must contain at least one lowercase letter")
          .matches(/[A-Z]/, "Must contain at least one uppercase letter")
          .matches(/^\S*$/, "Cannot contain spaces")
          .max(255)
          .required("Required"),
          confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .required("Required")
        }))}
        initialValues={formInitialValues}
        options
        onSubmit={handleSubmit}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Box mb={1.5}>
                <InputLabel sx={{mb: 1}}>
                  New password
                </InputLabel>
                <PasswordInput
                  name="newPassword"
                  value={values["newPassword"]}
                  error={!!(touched["newPassword"] && errors["newPassword"])}
                  helperText={touched["newPassword"] && errors["newPassword"] && errors["newPassword"][0]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="******"
                  autoComplete="no"
                  fullWidth
                  variant="outlined"
                />
              </Box>
              <Box mb={2}>
                <InputLabel sx={{mb: 1}}>
                  Password confirmation
                </InputLabel>
                <PasswordInput
                  name="confirmPassword"
                  value={values["confirmPassword"]}
                  error={!!(touched["confirmPassword"] && errors["confirmPassword"])}
                  helperText={touched["confirmPassword"] && errors["confirmPassword"] && errors["confirmPassword"][0]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="******"
                  fullWidth
                  variant="outlined"
                />
                {errors["newPassword"] instanceof Array && errors["newPassword"]?.length && (
                  <Box mt={2}>
                    {passwordRequirements.map(i =>
                      <Box
                        key={i}
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          mb: 1
                        }}>
                        {errors["newPassword"] instanceof Array && errors["newPassword"].includes(i as any) ? (
                          <CrossIcon color="error"/>
                        ) : (
                          <CheckmarkIcon color="success"/>
                        )}
                        <Typography color="textPrimary" variant="body1" ml={1}>
                          {i}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained">
                Change Password
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
