/**
 * Generates a unique Cognito username from an email address by removing special characters
 * and adding a timestamp suffix
 *
 * @example
 * generateCognitoUsername("john.doe@example.com")
 * // => "user_johndoeexamplecom_1710852347123"
 *
 * generateCognitoUsername("mary+test@gmail.com")
 * // => "user_marytestgmailcom_1710852347124"
 *
 * generateCognitoUsername("test.user-name+alias@company.org")
 * // => "user_testusernamealiastcompanyorg_1710852347127"
 *
 * @param email - Email address to convert into Cognito username
 * @returns Generated unique username safe for Cognito
 */
export const generateCognitoUsername = (email: string): string => {
    // Remove special characters and add timestamp to ensure uniqueness
    const timestamp = Date.now()
    const sanitized = email.replace(/[^a-zA-Z0-9]/g, "")
    return `user_${sanitized}_${timestamp}`
  }
  