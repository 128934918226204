import {useMemo} from "react"
import {DataTableAvatarCol} from "../types.t"
import {
  Avatar,
  Box,
  SxProps,
  TableCell,
  Theme,
  Typography
} from "@mui/material"

interface Props {
  input: ({id: string} | {_id: string}) & object
  schemaColRef: DataTableAvatarCol
  sx?: SxProps<Theme>
  colSpan?: number
}

export default function AvatarCell({
  input,
  schemaColRef,
  sx,
  colSpan
}: Props) {
  const id = "id" in input ? input?.id : input?._id

  const mappedAvatarFields = useMemo(() => {
    if (!schemaColRef || !input) {
      return ""
    }

    const fieldNameFromSchema = schemaColRef?.fieldName

    if (!fieldNameFromSchema) {
      return ""
    }

    if (typeof fieldNameFromSchema === "string") {
      return fieldNameFromSchema
    }

    return fieldNameFromSchema.map((i, num) => (
      <Typography key={num} color="primary" variant="body2">
        {input[i]}
      </Typography>
    ))
  }, [input, schemaColRef])

  return (
    <TableCell key={`${(id || input[schemaColRef.image])} avatar_cell`} sx={sx} colSpan={colSpan}>
      <Box display="flex" alignItems="center">
        <Avatar
          src={input[schemaColRef.image]}
          sx={{
            height: 42,
            width: 42
          }}
        />
        <Box ml={1}>
          {mappedAvatarFields}
        </Box>
      </Box>
    </TableCell>
  )
}
