import {useMutation, useQuery} from "@apollo/client"
import {LoadingButton} from "@mui/lab"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import CREATE_TESTING_GROUP from "api/apollo/mutations/CREATE_TESTING_GROUP"
import UPDATE_TESTING_GROUP from "api/apollo/mutations/UPDATE_TESTING_GROUP"
import GET_EXAM_CODES from "api/apollo/queries/GET_EXAM_CODES"
import GET_LICENSES from "api/apollo/queries/GET_LICENSES"
import GET_SCHOOL_STAFF from "api/apollo/queries/GET_SCHOOL_STAFF"
import GET_TESTING_GROUP from "api/apollo/queries/GET_TESTING_GROUP"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {LINKS} from "consts/links"
import {useFormik} from "formik"
import {
  CreateTestingGroupInput,
  CreateTestingGroupMutation,
  CreateTestingGroupMutationVariables,
  GetExamCodesQuery,
  GetLicensesQuery,
  GetLicensesQueryVariables,
  GetSchoolStaffQuery,
  GetSchoolStaffQueryVariables,
  GetTestingGroupQuery,
  GetTestingGroupQueryVariables,
  ProctoringType,
  TestingGroupPaymentType,
  UpdateTestingGroupMutation,
  UpdateTestingGroupMutationVariables
} from "generated/graphql"
import useMainPageLink from "hooks/useMainPageLink"
import {useMemo} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import * as Yup from "yup"

type Form = {
  name: string
  teacher: string
  proctoring: ProctoringType
  proctor: string
  examCode: string
  paymentType: TestingGroupPaymentType
  license: string
}

export default function TestingGroupForm() {
  const theme = useTheme()
  const params = useParams()
  const navigate = useNavigate()
  const {mainPageLink} = useMainPageLink()
  const dispatch = useDispatch()

  const schoolLoading = useSelector((store) => store.schoolSlice.loading)
  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)

  const testingGroupQuery = useQuery<
    GetTestingGroupQuery,
    GetTestingGroupQueryVariables
  >(GET_TESTING_GROUP, {
    variables: {
      testingGroupId: params.groupId || ""
    }
  })

  const schoolStaffQuery = useQuery<
    GetSchoolStaffQuery,
    GetSchoolStaffQueryVariables
  >(GET_SCHOOL_STAFF, {
    variables: {
      schoolId
    }
  })

  const examCodesQuery = useQuery<
    GetExamCodesQuery,
    GetExamCodesQuery
  >(GET_EXAM_CODES)

  const licensesQuery = useQuery<
    GetLicensesQuery,
    GetLicensesQueryVariables
  >(GET_LICENSES, {
    variables: {
      schoolId
    }
  })

  const [createTestingGroup, {loading: createTestingGroupLoading}] = useMutation<
    CreateTestingGroupMutation,
    CreateTestingGroupMutationVariables
  >(CREATE_TESTING_GROUP)

  const [updateTestingGroup, {loading: updateTestingGroupLoading}] = useMutation<
    UpdateTestingGroupMutation,
    UpdateTestingGroupMutationVariables
  >(UPDATE_TESTING_GROUP)

  const testingGroup = useMemo(() => {
    return testingGroupQuery.data?.getTestingGroup || null
  }, [testingGroupQuery.data])

  const teacherOptions = useMemo(() => {
    const list = schoolStaffQuery.data?.getSchool.staff.items.map(i => ({
      label: i.email,
      value: i._id
    }))

    if (list?.length) {
      return list
    } else {
      return [{label: "No teachers available", value: ""}]
    }
  }, [schoolStaffQuery.data])

  const proctorOptions = useMemo(() => {
    const list = schoolStaffQuery.data?.getSchool.staff.items.filter(i => (
      i.isProctor
    )).map(i => ({
      label: i.email,
      value: i._id
    })) || []

    if (list?.length) {
      return [{label: "No proctor", value: ""}, ...list]
    } else {
      return [{label: "No proctor", value: ""}]
    }
  }, [schoolStaffQuery.data])

  const proctoringOptions = useMemo(() => {
    return [
      {
        label: "Classroom",
        value: ProctoringType.Classroom
      },
      {
        label: "Online Proctoring",
        value: ProctoringType.Online
      }
    ]
  }, [])

  const paymentTypeOptions = useMemo(() => {
    return [
      {
        label: "License inventory",
        value: TestingGroupPaymentType.LicenseInventory
      },
      {
        label: "Student inventory",
        value: TestingGroupPaymentType.StudentInventory
      }
    ]
  }, [])

  const licenses = useMemo(() => {
    return licensesQuery.data?.licenses.items || []
  }, [licensesQuery.data])

  const examCodes = useMemo(() => {
    return examCodesQuery.data?.exams.items || []
  }, [examCodesQuery.data])

  const examCodeOptions = useMemo(() => {
    return examCodes.filter(i => {
      return !!licenses.find(license => {
        if (!license.isUniversalLicense) {
          return license.examCodes.includes(i.code)
        }

        return true
      })
    }).map(i => ({
      label: `${i.displayCode.toUpperCase()} - ${i.displayName.value}`,
      value: i.code
    }))
  }, [examCodes, licenses])

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit: handleSubmitForm
  } = useFormik<Form>({
    enableReinitialize: true,
    initialValues: {
      name: testingGroup?.name || "",
      teacher: testingGroup?.teacher?._id || "",
      proctoring: testingGroup?.proctoring || proctoringOptions[0].value,
      proctor: testingGroup?.proctor?._id || "",
      examCode: testingGroup?.examCode || "",
      paymentType: testingGroup?.paymentType || paymentTypeOptions[0].value,
      license: testingGroup?.licenseId || ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field is required"),
      teacher: Yup.string().required("Field is required"),
      proctoring: Yup.string().required("Field is required"),
      proctor: Yup.string(),
      examCode: Yup.string().required("Field is required"),
      paymentType: Yup.string().required("Field is required"),
      license: Yup.string().when("paymentType", (paymentType: string) => {
        if (paymentType === TestingGroupPaymentType.StudentInventory) {
          return Yup.string()
        }

        return Yup.string().required("Field is required")
      })
    }),
    onSubmit: handleSubmit
  })

  const licenseOptions = useMemo(() => {
    if (!values.examCode || values.examCode === "default") {
      return [{label: "No licenses available", value: ""}]
    } else {
      const list = licenses.filter(i => {
        if (i.examVouchersRemain > 0 || i.practiceVouchersRemain > 0) {
          if (i.isUniversalLicense) {
            return i
          }

          return i.examCodes.includes(values.examCode)
        }

        return false
      }).map(i => ({
        label: i.name,
        value: i._id
      }))

      if (list?.length) {
        return list
      } else {
        return [{label: "No licenses available", value: ""}]
      }
    }
  }, [licenses, examCodes, values.examCode])

  function handleSubmit(form: Form) {
    const data: CreateTestingGroupInput = {
      schoolId,
      name: form.name,
      teacherUserId: form.teacher,
      proctoring: form.proctoring,
      proctorUserId: form.proctor,
      paymentType: form.paymentType,
      examCode: form.examCode
    }

    if (form.license) {
      data.licenseId = form.license
    }

    if (!form.proctor) {
      delete data.proctorUserId
    }

    if (!!params?.groupId) {
      delete data.schoolId

      updateTestingGroup({
        variables: {
          testingGroupId: testingGroup?._id,
          testingGroupPayload: data
        }
      }).then(() => {
        dispatch(notifyUser({
          message: "Updated successfully",
          variant: "success"
        }))

        testingGroupQuery.refetch()
      }).catch(err => {
        dispatch(handleError(err))
      })
    } else {
      createTestingGroup({
        variables: {
          input: data
        }
      }).then(() => {
        dispatch(notifyUser({
          message: "Testing group created",
          variant: "success"
        }))

        navigate(LINKS.testingGroups)
      }).catch(err => {
        dispatch(handleError(err))
      })
    }
  }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Testing Group"
      title={params?.groupId ? "Testing Group" : "New Testing Group"}
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {
          path: LINKS.testingGroups,
          text: "Testing Groups"
        },
        {
          text: params?.groupId ? "Testing Group" : "Add New Group"
        }
      ]}>
      <Box>
        <Box pb={4}>
          <form onSubmit={handleSubmitForm}>
            <Box>
              {schoolLoading ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={24}>
                  <CircularProgress/>
                </Box>
              ) : (
                <Box mb={4} pt={1}>
                  <Typography mb={3} variant="h5" textAlign="center">
                    {params?.groupId ? "Testing Group" : "Add Testing Group"}
                  </Typography>
                  <Grid container rowSpacing={3} columnSpacing={4} direction="row">
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="text"
                        name="name"
                        label="Group Name"
                        value={values["name"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched["name"] && errors["name"])}
                        helperText={touched["name"] && errors["name"]}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    <Grid item xs={6}>
                      <InputLabel id="teacherLabel" sx={{mb: .5}}>
                        Teacher
                      </InputLabel>
                      <Select
                        fullWidth
                        name="teacher"
                        labelId="teacherLabel"
                        value={values["teacher"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched["teacher"] && errors["teacher"])}>
                        {teacherOptions.map(i => (
                          <MenuItem key={i.label} value={i.value}>
                            {i.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!(touched["teacher"] && errors["teacher"]) && (
                        <Typography variant="subtitle2" color="error">
                          {errors["teacher"] === "required" ? "Field is required" : errors["teacher"]}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel id="proctoringLabel" sx={{mb: .5}}>
                        Proctoring
                      </InputLabel>
                      <Select
                        fullWidth
                        name="proctoring"
                        labelId="proctoringLabel"
                        value={values["proctoring"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched["proctoring"] && errors["proctoring"])}>
                        {proctoringOptions.map(i => (
                          <MenuItem key={i.label} value={i.value}>
                            {i.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!(touched["proctoring"] && errors["proctoring"]) && (
                        <Typography variant="subtitle2" color="error">
                          {errors["proctoring"] === "required" ? "Field is required" : errors["proctoring"]}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel id="proctorLabel" sx={{mb: .5}}>
                        Proctor
                      </InputLabel>
                      <Select
                        fullWidth
                        name="proctor"
                        labelId="proctorLabel"
                        value={values["proctor"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched["proctor"] && errors["proctor"])}>
                        {proctorOptions.map(i => (
                          <MenuItem key={i.label} value={i.value}>
                            {i.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!(touched["proctor"] && errors["proctor"]) && (
                        <Typography variant="subtitle2" color="error">
                          {errors["proctor"] === "required" ? "Field is required" : errors["proctor"]}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel id="examCodeLabel" sx={{mb: .5}}>
                        Exam code
                      </InputLabel>
                      <Select
                        fullWidth
                        name="examCode"
                        labelId="examCodeLabel"
                        value={values["examCode"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched["examCode"] && errors["examCode"])}>
                        {examCodeOptions.map(i => (
                          <MenuItem key={i.label} value={i.value}>
                            {i.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!(touched["examCode"] && errors["examCode"]) && (
                        <Typography variant="subtitle2" color="error">
                          {errors["examCode"] === "required" ? "Field is required" : errors["examCode"]}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel id="paymentTypeLabel" sx={{mb: .5}}>
                        Payment Type
                      </InputLabel>
                      <Select
                        fullWidth
                        name="paymentType"
                        labelId="paymentTypeLabel"
                        value={values["paymentType"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched["paymentType"] && errors["paymentType"])}>
                        {paymentTypeOptions.map(i => (
                          <MenuItem key={i.label} value={i.value}>
                            {i.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!(touched["paymentType"] && errors["paymentType"]) && (
                        <Typography variant="subtitle2" color="error">
                          {errors["paymentType"] === "required" ? "Field is required" : errors["paymentType"]}
                        </Typography>
                      )}
                    </Grid>
                    {values.paymentType === TestingGroupPaymentType.LicenseInventory && (
                      <Grid item xs={6}>
                        <InputLabel id="licenseLabel" sx={{mb: .5}}>
                          License
                        </InputLabel>
                        <Select
                          fullWidth
                          name="license"
                          labelId="licenseLabel"
                          value={values["license"]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(touched["license"] && errors["license"])}>
                          {licenseOptions.map(i => {
                            const license = licenses.find(license => {
                              return license._id === i.value
                            })
                            const examVouchersRemain = license?.examVouchersRemain || 0
                            const practiceVouchersRemain = license?.practiceVouchersRemain || 0

                            return (
                              <MenuItem key={i.label} value={i.value}>
                                {i.label}
                                {license && (
                                  <>
                                    <span style={{marginLeft: theme.spacing(.5)}}>
                                      (
                                    </span>
                                    <span
                                      style={{
                                        color: examVouchersRemain ? theme.palette.success.main : theme.palette.error.main
                                      }}>
                                      Exams: {examVouchersRemain}
                                    </span>
                                    ,
                                    <span
                                      style={{
                                        marginLeft: theme.spacing(.5),
                                        color: practiceVouchersRemain ? theme.palette.success.main : theme.palette.error.main
                                      }}>
                                      Practice: {practiceVouchersRemain}
                                    </span>
                                    )
                                  </>
                                )}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {!!(touched["license"] && errors["license"]) && (
                          <Typography variant="subtitle2" color="error">
                            {errors["license"] === "required" ? "Field is required" : errors["license"]}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
              <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1)
                  }}
                  disabled={schoolLoading || createTestingGroupLoading || updateTestingGroupLoading}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={schoolLoading || createTestingGroupLoading || updateTestingGroupLoading}
                  loading={schoolLoading || createTestingGroupLoading || updateTestingGroupLoading}
                  type="submit">
                  {params?.groupId ? "Save Testing Group" : "Add Testing Group"}
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
