import {gql} from "@apollo/client"

export default gql`
  query GetMyClassrooms(
    $offset: Int!
    $take: Int!
    $sortBy: String
    $order: SortOrder
    $search: String
  ) {
    getMyClassrooms(
      offset: $offset
      take: $take
      sortBy: $sortBy
      order: $order
      search: $search
    ) {
      hasMore
      total
      items {
        _id
        name
        defaultExams {
          _id
          code
          displayCode
          displayName {
            en
            value
          }
        }
        district {
          name
          logo
        }
        school {
          _id
          name
          logo
        }
        teachers {
          _id
          fullName
        }
      }
    }
  }
`
