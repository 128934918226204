import {gql} from "@apollo/client"

export default gql`
  mutation UpdateTestingGroup(
    $testingGroupPayload: UpdateTestingGroupInput!
    $testingGroupId: GraphQLObjectId!
  ) {
    updateTestingGroupById(
      testingGroupPayload: $testingGroupPayload
      testingGroupId: $testingGroupId
    ) {
      _id
    }
  }
`
