import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography
} from "@mui/material"
import {createStyles, makeStyles} from "@mui/styles"
import cn from "classnames"
import {SortOrder} from "generated/graphql"
import {uniqBy} from "lodash"
import React, {ChangeEvent, Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react"
import ActionsCell from "./components/ActionsCell"
import ActionsMenu from "./components/ActionsMenu"
import AvatarCell from "./components/AvatarCell"
import CheckboxCell from "./components/CheckboxCell"
import DefaultCell from "./components/DefaultCell"
import Search from "./components/Search"
import SelectSingleFilter from "./components/SelectSingleFilter"
import ToggleFilter from "./components/ToggleFilter"
import DateFilter from "./components/DateFilter"
import {
  DataTableActionCol,
  DataTableAvatarCol,
  DataTableColConfig,
  DataTableCustomCol,
  DataTableFilter,
  DataTableProps,
  DataTableSelectedList,
  DataTableState,
  DataTableTextCol,
  isInteractiveHeaderText
} from "./types.t"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const useStyles = makeStyles(() => createStyles({
  scrollableMarker: {
    position: "absolute",
    width: 80,
    height: "100%",
    top: 0,
    right: 0,
    background: "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    pointerEvents: "none",
    opacity: 0,
    transition: ".3s"
  },
  visible: {
    opacity: 1,
    transition: ".3s"
  },
  paginationRow: {
    "& .MuiTablePagination-spacer": {
      display: "none"
    }
  },
  additionalFilterContainer: {
    "& > div > div": {
      width: "fit-content",
      minWidth: "58.075%"
    }
  }
}))

export default function DataTable({
  schema,
  data,
  loading,
  loadingMessage,
  error,
  rowsPerPageLimit,
  itemsTotalCount,
  // lastPage,
  onQueryUpdate,
  onSelectionUpdate,
  fullWidth,
  noWrap,
  headerNoWrap,
  contentNoWrap,
  emptyCondition,
  emptyDataMessage = "No Results",
  defaultSort,
  search,
  filters,
  actionButtons,
  actions
}: DataTableProps) {
  const defaultState: DataTableState = useMemo(() => {
    return {
      itemsPerPage: rowsPerPageLimit < 50 ? rowsPerPageLimit : 50,
      page: 0,
      filters: null,
      sort: null,
      searchQuery: null
    }
  }, [rowsPerPageLimit])
  const defaultSelected: DataTableSelectedList = null

  const s = useStyles()

  const [state, setState] = useState<DataTableState>({
    ...defaultState,
    ...(!!defaultSort ? {sort: getSortOption(defaultSort)} : {})
  })
  const [selected, setSelected] = useState<DataTableSelectedList>(defaultSelected)
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)
  const [filtersKey, setFiltersKey] = useState("INITIAL")
  const [list, setList] = useState<typeof data>(data)
  const [total, setTotal] = useState<typeof itemsTotalCount>(itemsTotalCount)
  const [scrollData, setScrollData] = useState<{
    position: number
    width: number
  } | null>(null)

  const rootTopGhostRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const showScrollableMarker = useMemo(() => {
    if (scrollData) {
      const offset = 20

      return (scrollData.width - offset) > scrollData.position
    } else {
      return false
    }
  }, [containerRef, scrollData])

  const rowsPerPageOptions = useMemo(() => {
    return [5, 10, 25, 50, 100].filter(i => i <= (rowsPerPageLimit || 100))
  }, [rowsPerPageLimit])

  const mainFilters = useMemo(() => {
    const target = (filters?.main || []).filter(Boolean)

    return target.length ? target : null
  }, [filters, state])

  const secondaryFilters = useMemo(() => {
    const target = (filters?.secondary || []).filter(Boolean)

    return target.length ? target : null
  }, [filters, state])

  const additionalFilters = useMemo(() => {
    const target = (filters?.additional || []).filter(Boolean)

    return target.length ? target : null
  }, [filters, state])

  const filtersResettable = useMemo(() => {
    return Boolean(filters?.resettable !== false && (mainFilters || secondaryFilters || additionalFilters))
  }, [filters, mainFilters, secondaryFilters, additionalFilters, state])

  const resetFiltersDisabled = useMemo(() => {
    return Boolean(state.filters === null)
  }, [filtersResettable, filters, mainFilters, secondaryFilters, additionalFilters, state])

  const actionButtonsList = useMemo(() => {
    const target = (actionButtons || []).filter(Boolean)

    return target.length ? target : null
  }, [actionButtons, selected])

  const actionsList = useMemo(() => {
    const target = (actions?.items || []).filter(Boolean)

    return target.length ? target : null
  }, [actions, selected])

  const isHeadExist = useMemo(() => {
    return !!search || (mainFilters || secondaryFilters || additionalFilters) || actionButtonsList || actionsList
  }, [search, mainFilters, secondaryFilters, additionalFilters, actionButtonsList, actionsList])

  const searchLabel = useMemo(() => {
    return (typeof search === "string" ? search : typeof search === "object" ? search.label : "")
  }, [search])

  useEffect(() => {
    if (!!data) {
      setList(data)
      setSelected(defaultSelected)
    }
  }, [data])

  useEffect(() => {
    if (!!data) {
      setTotal(itemsTotalCount)
    }
  }, [itemsTotalCount, data])

  useEffect(() => {
    if (!search) {
      setState(current => ({
        ...current,
        searchQuery: defaultState.searchQuery
      }))

      setSelected(defaultSelected)
    }
  }, [search])

  useEffect(() => {
    if (filtersKey !== "INITIAL") {
      setState(current => ({
        ...current,
        page: defaultState.page,
        filters: defaultState.filters
      }))
    }
  }, [filtersKey])

  useEffect(() => {
    if (!!onQueryUpdate) {
      onQueryUpdate(state)
    }
  }, [state])

  useEffect(() => {
    if (!!onSelectionUpdate) {
      onSelectionUpdate(selected)
    }
  }, [selected])

  useEffect(() => {
    setTimeout(() => {
      handleCalcScrollData()
    }, 0)
  }, [containerRef, schema])

  const handleCalcScrollData = () => {
    const el = containerRef?.current

    if (el && (el.scrollWidth - el.clientWidth) > 10) {
      setScrollData({
        position: el.scrollLeft,
        width: el.scrollWidth - el.clientWidth
      })
    } else {
      setScrollData(null)
    }
  }

  const handleScrollToTop = () => {
    const el = rootTopGhostRef?.current

    if (el) {
      el.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

  const handleChangeRowsPerPage = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent
  ) => {
    setState(current => ({
      ...current,
      itemsPerPage: parseInt(e?.target?.value || String(defaultState.itemsPerPage), 10),
      page: defaultState.page
    }))

    setSelected(defaultSelected)

    handleScrollToTop()
  }

  const handleChangePage = (page: number) => {
    if (page !== state.page) {
      setState(current => ({
        ...current,
        page
      }))

      handleScrollToTop()
    }
  }

  function getSortOption(sortValue: DataTableColConfig["sort"], state?: DataTableState) {
    const currentState = state || null
    const key = typeof sortValue === "string" ? sortValue : sortValue[0]
    const defaultOrderDesc = typeof sortValue === "string" ? false : !!sortValue[1].defaultOrderDesc
    const isSelected = currentState?.sort?.key === key
    const orderDesc = (isSelected && currentState?.sort.order === SortOrder.Asc) || (!isSelected && defaultOrderDesc)

    return isSelected && ((defaultOrderDesc && orderDesc) || (!defaultOrderDesc && !orderDesc)) ? null : {
      key,
      order: orderDesc ? SortOrder.Desc : SortOrder.Asc
    }
  }

  const handleChangeSort = (sortValue: DataTableColConfig["sort"]) => {
    setState(current => {
      const sortOption = getSortOption(sortValue, current)

      return {
        ...current,
        page: defaultState.page,
        sort: sortOption
      }
    })

    setSelected(defaultSelected)
  }

  const handleChangeSearchQuery = useCallback((value: string) => {
    setState(current => ({
      ...current,
      page: defaultState.page,
      searchQuery: value || null
    }))

    setSelected(defaultSelected)
  }, [])

  function handleResetFilters() {
    setFiltersKey(Math.random().toString(36).slice(2, 12))
  }

  const handleChangeFilter = (type: DataTableFilter["type"], id: string, value: any) => {
    setState(current => {
      function getParsedValue() {
        if (value === "true") {
          return true
        } else if (value === "false") {
          return false
        } else {
          return value
        }
      }

      const newFilters = uniqBy([
        {
          type,
          id,
          value: getParsedValue()
        },
        ...(current.filters || [])
      ], "id").filter(i => {
        if (typeof i.value === "boolean") {
          return true
        } else {
          return !!i.value
        }
      })

      return {
        ...current,
        page: defaultState.page,
        filters: newFilters.length ? newFilters : defaultState.filters
      }
    })

    setSelected(defaultSelected)
  }

  const handleCheckbox = (id: string) => {
    setSelected(current => {
      const idFound = current?.includes(id)

      return idFound ? current.filter(i => i !== id) : [...(current || []), id]
    })
  }

  const handleSelectAll = (allSelected: boolean) => {
    setSelected(
      allSelected || !list?.length ? defaultSelected : list.map(i => i._id)
    )
  }

  const renderFilter = useCallback((input: DataTableFilter) => {
    const key = `${input?.key || input?.id}-${filtersKey}`

    if (input.type === "select-single") {
      return (
        <SelectSingleFilter
          {...input}
          key={key}
          tableState={state}
          onChange={value => handleChangeFilter(input.type, input.id, value)}
        />
      )
    } else if (input.type === "toggle") {
      return (
        <ToggleFilter
          {...input}
          key={key}
          onChange={value => handleChangeFilter(input.type, input.id, value)}
        />
      )
    } else if (input.type === "date") {
      return (
        <DateFilter
          {...input}
          key={key}
          onChange={value => handleChangeFilter(input.type, input.id, value)}
        />
      )
    } else {
      return null
    }
  }, [state, filtersKey])

  const clearSelected = () => {
    setSelected(defaultSelected)
  }

  return (
    <Box position="relative">
      <Box ref={rootTopGhostRef} position="absolute" top={-40} height="1px"/>
      {isHeadExist && (
        <Box position="relative" width="100%" overflow="auto" className="scrollbar-hidden">
          <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} pt={0.45}>
            <Box display="flex" alignItems="center" gap={1}>
              {!!search && (
                <Box
                  width={(mainFilters?.length && (actionButtonsList?.length || actionsList?.length)) ? 260 : 300}
                  maxWidth="100%">
                  <Search
                    label={searchLabel}
                    disabled={typeof search === "object" && search.disabled}
                    select={typeof search === "object" && search.select}
                    onChange={handleChangeSearchQuery}
                    value={state.searchQuery}
                  />
                </Box>
              )}
              {(mainFilters || []).map(i => (
                <Box key={i.id}>
                  {renderFilter(i)}
                </Box>
              ))}
            </Box>
            {(secondaryFilters?.length || additionalFilters?.length || actionButtonsList?.length || actionsList?.length) && (
              <Box display="flex" alignItems="center" gap={1}>
                {(secondaryFilters || []).map(i => (
                  <Box key={i.id}>
                    {renderFilter(i)}
                  </Box>
                ))}
                {additionalFilters?.length && (
                  <Box>
                    <Button
                      onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}>
                      {showAdditionalFilters ? (
                        <ExpandLessIcon/>
                      ) : (
                        <ExpandMoreIcon/>
                      )}
                      {showAdditionalFilters ? "Less" : "More"} filters
                    </Button>
                  </Box>
                )}
                {actionButtonsList?.length && (
                  <Box display="flex" alignItems="center" gap={1}>
                    {actionButtonsList.map(i => {
                      return !("type" in i) ? (
                        <Button
                          key={i.key}
                          variant={i.variant || "contained"}
                          color={i.color}
                          startIcon={i.icon}
                          onClick={() => i.onClick(state, selected, clearSelected)}
                          disabled={i.disabled}>
                          {i.label}
                        </Button>
                      ) : i.type === "custom" && (
                        <React.Fragment key={i.key}>
                          {i.content(selected, clearSelected, state)}
                        </React.Fragment>
                      )
                    })}
                  </Box>
                )}
                {(filtersResettable || actionsList?.length) && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    flexDirection={actions?.options?.isLeftPosition  ? "row-reverse" : "row"}>
                    {filtersResettable && (
                      <Box>
                        <Tooltip arrow title="Reset filters">
                          <Button
                            sx={{px: 1.5, py: 1.5, minWidth: "unset"}}
                            disabled={resetFiltersDisabled}
                            onClick={handleResetFilters}>
                            <FilterAltOffIcon/>
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                    {actionsList?.length && (
                      <Box>
                        <ActionsMenu
                          options={actions.options}
                          state={state}
                          selected={selected}
                          id={String((list?.length || 0) + total + 1)}
                          actions={actionsList}
                          clearSelected={clearSelected}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {additionalFilters?.length && (
        <Collapse in={showAdditionalFilters}>
          <Box py={2}>
            <Grid container rowSpacing={3} columnSpacing={4}>
              {additionalFilters.map(i => (
                <Grid key={i.id} item xs={6}>
                  <Box>
                    <Divider textAlign="left">
                      <Typography variant="overline">
                        {i.label}
                      </Typography>
                    </Divider>
                    <Box className={s.additionalFilterContainer}>
                      {renderFilter(i)}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>
      )}
      <Box>
        <Box
          my={isHeadExist ? 2.5 : 0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          position="relative">
          {isHeadExist && (
            <Divider/>
          )}
          {(!!list?.length && loading) && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate3d(-50%, -50%, 0)",
                width: "100%"
              }}>
              <LinearProgress color="primary"/>
            </Box>
          )}
        </Box>
        {(!list?.length && loading) ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={24}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
              <CircularProgress/>
              {loadingMessage && (
                <Typography variant="h5">
                  {loadingMessage}
                </Typography>
              )}
            </Box>
          </Box>
        ) : (!loading && !!error) ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={24}>
            <Typography variant="h5">
              {typeof error === "string" ? error : "Something went wrong"}
            </Typography>
          </Box>
        ) : ((emptyCondition && emptyCondition()) || !(schema?.filter(Boolean).length) || (!list?.length && !loading)) ? (
          <Box>
            {typeof emptyDataMessage === "string" ? (
              <Box display="flex" justifyContent="center" alignItems="center" py={24}>
                <Typography variant="h5">
                  {emptyDataMessage}
                </Typography>
              </Box>
            ) : (
              emptyDataMessage
            )}
          </Box>
        ) : (
          <Box position="relative" width="100%" minWidth={fullWidth ? "100%" : 700}>
            <TableContainer
              ref={containerRef}
              sx={{position: "relative", width: "100%"}}
              onScroll={handleCalcScrollData}>
              <Table sx={{tableLayout: fullWidth ? "fixed" : "auto"}}>
                <TableHead>
                  <TableRow sx={{background: "white"}}>
                    {schema.filter(Boolean).map(({
                      headerText = "",
                      ...i
                    }, num, arr) => {
                      const id = `${headerText}-${num}`
                      const isLastItem = num === arr.length - 1
                      const style: SxProps<Theme> = {
                        position: "relative",
                        width: fullWidth ? `${100 / arr.length}%` : undefined,
                        padding: "16px",
                        whiteSpace: (i.headerNoWrap || headerNoWrap || noWrap) ? "nowrap" : "normal",
                        borderTopLeftRadius: num === 0 ? "8px" : 0,
                        borderBottomLeftRadius: num === 0 ? "8px" : 0,
                        borderTopRightRadius: isLastItem ? "8px" : 0,
                        borderBottomRightRadius: isLastItem ? "8px" : 0
                      }
                      const isInteractive = isInteractiveHeaderText(headerText)
                      const sortKey = !i.sort ? null : ((typeof i.sort === "object" ? i.sort[0] : i.sort) || null)
                      const activeSort = state.sort?.key === sortKey ? state.sort.order : null
                      const isSelectAll = i.type === "checkbox" && i.selectAll
                      const checked = selected?.length === list?.length

                      return (
                        isSelectAll ? (
                          <CheckboxCell
                            key={`${(id || num) + "row"}-CheckboxCell-${num}`}
                            id={id}
                            checked={checked}
                            onClick={() => {
                              handleSelectAll(checked)
                            }}
                            showDivider
                            sx={style}
                          />
                        ) : (
                          <TableCell
                            key={id}
                            padding={isInteractive ? "checkbox" : undefined}
                            align={i.align || i.type === "actions" ? "center" : "left"}
                            sx={style}>
                            <Box sx={!!i.sort ? {
                              display: "flex",
                              alignItems: "center",
                              gap: .5
                            } : undefined}>
                              {isInteractive ? headerText.element : (
                                <Typography
                                  variant="body1"
                                  fontWeight="700"
                                  color={activeSort ? "info.main" : undefined}>
                                  {headerText}
                                </Typography>
                              )}
                              {i.sort && (
                                <IconButton
                                  size="small"
                                  sx={{p: 0, ml: "auto"}}
                                  onClick={() => handleChangeSort(i.sort)}>
                                  {activeSort === SortOrder.Asc ? (
                                    <ExpandMoreIcon color="info"/>
                                  ) : activeSort === SortOrder.Desc ? (
                                    <ExpandLessIcon color="info"/>
                                  ) : (
                                    <UnfoldMoreIcon/>
                                  )}
                                </IconButton>
                              )}
                            </Box>
                            {!isLastItem && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  right: 0,
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  width: "1px",
                                  borderRight: theme => `1px solid ${theme.palette.lightGrey.main}`,
                                  height: "calc(100% - 16px)"
                                }}
                              />
                            )}
                          </TableCell>
                        )
                      )
                    })}
                  </TableRow>
                </TableHead>
                <Box height={8}/>
                <TableBody>
                  {list.map((cellData, num, arr) => {
                    const id = "id" in cellData ? cellData?.id : cellData?._id

                    return (
                      <Fragment key={`${id || num}-row`}>
                        <TableRow
                          sx={{
                            position: "relative",
                            background: "white",
                            "&::after": {
                              content: "\"\"",
                              display: "block",
                              height: "16px"
                            }
                          }}>
                          {schema.filter(Boolean).map((
                            i,
                            num,
                            arr
                          ) => {
                            const isLastItem = num === arr.length - 1 || i.colSpan == arr.length
                            const style: SxProps<Theme> = {
                              position: "relative",
                              whiteSpace: ((noWrap || contentNoWrap) ? "nowrap" : i.contentWrap) || undefined,
                              borderTopLeftRadius: num === 0 ? "8px" : 0,
                              borderBottomLeftRadius: num === 0 ? "8px" : 0,
                              borderTopRightRadius: isLastItem ? "8px" : 0,
                              borderBottomRightRadius: isLastItem ? "8px" : 0
                            }

                            if (i.colSpan === 0) {
                              return null
                            }

                            const border = isLastItem ? {} : {
                              "&::after": {
                                content: "''",
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                width: "1px",
                                borderRight: theme => `1px solid ${theme.palette.lightGrey.main}`,
                                height: "calc(100% - 32px)"
                              }
                            }

                            return (
                              <Fragment key={`${id || num}-row-${i.type || "default"}Cell-${num}`}>
                                {i.type === "actions" ? (
                                  <ActionsCell
                                    colSpan={i.colSpan}
                                    input={cellData}
                                    actions={(i as DataTableActionCol).actions}
                                    sx={{
                                      ...style,
                                      ...border
                                    }}
                                  />
                                ) : i.type === "avatar" ? (
                                  <AvatarCell
                                    input={cellData}
                                    schemaColRef={i as DataTableAvatarCol}
                                    sx={{
                                      ...style,
                                      ...border
                                    }}
                                  />
                                ) : i.type === "checkbox" ? (() => {
                                  const id = i.key(cellData)
                                  const checked = i.checked ? i.checked(cellData) : !!selected?.find(i => i === id)
                                  const disabled = !!i.disabled ? i.disabled(cellData) : false

                                  return (
                                    <CheckboxCell
                                      colSpan={i.colSpan}
                                      id={id}
                                      checked={checked}
                                      disabled={disabled}
                                      onClick={id => {
                                        handleCheckbox(id)

                                        if (!!i.handler) {
                                          i.handler(!checked, cellData)
                                        }
                                      }}
                                      sx={{
                                        ...style,
                                        ...border
                                      }}
                                    />
                                  )
                                })() : i.type === "custom" ? (
                                  <TableCell
                                    colSpan={i.colSpan}
                                    sx={{
                                      whiteSpace: "pre-line",
                                      padding: i.withoutPadding ? 0 : undefined,
                                      ...style,
                                      ...border
                                    }}>
                                    {(i as DataTableCustomCol).content(cellData)}
                                  </TableCell>
                                ) : (
                                  <DefaultCell
                                    align={i.align}
                                    colSpan={i.colSpan}
                                    input={cellData}
                                    schemaColRef={i as DataTableTextCol}
                                    sx={{
                                      ...style,
                                      ...border
                                    }}
                                  />
                                )}
                              </Fragment>
                            )
                          })}
                        </TableRow>
                        {num !== arr.length - 1 && (
                          <Box height={8}/>
                        )}
                      </Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={cn(s.scrollableMarker, {[s.visible]: showScrollableMarker})}/>
            {!!total && total > 5 && (
              <Box display="flex" flexDirection="column" gap={2.5} mt={2.5}>
                <Divider/>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                  <Box mr={1}>
                    <Select
                      disabled={loading}
                      value={state.itemsPerPage.toString()}
                      onChange={handleChangeRowsPerPage}>
                      {rowsPerPageOptions.map(i => (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Pagination
                    count={Math.ceil(total / state.itemsPerPage)}
                    variant="outlined"
                    shape="rounded"
                    page={state.page + 1}
                    onChange={(_e, v) => handleChangePage(v - 1)}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        disabled={
                          item.disabled ||
                          loading
                        }
                        sx={{
                          border: ["next", "previous"].includes(item.type) ? "none" : undefined
                        }}
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
