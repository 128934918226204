import {Box, Typography} from "@mui/material"
import {BoxProps} from "@mui/material/Box"
import MD from "components/MD"
import {isEqual} from "lodash"
import {useEffect, useMemo, useState} from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd"

interface Props {
  description?: string
  code?: string
  codeLang?: string
  options: Array<{
    id: string
    content: string
  }>
  onChange?: (
    value: Array<{
      id: string
      position: number
    }>
  ) => void
  config?: {
    fontFamily?: string
    codeBgColor?: string
    optionBgColor?: string
  }
  answer?: Array<{
    id: string
    position: number
  }> | null
  givenAnswer?: Array<{
    id?: string
    position?: number
  }>
  inactive?: boolean
}

export default function ParsonsTask({
  description,
  code,
  codeLang,
  options,
  onChange,
  config: configProp,
  answer,
  givenAnswer,
  inactive
}: Props) {
  const [localOptions, setLocalOptions] = useState<Props["options"] | null>(null)
  const [value, setValue] = useState<Array<{
    id: string
    position: number
  }>>(null)

  const config: Props["config"] = useMemo(() => ({
    fontFamily: configProp?.fontFamily || "Roboto, sans-serif",
    codeBgColor: configProp?.codeBgColor || "rgba(32, 146, 192, 0.04)",
    optionBgColor: configProp?.optionBgColor || "rgba(0, 40, 85, 0.04)"
  }), [])

  const correctAnswer = useMemo(() => {
    if (options && answer) {
      return answer.map(i => ({
        id: i.id,
        position: i.position,
        content: options.find(option => option.id === i.id).content
      })).sort((a, b) => a.position - b.position)
    } else {
      return null
    }
  }, [options, answer])

  const MDStyles = useMemo(() => {
    return {
      default: {
        "& .wmde-markdown code": {
          backgroundColor: "transparent"
        },
        "& .wmde-markdown pre > code": {
          px: 2,
          py: 1.5,
          borderRadius: "4px",
          backgroundColor: config.codeBgColor
        },
        "& .wmde-markdown pre .copied": {
          top: 10,
          right: 10
        }
      },
      option: {
        "& .wmde-markdown code": {
          backgroundColor: "transparent"
        },
        "& .wmde-markdown pre": {
          backgroundColor: "transparent"
        },
        "& .wmde-markdown pre > code": {
          padding: "12px",
          whiteSpace: "pre-wrap",
          borderRadius: "4px",
          backgroundColor: config.optionBgColor
        },
        "& .wmde-markdown pre .copied": {
          top: 10,
          right: 10
        }
      }
    }
  }, [config])

  const sortedOptions: typeof localOptions = useMemo(() => {
    if (!value) {
      return localOptions
    } else {
      return value.map(v => localOptions.find(i => {
        return i.id === v.id
      }))
    }
  }, [localOptions, value])

  useEffect(() => {
    if (options?.length && !localOptions?.length) {
      setLocalOptions(options)
    }
  }, [options, localOptions])

  useEffect(() => {
    if (givenAnswer?.length) {
      setValue(current => {
        const newValue = givenAnswer.map(i => ({
          id: i.id,
          position: i.position
        }))

        if (isEqual(givenAnswer, value)) {
          return current
        }

        return newValue
      })
    }
  }, [givenAnswer, localOptions])

  useEffect(() => {
    if (localOptions && !value) {
      setValue(localOptions.map((i: any, num) => ({
        id: i.id,
        position: num + 1
      })))
    }
  }, [localOptions, value])

  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  }, [value])

  const handleChange = (result: DropResult) => {
    if (inactive) return

    const {source, destination} = result

    if (!destination) return

    const list = Array.from(sortedOptions)
    const [movedItem] = list.splice(source.index, 1)

    list.splice(destination.index, 0, movedItem)

    setValue(list.map((i, num) => ({
      id: i.id,
      position: num + 1
    })))
  }

  const Option = useMemo(() => ({
    wrapperProps,
    num,
    content,
    bgColor,
    borderColor = "rgba(0, 40, 85, 0.1)"
  }: {
    wrapperProps?: BoxProps
    num: number
    content: string
    bgColor?: string
    borderColor?: string
  }) => {
    return (
      <Box
        {...(wrapperProps || {})}
        position="relative"
        width="100%"
        pb={1}
        display="grid"
        gridTemplateColumns="64px calc(100% - 72px)"
        gap={1}
        sx={{
          pointerEvents: inactive ? "none" : "auto"
        }}>
        <Box
          width="100%"
          height="100%"
          px={1.5}
          borderRadius="4px"
          bgcolor={bgColor || config.optionBgColor}
          border={`1px solid ${borderColor}`}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Typography fontFamily={config.fontFamily}>
            {num}
          </Typography>
        </Box>
        <Box
          width="100%"
          borderRadius="4px"
          sx={{
            ...MDStyles.option,
            "& .wmde-markdown pre code": {
              backgroundColor: bgColor || config.optionBgColor
            }
          }}>
          <MD
            fontFamily={config.fontFamily}
            value={"```" + ((codeLang + "\n") || "") + content}
            fullWidth
            inactive
          />
        </Box>
      </Box>
    )
  }, [config, MDStyles])

  return (
    <Box position="relative" display="inline-block" minWidth="500px" maxWidth="800px">
      <Box mb={4}>
        <Box display="flex" gap={1} sx={MDStyles.default}>
          <Typography fontWeight="700">
            Question:
          </Typography>
          <MD
            fontFamily={config.fontFamily}
            value={description}
          />
        </Box>
        {code && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              mt: 1.5,
              borderRadius: "4px",
              ...MDStyles.default
            }}>
            <MD
              fontFamily={config.fontFamily}
              value={"```" + ((codeLang + "\n") || "") + code}
              fullWidth
            />
          </Box>
        )}
      </Box>
      <Box position="relative" width="100%">
        {sortedOptions && (
          <DragDropContext onDragEnd={handleChange}>
            <Droppable droppableId="parsonsTask" isDropDisabled={inactive}>
              {provided => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  <Box position="relative" width="100%">
                    {sortedOptions.map((i, num) => (
                      <Draggable
                        key={i.id}
                        draggableId={i.id}
                        index={num}
                        isDragDisabled={inactive}>
                        {provided => (
                          <Option
                            wrapperProps={{
                              ref: provided.innerRef,
                              ...provided.draggableProps,
                              ...provided.dragHandleProps
                            }}
                            num={num + 1}
                            content={i.content}
                          />
                        )}
                      </Draggable>
                    ))}
                  </Box>
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {answer && (
          <Box
            position="relative"
            width="100%"
            mt={4}
            py={2}
            px={2.5}
            borderRadius="6px"
            bgcolor="rgba(39, 174, 96, 0.1)"
            overflow="hidden">
            <Box
              position="absolute"
              width={4}
              height="100%"
              top={0}
              left={0}
              bgcolor="rgba(39, 174, 96, 1)"
            />
            <Typography mb={1} fontFamily={config.fontFamily}>
              <strong>
                Right answer:
              </strong>
            </Typography>
            <Box display="flex" flexDirection="column">
              {correctAnswer.map((i, num) => (
                <Option
                  key={i.id}
                  num={num + 1}
                  content={i.content}
                  bgColor="rgba(39, 174, 96, 0.1)"
                  borderColor="rgba(39, 174, 96, 0.2)"
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
