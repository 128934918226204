import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from "@mui/material"
import CodeEditor from "components/CodeEditor"
import ConfirmationAlert from "components/ConfirmationAlert"
import MD from "components/MD"
import useSphereEngine from "hooks/useSphereEngine"
import React, {useEffect, useMemo, useState} from "react"
import practicalExamStorage from "utils/practicalExamStorage"

interface Props {
  sessionId?: string
  task: {
    id: string
    compilerId: number
    compilerVersionId: number
  }
  overview?: string
  codeLang: string
  template?: string
  onChange?: (value: string) => void
  description?: string
  config?: {
    fontFamily?: string
    descriptionBgColor?: string
    expandedView?: boolean
  }
  inactive?: boolean
}

export default function CodeTask({
  sessionId,
  task,
  overview,
  codeLang,
  template,
  onChange,
  description,
  config: configProp,
  inactive
}: Props) {
  const {
    compileAnswer,
    runDisabled,
    onRunCode
  } = useSphereEngine(task.id, task.compilerId, task.compilerVersionId)

  const [descriptionExpanded, setDescriptionExpanded] = useState(false)
  const [localAnswer, setLocalAnswer] = useState(template)
  const [showContinueDialog, setShowContinueDialog] = useState(false)
  const [savingStatus, setSavingStatus] = useState<string | null>(null)

  const savedProgress = useMemo(() => {
    if (sessionId) {
      return practicalExamStorage.getItem(sessionId, task.id)
    }
  }, [sessionId])

  const config: Props["config"] = useMemo(() => ({
    fontFamily: configProp?.fontFamily || "Roboto, sans-serif",
    descriptionBgColor: configProp?.descriptionBgColor || "rgba(237, 237, 237, 1)",
    expandedView: configProp?.expandedView || false
  }), [configProp])

  const alert = useMemo(() => {
    if (savingStatus) {
      return {
        name: savingStatus,
        code: 0
      }
    } else {
      return compileAnswer ? {
        code: compileAnswer.status_code,
        name: compileAnswer.status_name,
        labels: {
          15: "compiled"
        }
      } : null
    }
  }, [savingStatus, compileAnswer])

  useEffect(() => {
    if (savedProgress && !inactive && onChange) {
      setShowContinueDialog(true)
    }
  }, [savedProgress, inactive])

  useEffect(() => {
    if (!inactive && onChange) {
      onChange(localAnswer)
    }
  }, [localAnswer, inactive])

  const handleRestoreProgress = () => {
    if (savedProgress) {
      setLocalAnswer(savedProgress.value)
      setLocalAnswer(savedProgress.value)
    }

    setShowContinueDialog(false)
  }

  const handleAnswerChange = (value: string) => {
    setLocalAnswer(value)
  }

  const handleRunCode = (code: string) => {
    onRunCode(code)
    setLocalAnswer(code || " ")
    practicalExamStorage.setItem(sessionId, task.id, code)
  }

  const handleSave = (code: string) => {
    if (localAnswer) {
      setSavingStatus("saving...")

      practicalExamStorage.setItem(sessionId, task.id, code)

      setTimeout(() => {
        setSavingStatus("saved")

        setTimeout(() => {
          setSavingStatus(null)
        }, 2000)
      }, 500)
    }
  }

  return (
    <Box position="relative" width="100%" height="100%" display="flex" flexDirection="column">
      {description && (
        <Box position="relative" zIndex={11}>
          <Collapse
            in={!descriptionExpanded}
            collapsedSize={config.expandedView ? 0 : 16}
            sx={{
              position: "relative",
              mb: (config.expandedView && descriptionExpanded) ? 0 : 1,
              borderRadius: config.expandedView ? 0 : "12px",
              bgcolor: config.descriptionBgColor
            }}>
            <Box
              position="relative"
              p={2}
              pb={!!description && 3}>
              {description && (
                <Box display="flex" gap={1}>
                  <Typography fontWeight="700">
                    Question:
                  </Typography>
                  <MD
                    fontFamily={config.fontFamily}
                    value={description}
                  />
                </Box>
              )}
            </Box>
          </Collapse>
          {!!description && (
            <Box
              position="absolute"
              zIndex={3}
              width="100%"
              left={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                bottom: !descriptionExpanded || !config.expandedView ? -20 : -28,
                transition: ".2s"
              }}
            >
              <IconButton
                sx={{
                  p: "0 12px 8px",
                  backgroundColor: config.descriptionBgColor,
                  borderRadius: "0 0 50% 50%",
                  color: "rgba(28, 27, 31, 1)",
                  transition: ".2s",
                  "&:hover": {
                    backgroundColor: config.descriptionBgColor
                  },
                  "& .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    transform: descriptionExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: ".2s"
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none"
                  }
                }}
                onClick={() => setDescriptionExpanded(!descriptionExpanded)}>
                <ArrowUpwardIcon/>
              </IconButton>
            </Box>
          )}
        </Box>
      )}
      <Box
        position="relative"
        flexGrow={1}
        width="100%"
        borderRadius={!config.expandedView && "12px"}
        border={!config.expandedView && "1px solid rgba(235, 235, 235, 1)"}
        bgcolor="white.main"
        overflow="hidden"
        sx={{
          aspectRatio: config.expandedView ? undefined : "16/8",
          pointerEvents: inactive && "none !important",
          "& *": {
            pointerEvents: inactive && "none !important"
          }
        }}>
        <CodeEditor
          vertical
          overview={overview && {
            input: overview
          }}
          code={{
            lang: codeLang,
            template,
            input: localAnswer,
            onRun: handleRunCode,
            onSave: handleSave,
            submissionLoading: runDisabled,
            noControls: !onChange
          }}
          alert={alert}
          compiled={compileAnswer?.cmpinfo || compileAnswer?.error || compileAnswer?.output || compileAnswer?.signal_desc}
          onChange={!inactive && handleAnswerChange}
        />
        {inactive && (
          <Box
            position="absolute"
            zIndex={100}
            width="100%"
            height="100%"
            top={0}
            left={0}
          />
        )}
        <ConfirmationAlert
          isOpen={showContinueDialog}
          setOpen={setShowContinueDialog}
          handleConfirm={handleRestoreProgress}
          handleCancel={() => setShowContinueDialog(false)}
          dialogTitle="Continue where you left off?"
          cancelButton={{variant: "contained", color: "secondary", text: "NO"}}
          confirmButton={{variant: "contained", color: "error", text: "YES"}}
        />
      </Box>
    </Box>
  )
}
