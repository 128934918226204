import {useLazyQuery, useMutation} from "@apollo/client"
import {FileDownload} from "@mui/icons-material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import LockIcon from "@mui/icons-material/Lock"
import RefreshIcon from "@mui/icons-material/Refresh"
import VideocamIcon from "@mui/icons-material/Videocam"
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from "@mui/material"
import {useTheme} from "@mui/styles"
import CANCEL_EXAM_SESSION from "api/apollo/mutations/CANCEL_EXAM_SESSION"
import MARK_EXAM_SESSION_REVIEWED from "api/apollo/mutations/MARK_EXAM_SESSION_REVIEWED"
import REPORT_EXAM_SESSION_VIOLATION from "api/apollo/mutations/REPORT_EXAM_SESSION_VIOLATION"
import RESET_CERTIFICATE from "api/apollo/mutations/RESET_CERTIFICATE"
import EXPORT_EXAM_SESSIONS from "api/apollo/queries/EXPORT_EXAM_SESSIONS"
import EXPORT_PDF_CERTIFICATES from "api/apollo/queries/EXPORT_PDF_CERTIFICATES"
import EXPORT_SESSIONS_BY_OBJECTIVES_RESULTS from "api/apollo/queries/EXPORT_SESSIONS_BY_OBJECTIVES_RESULTS"
import EXPORT_TESTING_SESSIONS from "api/apollo/queries/EXPORT_TESTING_SESSIONS"
import GET_CLASSROOM_DEFAULT_EXAMS from "api/apollo/queries/GET_CLASSROOM_DEFAULT_EXAMS"
import GET_CLASSROOM_STUDENTS_IDS from "api/apollo/queries/GET_CLASSROOM_STUDENTS_IDS"
import GET_DISTRICT_SCHOOL_IDS from "api/apollo/queries/GET_DISTRICT_SCHOOL_IDS"
import GET_EXAM_CODES from "api/apollo/queries/GET_EXAM_CODES"
import GET_EXAM_SESSIONS from "api/apollo/queries/GET_EXAM_SESSIONS"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import GET_SCHOOL_STUDENTS_IDS from "api/apollo/queries/GET_SCHOOL_STUDENTS_IDS"
import GET_TESTING_GROUPS_IDS from "api/apollo/queries/GET_TESTING_GROUPS_IDS"
import GET_TESTING_SESSIONS from "api/apollo/queries/GET_TESTING_SESSIONS"
import GET_USER_IDS from "api/apollo/queries/GET_USER_IDS"
import {getApiCallHeaders} from "api/rest"
import ConfirmationAlert from "components/ConfirmationAlert"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableActionClick,
  DataTableActions,
  DataTableFilters,
  DataTableQueryUpdate,
  DataTableSchema,
  DataTableState
} from "components/DataTable/types.t"
import SessionResultProgress from "components/SessionResultProgress"
import ExamCard from "./components/ExamCard"
import ScoreLinear from "components/ScoreLinear"
import {LINKS} from "consts/links"
import {
  CancelExamSessionMutation,
  CancelExamSessionMutationVariables,
  Exam,
  ExamSession,
  ExamSessionStatus,
  ExportExamSessionsQuery,
  ExportExamSessionsQueryVariables,
  ExportPdfCertificatesQuery,
  ExportPdfCertificatesQueryVariables,
  ExportSessionsByObjectivesResultsQuery,
  ExportSessionsByObjectivesResultsQueryVariables,
  ExportTestingSessionsQuery,
  ExportTestingSessionsQueryVariables,
  GetClassroomDefaultExamsQuery,
  GetClassroomDefaultExamsQueryVariables,
  GetClassroomStudentsIdsQuery,
  GetClassroomStudentsIdsQueryVariables,
  GetDistrictSchoolIdsQuery,
  GetDistrictSchoolIdsQueryVariables,
  GetExamCodesQuery,
  GetExamCodesQueryVariables,
  GetExamSessionsQuery,
  GetExamSessionsQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  GetSchoolStudentsIdsQuery,
  GetSchoolStudentsIdsQueryVariables,
  GetTestingGroupsIdsQuery,
  GetTestingGroupsIdsQueryVariables,
  GetTestingSessionsQuery,
  GetTestingSessionsQueryVariables,
  GetUserIdsQuery,
  GetUserIdsQueryVariables,
  MarkExamSessionAsReviewedMutation,
  MarkExamSessionAsReviewedMutationVariables,
  ReportExamSessionViolationMutation,
  ReportExamSessionViolationMutationVariables,
  ResetCertsPrerenderedFieldsMutation,
  ResetCertsPrerenderedFieldsMutationVariables,
  School,
  SessionType,
  TestingGroup,
  TestingSession,
  TestingSessionStatus,
  User
} from "generated/graphql"
import JsFileDownloader from "js-file-downloader"
import React, {useMemo, useState} from "react"
import {Link as RouterLink} from "react-router-dom"
import {DeepPartial} from "redux"
import {useDispatch, useSelector} from "store"
import {customNotifications} from "store/slices/notifier/notificationObject"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import {Roles} from "types/access"
import {QueryDataType} from "types/typeUtils"
import formatDate from "utils/formatDate"
import getExamCodeName from "utils/getExamCodeName"
import getExamSessionStatusName from "utils/getExamSessionStatusName"
import getIsSessionCompleted from "utils/getIsSessionCompleted"
import getSessionDuration from "utils/getSessionDuration"

type ContentTypes = "examAdmin" | "examDistrict" | "exam" | "testing" | "examIndividual" | "testingIndividual" | "testingAdmin" | "testingDistrict"
type ModalTypes = "CANCEL" | "EXPORT_LOADING" | "PROCTOR_REPORT" | "CERT_GENERATED"
type ViolationTypes = "NO_VIOLATION" | "VIOLATION"

interface Props {
  type: ContentTypes
  schoolId?: string
  classroomId?: string
  testingGroupId?: string
  userId?: string
  districtId?: string
  detailsLink?: string
  defaultExamFilter?: string[]
  statusFilter?: ExamSessionStatus
}

function SessionsTableComponent(props: Props) {
  const theme = useTheme()
  const dispatch = useDispatch()

  const user = useSelector(store => store.userSlice)

  const [usersData, setUsersData] = useState<QueryDataType<Partial<User>>>(null)
  const [examCodesData, setExamCodesData] = useState<
    DeepPartial<Exam>[] | null
  >(null)
  const [schoolIdsData, setSchoolIdsData] = useState<
    Partial<School>[] | null
  >(null)
  const [testingGroupsData, setTestingGroupsData] = useState<QueryDataType<Partial<TestingGroup>>>(null)
  const [sessionsData, setSessionsData] = useState<QueryDataType<DeepPartial<TestingSession | ExamSession>>>(null)
  const [query, setQuery] = useState<DataTableState | null>(null)
  const [modal, setModal] = useState<ModalTypes | null>(null)
  const [sessionToManage, setSessionToManage] = useState<ExamSession | null>(null)
  const [cancelReason, setCancelReason] = useState<string>("")
  const [violation, setViolation] = useState<ViolationTypes>("NO_VIOLATION")
  const [violationNote, setViolationNote] = useState("")
  const [generatedCert, setGeneratedCert] = useState<string | null>(null)

  const schoolId = useMemo(() => {
    return props.schoolId || query?.filters?.find(i => i.id === "schoolId")?.value as string
  }, [props.schoolId, query])

  const classroomId = useMemo(() => {
    return props.classroomId || query?.filters?.find(i => i.id === "classroomId")?.value as string
  }, [props.classroomId, query])

  const [usersIdsQueryFetch, usersIdsQuery] = useLazyQuery<
    GetUserIdsQuery,
    GetUserIdsQueryVariables
  >(GET_USER_IDS)

  const [schoolStudentsIdsQueryFetch, schoolStudentsIdsQuery] = useLazyQuery<
    GetSchoolStudentsIdsQuery,
    GetSchoolStudentsIdsQueryVariables
  >(GET_SCHOOL_STUDENTS_IDS)

  const [classroomStudentsIdsQueryFetch, classroomStudentsIdsQuery] = useLazyQuery<
    GetClassroomStudentsIdsQuery,
    GetClassroomStudentsIdsQueryVariables
  >(GET_CLASSROOM_STUDENTS_IDS)

  const [examCodesQueryFetch, examCodesQuery] = useLazyQuery<
    GetExamCodesQuery,
    GetExamCodesQueryVariables
  >(GET_EXAM_CODES)

  const [classroomDefaultExamsQueryFetch, classroomDefaultExamsQuery] = useLazyQuery<
    GetClassroomDefaultExamsQuery,
    GetClassroomDefaultExamsQueryVariables
  >(GET_CLASSROOM_DEFAULT_EXAMS)

  const [districtSchoolIdsQueryFetch, districtSchoolIdsQuery] = useLazyQuery<
    GetDistrictSchoolIdsQuery,
    GetDistrictSchoolIdsQueryVariables
  >(GET_DISTRICT_SCHOOL_IDS)

  const [schoolIdsQueryFetch, schoolIdsQuery] = useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [testingGroupsQueryFetch, testingGroupsQuery] = useLazyQuery<
    GetTestingGroupsIdsQuery,
    GetTestingGroupsIdsQueryVariables
  >(GET_TESTING_GROUPS_IDS)

  const [examSessionsQueryFetch, examSessionsQuery] = useLazyQuery<
    GetExamSessionsQuery,
    GetExamSessionsQueryVariables
  >(GET_EXAM_SESSIONS)

  const [testingSessionsQueryFetch, testingSessionsQuery] = useLazyQuery<
    GetTestingSessionsQuery,
    GetTestingSessionsQueryVariables
  >(GET_TESTING_SESSIONS)

  const [exportPdfCertificatesQuery] = useLazyQuery<
    ExportPdfCertificatesQuery,
    ExportPdfCertificatesQueryVariables
  >(EXPORT_PDF_CERTIFICATES)

  const [exportExamSessions] = useLazyQuery<
    ExportExamSessionsQuery,
    ExportExamSessionsQueryVariables
  >(EXPORT_EXAM_SESSIONS, {
    fetchPolicy: "network-only"
  })

  const [exportTestingSessions] = useLazyQuery<
    ExportTestingSessionsQuery,
    ExportTestingSessionsQueryVariables
  >(EXPORT_TESTING_SESSIONS, {
    fetchPolicy: "network-only"
  })

  const [exportSessionsByObjectivesResults] = useLazyQuery<
    ExportSessionsByObjectivesResultsQuery,
    ExportSessionsByObjectivesResultsQueryVariables
  >(EXPORT_SESSIONS_BY_OBJECTIVES_RESULTS, {
    fetchPolicy: "network-only"
  })

  const [cancelSession, {
    loading: cancelSessionLoading
  }] = useMutation<
    CancelExamSessionMutation,
    CancelExamSessionMutationVariables
  >(CANCEL_EXAM_SESSION)

  const [reportExamSessionViolation] = useMutation<
    ReportExamSessionViolationMutation,
    ReportExamSessionViolationMutationVariables
  >(REPORT_EXAM_SESSION_VIOLATION)

  const [markExamSessionReviewed] = useMutation<
    MarkExamSessionAsReviewedMutation,
    MarkExamSessionAsReviewedMutationVariables
  >(MARK_EXAM_SESSION_REVIEWED)

  const [resetCertificate] = useMutation<
    ResetCertsPrerenderedFieldsMutation,
    ResetCertsPrerenderedFieldsMutationVariables
  >(RESET_CERTIFICATE)

  const isTesting = useMemo(() => {
    return ["testing", "testingIndividual", "testingDistrict"].includes(props.type)
  }, [props.type])

  const isIndividual = useMemo(() => {
    return ["examIndividual", "testingIndividual"].includes(props.type)
  }, [props.type])

  const usersLoading = useMemo(() => {
    return (
      usersIdsQuery.loading
    ) || (
      schoolStudentsIdsQuery.loading
    ) || (
      classroomStudentsIdsQuery.loading
    ) || false
  }, [
    usersIdsQuery.loading,
    schoolStudentsIdsQuery.loading,
    classroomStudentsIdsQuery.loading
  ])

  const sessionsLoading = useMemo(() => {
    return examSessionsQuery.loading || (
      testingSessionsQuery.loading
    ) || false
  }, [
    testingSessionsQuery.loading,
    examSessionsQuery.loading
  ])

  const sessionsError = useMemo(() => {
    return !sessionsData && ((
      examSessionsQuery.error
    ) || (
      testingSessionsQuery.error
    ) || null)
  }, [
    sessionsData,
    examSessionsQuery.error,
    testingSessionsQuery.error
  ])

  const searchOptions = useMemo(() => {
    return (usersData?.items || []).map(i => ({
      label: `${i.lastName} ${i.firstName}`,
      value: i._id
    }))
  }, [usersData])

  const hasRecordings = useMemo(() => {
    if (!isTesting && !!sessionsData?.items) {
      return !!sessionsData.items.map(i => {
        return "examus" in i && i?.examus && i.examus?.report?.archive
      }).filter(Boolean).length
    } else {
      return false
    }
  }, [isTesting, sessionsData])

  const tableSchema: DataTableSchema<ExamSession | TestingSession> = useMemo(() => {
    return [
      !isIndividual && {
        type: "checkbox",
        key: data => data._id,
        disabled: data => {
          const isTesting = data.__typename === "TestingSession"

          return !(isTesting ? (
            data.status === TestingSessionStatus.Finished
          ) : (
            data.status === ExamSessionStatus.ReviewedByProctor && data.isPassed
          ))
        }
      },
      !isIndividual && {
        type: "custom",
        headerText: "Last Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        sort: "user.lastName",
        content: data => {
          return (
            <Box display="flex" alignItems="center">
              {data.user?.lastName}
              {props.type === "examAdmin" && data.user?._id && (
                <Box ml={0.5}>
                  <Link href={`${LINKS.adminUsers}/${data.user._id}`}>
                    <IconButton size="small">
                      <OpenInNewIcon/>
                    </IconButton>
                  </Link>
                </Box>
              )}
            </Box>
          )
        }
      },
      !isIndividual && {
        type: "text",
        headerText: "First Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "user.firstName",
        sort: "user.firstName"
      },
      {
        type: "custom",
        headerText: "Details",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const status = getExamSessionStatusName(data.status)
          const isTesting = data.__typename === "TestingSession"
          const displayName = getExamCodeName(data.examCode)
          const examGroup = (!isTesting && data.testingGroup) || null
          const mode = isTesting && (data.options.showResult === "end" ? "Exam Mode " : "Practice Mode")
          const canceledBy = (!isTesting && data.canceledBy?.fullName) || null
          const cancelReason = (!isTesting && data.cancelReason) || null
          const retake = (!isTesting && data.consideredAsRetake) || null
          const disableAi = (isTesting && data.options.disableAi) || false
          const voucherCode = (!isTesting && data.voucherCode) || null
          const isCompleted = getIsSessionCompleted(data.status)
          const isPassed = !isCompleted ? false : (isTesting ? data?.result?.isPassed : data?.isPassed)
          const certificateId = (!isTesting && data?.certificateId) || null

          const startDate = data.startDate || data.createdAt
          const finishDate = data.finishDate

          const duration = getSessionDuration(startDate, finishDate)

          return (
            <ExamCard
              canceledBy={canceledBy}
              cancelReason={cancelReason}
              examGroup={examGroup}
              status={status}
              duration={duration}
              isTesting={isTesting}
              disableAi={disableAi}
              createdAt={data?.createdAt}
              displayName={displayName}
              examCode={data?.examCode}
              mode={mode}
              retake={retake}
              voucherCode={voucherCode}
              isCompleted={isCompleted}
              isPassed={isPassed}
              certificateId={certificateId}
              type={props?.type}
              updatedAt={data?.updatedAt}
              handleResetCertificate={() => handleResetCertificate(data.user._id)}
            />
          )
        },
        sort: ["createdAt", {
          defaultOrderDesc: true
        }]
      },
      {
        type: "custom",
        headerText: "Score",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const isTesting = data.__typename === "TestingSession"
          const isCompleted = getIsSessionCompleted(data.status)
          const isCertificateExists = isTesting ? false : Boolean(data.certificateId)
          const userCanReviewAndCancel = !isTesting ? (
            (!props.districtId && user?.roles.includes(Roles.Admin)) || data?.testingGroup?.proctor?._id === user?.id
          ) : false
          const limitedAccessMessage = !isTesting ? (
            <>
              Action Restricted.<br />
              Please contact <span style={{fontWeight: "bold"}}>{data?.testingGroup?.proctor?.fullName || "Admin"}</span>
            </>
          ) : null
          const reviewButtonCondition = (!isTesting && !!data.finishDate && !isCertificateExists && ([
            ExamSessionStatus.Finished,
            ExamSessionStatus.ProctorReviewInProgress
          ].includes(data.status)))
          const reviewButton = () => (
            reviewButtonCondition && (
              <>
                {userCanReviewAndCancel ? (
                  <Button
                    variant="contained"
                    fullWidth
                    color="warning"
                    onClick={() => {
                      setSessionToManage(data)
                      setModal("PROCTOR_REPORT")
                    }}
                    sx={{whiteSpace: "nowrap"}}>
                    REVIEW EXAM
                  </Button>
                ) : (
                  <Tooltip title={limitedAccessMessage} arrow>
                    <span>
                      <Button
                        variant="outlined"
                        fullWidth
                        disabled
                        color="warning"
                        endIcon={<LockIcon />}
                        sx={{whiteSpace: "nowrap"}}>
                        REVIEW EXAM
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )
          )
          const viewMoreButton = () => (
            <Link
              to={(props.detailsLink) ? `${props.detailsLink}${data._id}` : ""}
              component={RouterLink}
              underline="none"
              sx={(!props.detailsLink) ? {
                pointerEvents: "none"
              } : undefined}>
              <Button
                variant="outlined"
                fullWidth
                color="secondary"
                disabled={!props.detailsLink}>
                VIEW ALL DETAILS
              </Button>
            </Link>
          )
          const cancelButton = () => (
            userCanReviewAndCancel && !isTesting && (
              <Button
                variant="contained"
                fullWidth
                startIcon={<RefreshIcon/>}
                color="error"
                disabled={cancelSessionLoading}
                sx={{
                  whiteSpace: "nowrap"
                }}
                onClick={() => {
                  setSessionToManage(data)
                  setModal("CANCEL")
                }}>
                CANCEL EXAM SESSION
              </Button>
            )
          )

          return (
            <Box position="relative" width="100%" p={2}>
              {!isCompleted ? (
                <Box display="flex" justifyContent="center">
                  N/A
                </Box>
              ) : (
                <Box minWidth="240px">
                  <Paper
                    sx={{
                      marginBottom: 2,
                      py: 4,
                      px: 1.5,
                      borderRadius: 1.5,
                      display: "flex",
                      justifyContent: "center"
                    }}>
                    <SessionResultProgress
                      size={180}
                      passingScore={data.exam.passingScoreRate}
                      value={data._score}
                    />
                  </Paper>
                  <Box position="relative" width="100%">
                    {(!isTesting && props.type === "examAdmin") ? (
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Button
                          variant="contained"
                          startIcon={<RefreshIcon />}
                          color="error"
                          disabled={data.status === ExamSessionStatus.Canceled}
                          sx={{whiteSpace: "nowrap"}}
                          onClick={() => {
                            setSessionToManage(data)
                            setModal("CANCEL")
                          }}>
                          CANCEL EXAM SESSION
                        </Button>
                        {reviewButton()}
                        {viewMoreButton()}
                      </Box>
                    ) : (!isIndividual && reviewButtonCondition) ? (
                      <Box display="flex" flexDirection="column" gap={1}>
                        {cancelButton()}
                        {reviewButton()}
                      </Box>
                    ) : (!isIndividual && !isTesting && !data.finishDate) ? (
                      <>
                        {userCanReviewAndCancel ? (
                          <Button
                            variant="contained"
                            fullWidth
                            startIcon={<RefreshIcon/>}
                            color="error"
                            disabled={cancelSessionLoading}
                            sx={{
                              whiteSpace: "nowrap"
                            }}
                            onClick={() => {
                              setSessionToManage(data)
                              setModal("CANCEL")
                            }}>
                            CANCEL EXAM SESSION
                          </Button>
                        ) : (
                          <Tooltip title={limitedAccessMessage} arrow>
                            <span>
                              <Button
                                variant="outlined"
                                disabled
                                fullWidth
                                color="error"
                                endIcon={<LockIcon/>}
                                sx={{whiteSpace: "nowrap"}}>
                                CANCEL EXAM SESSION
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      viewMoreButton()
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )
        },
        sort: [isTesting ? "result.score" : "score", {
          defaultOrderDesc: true
        }]
      },
      {
        type: "custom",
        headerText: "Objectives",
        content: data => {
          const isTesting = data.__typename === "TestingSession"
          const objectives = isTesting ? (
            data?.objectiveScoring
          ) : data?.progress?.map(i => ({
            objectiveId: i?.objective?._id,
            title: i?.objective?.title.value,
            score: i?.averageObjectiveScore
          }))


          const sortedObjectives = [...(objectives ?? [])].sort((
            a,
            b
          ) => {
            return parseInt(a.title) - parseInt(b.title)
          })

          return (
            <Box
              position="relative"
              width="100%"
              display="flex"
              flexDirection="column"
              p={2}
              gap={1}>
              {sortedObjectives?.map(i => (
                <Box key={i.objectiveId}>
                  <ScoreLinear value={i.score} label={i.title}/>
                </Box>
              ))}
            </Box>
          )
        }
      },
      !isIndividual && hasRecordings && {
        type: "custom",
        headerText: "Recording",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: (data: ExamSession) => {
          const recording = data.examus?.report?.archive || null

          return (
            <Box display="flex" justifyContent="center">
              {recording ? (
                <Link
                  target="_blank"
                  href={recording}>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      minWidth: "unset",
                      px: 1.5,
                      py: .75
                    }}>
                    <VideocamIcon fontSize="medium"/>
                  </Button>
                </Link>
              ) : (
                "N/A"
              )}
            </Box>
          )
        }
      }
    ]
  }, [sessionsData, props.type, hasRecordings, isTesting, isIndividual, props.detailsLink])


  const handleQueryExamCodes = () => {
    if (props?.defaultExamFilter?.length && props.classroomId) {
      classroomDefaultExamsQueryFetch({
        variables: {
          classroomId: props.classroomId
        }
      }).then(res => {
        setExamCodesData(res.data?.getClassroom?.defaultExams || null)
      })
    } else {
      examCodesQueryFetch().then(res => {
        setExamCodesData(res.data?.exams?.items || null)
      })
    }
  }

  const handleQuerySchoolIds = () => {
    if (props.districtId) {
      districtSchoolIdsQueryFetch({
        variables: {
          districtId: props.districtId
        }
      }).then(res => {
        setSchoolIdsData(res.data?.getDistrict?.schools?.items || null)
      })
    } else {
      schoolIdsQueryFetch().then(res => {
        setSchoolIdsData(res.data?.schools?.items || null)
      })
    }
  }

  const handleQueryTestingGroups = (searchQuery: string) => {
    testingGroupsQueryFetch({
      variables: {
        schoolId,
        search: searchQuery
      }
    }).then(res => {
      setTestingGroupsData(res.data?.testingGroups || null)
    })
  }

  const tableFilters: DataTableFilters = useMemo(() => {
    return {
      main: ["examAdmin", "examDistrict", "testingDistrict", "testingAdmin"].includes(props.type) ? (
        [
          {
            id: "schoolId",
            type: "select-single",
            label: "School",
            loading: districtSchoolIdsQuery.loading || schoolIdsQuery.loading,
            onUpdateOptions: handleQuerySchoolIds,
            options: [
              {label: "All schools", value: ""},
              ...(schoolIdsData || []).filter(i => Boolean(i._id)).map(i => ({
                label: i.name,
                value: i._id
              }))
            ]
          }
        ]
      ) : ([
        {
          id: "examCode",
          type: "select-single",
          label: "Exam Code",
          loading: classroomDefaultExamsQuery.loading || examCodesQuery.loading,
          loadOnce: true,
          onUpdateOptions: handleQueryExamCodes,
          width: 400,
          options: [
            {label: "All exams", value: ""},
            ...(examCodesData || []).filter(i => Boolean(i.code)).map(i => ({
              label: `${i.displayCode} - ${i.displayName.value}`,
              value: i.code
            }))
          ]
        },
        isTesting ? {
          id: "showResult",
          type: "select-single",
          label: "Practice mode",
          options: [
            {label: "All", value: ""},
            {label: "Exam simulator", value: "end"},
            {label: "Practice mode", value: "task"}
          ]
        } : {
          id: "isPassed",
          type: "select-single",
          label: "Exam passed",
          options: [
            {label: "See all", value: ""},
            {label: "Yes", value: "true"},
            {label: "No", value: "false"}
          ]
        }
      ]),
      secondary: [
        props.type === "examAdmin" && {
          id: "voucherUsed",
          type: "toggle",
          label: "Voucher Used"
        }
      ],
      additional: [
        ["examAdmin", "examDistrict"].includes(props.type) && {
          id: "examCode",
          type: "select-single",
          label: "Exam Code",
          loading: classroomDefaultExamsQuery.loading || examCodesQuery.loading,
          loadOnce: true,
          onUpdateOptions: handleQueryExamCodes,
          options: [
            {label: "All exams", value: ""},
            ...(examCodesData || []).filter(i => Boolean(i.code)).map(i => ({
              label: `${i.displayCode} - ${i.displayName.value}`,
              value: i.code
            }))
          ]
        },
        ["examAdmin", "examDistrict"].includes(props.type) && (
          isTesting ? {
            id: "showResult",
            type: "select-single",
            label: "Practice mode",
            options: [
              {label: "All", value: ""},
              {label: "Exam simulator", value: "end"},
              {label: "Practice mode", value: "task"}
            ]
          } : {
            id: "isPassed",
            type: "select-single",
            label: "Exam passed",
            options: [
              {label: "See all", value: ""},
              {label: "Yes", value: "true"},
              {label: "No", value: "false"}
            ]
          }
        ),
        ["exam", "examIndividual"].includes(props.type) && !props.testingGroupId && {
          id: "testingGroupId",
          type: "select-single",
          label: "Testing Group",
          loading: testingGroupsQuery.loading,
          onUpdateOptions: handleQueryTestingGroups,
          options: [
            {label: "All", value: ""},
            ...(testingGroupsData?.items || []).map(i => ({
              label: i.name,
              value: i._id
            }))
          ]
        },
        {
          id: "date",
          type: "date",
          label: "Date"
        },
        isTesting && {
          id: "isPassed",
          type: "select-single",
          label: "Practice Test Passed",
          options: [
            {label: "See all", value: ""},
            {label: "Yes", value: "true"},
            {label: "No", value: "false"}
          ]
        },
        {
          id: "showCanceled",
          type: "toggle",
          label: "Show canceled",
          initialValue: true
        }
      ]
    }
  }, [
    examCodesQuery.loading,
    examCodesData,
    testingGroupsQuery.loading,
    testingGroupsData,
    props.type,
    isTesting,
    props.testingGroupId,
    classroomDefaultExamsQuery.loading,
    districtSchoolIdsQuery.loading,
    schoolIdsQuery.loading,
    schoolIdsData
  ])

  // const tableActionButtons: DataTableActionButtons = useMemo(() => {
  //   return [
  //     (!isTesting && !isIndividual && !["examAdmin", "examDistrict"].includes(props.type)) && {
  //       key: "exportCertificates",
  //       label: "Export Certificates",
  //       icon: <FileDownload/>,
  //       onClick: handleExportCertificates
  //     }
  //   ]
  // }, [isTesting, isIndividual])

  const tableActions: DataTableActions = useMemo(() => {
    return {
      options: {
        label: "Export",
        icon: <FileDownload />,
        variant: "contained"
      },
      items: [
        (!isTesting && !isIndividual) && {
          key: "exportCertificates",
          label: "Export Certificates",
          icon: <FileDownload/>,
          onClick: handleExportCertificates
        },
        (!isIndividual && props.districtId) && {
          key: "exportAllCSV",
          label: "Export All Schools Results",
          icon: <FileDownload/>,
          onClick: (state, selected, clearSelected) => handleExportCSV(true, state, selected, clearSelected)
        },
        !isIndividual && {
          key: "exportCSV",
          label: "Export School-Specific Results",
          icon: <FileDownload/>,
          onClick: (state, selected, clearSelected) => handleExportCSV(false, state, selected, clearSelected)
        },
        !isIndividual && {
          key: "exportCSVObjectivesInfo",
          label: "Export CSV - objectives information",
          icon: <FileDownload/>,
          disabled: !props.classroomId,
          onClick: handleExportCSVObjectivesInfo
        }]
    }
  }, [isIndividual, isTesting])

  const handleQuerySearchOptions = (searchQuery: string) => {
    if (!!classroomId) {
      classroomStudentsIdsQueryFetch({
        variables: {
          classroomId,
          search: searchQuery
        }
      }).then(res => {
        setUsersData(res.data?.users || null)
      })
    } else if (!!schoolId) {
      schoolStudentsIdsQueryFetch({
        variables: {
          schoolId,
          search: searchQuery
        }
      }).then(res => {
        setUsersData(res.data?.users || null)
      })
    } else {
      usersIdsQueryFetch({
        variables: {
          search: searchQuery
        }
      }).then(res => {
        setUsersData(res.data?.users || null)
      })
    }
  }

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    setQuery(state)

    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, filters, searchQuery} = state
    const userId = props.userId || searchQuery
    const schoolId = props.schoolId || filters?.find(i => i.id === "schoolId")?.value
    const districtId = props.districtId
    const classroomId = props.classroomId || filters?.find(i => i.id === "classroomId")?.value
    const testingGroupId = props.testingGroupId || filters?.find(i => i.id === "testingGroupId")?.value
    const showResult = filters?.find(i => i.id === "showResult")?.value
    const isPassed = filters?.find(i => i.id === "isPassed")?.value
    const dateValue = filters?.find(i => i.id === "date")?.value
    const dateStart = dateValue?.[0] ? new Date(dateValue[0]).toISOString() : undefined
    const dateEnd = dateValue?.[1] ? new Date(dateValue[1]).toISOString() : undefined
    const examCode = filters?.find(i => i.id === "examCode")?.value

    const commonVars = {
      take: itemsPerPage,
      offset: itemsPerPage * page,
      sortBy: sort?.key,
      order: sort?.order,
      examCodes: props.defaultExamFilter && !examCode ? props.defaultExamFilter : examCode,
      voucherUsed: filters?.find(i => i.id === "voucherUsed")?.value,
      dateStart,
      dateEnd,
      showCanceled: filters?.find(i => i.id === "showCanceled")?.value,
      isPassed
    }

    const examVars = {
      status: props.statusFilter
    }

    if (userId) {
      if (["examAdmin", "exam", "examIndividual", "examDistrict"].includes(props.type)) {
        examSessionsQueryFetch({
          fetchPolicy,
          variables: {
            ...commonVars,
            ...examVars,
            userId,
            testingGroupId
          }
        }).then(res => {
          setSessionsData(res.data?.examSessions || null)
        })
      } else {
        testingSessionsQueryFetch({
          fetchPolicy,
          variables: {
            ...commonVars,
            showResult,
            userId
          }
        }).then(res => {
          setSessionsData(res.data?.testingSessions || null)
        })
      }
    } else if (["examAdmin", "examDistrict"].includes(props.type) && !schoolId) {
      examSessionsQueryFetch({
        fetchPolicy,
        variables: {
          districtId,
          ...commonVars,
          ...examVars
        }
      }).then(res => {
        setSessionsData(res.data?.examSessions || null)
      })
    } else if (["exam", "examIndividual", "examAdmin", "examDistrict"].includes(props.type)) {
      if (!classroomId) {
        examSessionsQueryFetch({
          fetchPolicy,
          variables: {
            ...commonVars,
            ...examVars,
            schoolId,
            testingGroupId
          }
        }).then(res => {
          setSessionsData(res.data?.examSessions || null)
        })
      } else {
        examSessionsQueryFetch({
          fetchPolicy,
          variables: {
            ...commonVars,
            ...examVars,
            usersFromClassroomId: classroomId,
            testingGroupId
          }
        }).then(res => {
          setSessionsData(res.data?.examSessions || null)
        })
      }
    } else if (["testingAdmin", "testingDistrict"].includes(props.type) && !schoolId) {
      testingSessionsQueryFetch({
        fetchPolicy,
        variables: {
          districtId,
          ...commonVars
        }
      }).then(res => {
        setSessionsData(res.data?.testingSessions || null)
      })
    } else if (["testing", "testingIndividual"].includes(props.type)) {
      if (!classroomId) {
        testingSessionsQueryFetch({
          fetchPolicy,
          variables: {
            ...commonVars,
            showResult,
            schoolId
          }
        }).then(res => {
          setSessionsData(res.data?.testingSessions || null)
        })
      } else {
        testingSessionsQueryFetch({
          fetchPolicy,
          variables: {
            ...commonVars,
            showResult,
            usersFromClassroomId: classroomId
          }
        }).then(res => {
          setSessionsData(res.data?.testingSessions || null)
        })
      }
    }
  }

  function handleResetCertificate(userId: string) {
    resetCertificate({
      variables: {
        userIds: [userId]
      }
    }).then(() => {
      handleQuery(query, {
        refetch: true
      })
    })
  }

  async function handleExportCSV(
    exportAll: boolean,
    ...args: Parameters<DataTableActionClick>
  ) {
    const [state] = args

    const currentSchoolId = schoolId || state.filters?.find(i => i.id === "schoolId")?.value

    setModal("EXPORT_LOADING")

    const dateValue = state.filters?.find(i => i.id === "date")?.value

    const commonVars = {
      districtId: exportAll ? props.districtId : undefined,
      schoolId: (!exportAll && currentSchoolId) ? currentSchoolId : undefined,
      classroomId,
      testingGroupId: props.testingGroupId || state.filters?.find(i => i.id === "testingGroupId")?.value,
      userId: props.userId || state.searchQuery,
      examCodes: state.filters?.find(i => i.id === "examCode")?.value,
      dateStart: dateValue?.[0] ? new Date(dateValue[0]).toISOString() : undefined,
      dateEnd: dateValue?.[1] ? new Date(dateValue[1]).toISOString() : undefined,
      showCanceled: state.filters?.find(i => i.id === "showCanceled")?.value,
      isPassed: state.filters?.find(i => i.id === "isPassed")?.value
    }

    const headers = await getApiCallHeaders()

    if (!isTesting) {
      exportExamSessions({
        variables: {
          ...commonVars,
          voucherUsed: state.filters?.find(i => i.id === "voucherUsed")?.value
        }
      }).then(res => {
        const url = res.data?.exportExamSessions?.url

        return new JsFileDownloader({
          url,
          headers: Object.entries(headers).map(([name, value]) => ({
            name,
            value
          })),
          filename: url.split("?")[0].split("/").pop() || "exam-results.csv"
        })
      }).finally(() => {
        setModal(null)
      })
    } else {
      exportTestingSessions({
        variables: {
          ...commonVars,
          showResult: state.filters?.find(i => i.id === "showResult")?.value
        }
      }).then(res => {
        const url = res.data?.exportTestingSessions?.url

        return new JsFileDownloader({
          url,
          headers: Object.entries(headers).map(([name, value]) => ({
            name,
            value
          })),
          filename: url.split("?")[0].split("/").pop() || "testing-results.csv"
        })
      }).finally(() => {
        setModal(null)
      })
    }
  }

  async function handleExportCSVObjectivesInfo(...args: Parameters<DataTableActionClick>) {
    const [state] = args

    const examCode = state.filters?.find(i => i.id === "examCode")?.value

    if (!examCode) {
      dispatch(notifyUser({
        message: "Choose exam first, please",
        variant: "info"
      }))
    } else {
      const headers = await getApiCallHeaders()

      setModal("EXPORT_LOADING")

      exportSessionsByObjectivesResults({
        variables: {
          sessionType: isTesting ? SessionType.Practice : SessionType.Exam,
          examCode,
          classroomId: props.classroomId
        }
      }).then(res => {
        const url = res.data?.exportSessionsByObjectivesResults?.url

        return new JsFileDownloader({
          url,
          headers: Object.entries(headers).map(([name, value]) => ({
            name,
            value
          })),
          filename: url.split("?")[0].split("/").pop() || "objectives-information.csv"
        })
      }).finally(() => {
        setModal(null)
      })
    }
  }

  function handleExportCertificates(...args: Parameters<DataTableActionClick>) {
    const [, selected, clearSelected] = args

    if (!selected?.length) {
      dispatch(notifyUser({
        message: "Choose sessions first, please",
        variant: "info"
      }))
    } else if (selected.length > 200) {
      dispatch(notifyUser({
        message: "You can select 200 items max",
        variant: "warning"
      }))
    } else {
      setModal("EXPORT_LOADING")

      exportPdfCertificatesQuery({
        variables: {
          sessionIds: selected
        }
      }).then(res => {
        window.open(res.data.exportPdfCertificates.url, "_self")
      }).catch(error => {
        dispatch(handleError(error))
      }).finally(() => {
        setModal(null)
        clearSelected()
      })

      // getPdfCertificatesQuery({
      //   variables: {
      //     sessionIds: selected
      //   }
      // }).then(res => {
      //   window.open(res.data.getPdfCertificates.file, "_self")
      // }).catch(error => {
      //   dispatch(handleError(error))
      // }).finally(() => {
      //   setModal(null)
      //   clearSelected()
      // })
    }
  }

  const handleCancelModal = () => {
    setModal(null)
    setSessionToManage(null)
    setCancelReason("")
  }

  const handleCancelSession = () => {
    handleCancelModal()

    cancelSession({
      variables: {
        examSessionId: sessionToManage._id,
        cancelReason
      }
    }).then(() => {
      if (query) {
        handleQuery(query, {
          refetch: true
        })
      }
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  const handleReportViolation = () => {
    reportExamSessionViolation({
      variables: {
        violationReason: violationNote,
        examSessionId: sessionToManage?._id
      }
    }).then(() => {
      handleCancelModal()

      if (query) {
        handleQuery(query, {
          refetch: true
        })
      }
    }).catch(err => {
      dispatch(handleError(err))

      handleCancelModal()
    })
  }

  const handleMarkReviewed = () => {
    markExamSessionReviewed({
      variables: {
        examSessionId: sessionToManage?._id
      }
    }).then(res => {
      if (sessionToManage?.isPassed) {
        setModal("CERT_GENERATED")
        setGeneratedCert(`${LINKS.certificate}${res.data.markExamSessionAsReviewed.certificateId}`)
      } else {
        dispatch(notifyUser({
          message: customNotifications.EXAM_SESSION_REVIEWED_SUCCESS
        }))

        handleCancelModal()
      }

      if (query) {
        handleQuery(query, {
          refetch: true
        })
      }
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  return (
    <Box>
      <DataTable
        schema={tableSchema}
        rowsPerPageLimit={!isTesting ? 50 : undefined}
        data={sessionsData?.items}
        loading={sessionsLoading || (props.districtId && districtSchoolIdsQuery.loading)}
        error={!!sessionsError}
        itemsTotalCount={sessionsData?.total}
        onQueryUpdate={handleQuery}
        search={!props.userId ? {
          select: {
            options: searchOptions,
            loading: usersLoading,
            onUpdateOptions: handleQuerySearchOptions
          }
        } : undefined}
        lastPage={!sessionsData?.hasMore}
        filters={tableFilters}
        // actionButtons={tableActionButtons}
        actions={tableActions}
      />
      <CustomModal open={modal === "EXPORT_LOADING"}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mx={16} my={8}>
          <CircularProgress />
          <Typography variant="h6" align="center" mt={2}>
            Collecting data...
          </Typography>
        </Box>
      </CustomModal>
      <ConfirmationAlert
        isOpen={modal === "CANCEL" && !!sessionToManage}
        setOpen={handleCancelModal}
        handleConfirm={handleCancelSession}
        handleCancel={handleCancelModal}
        dialogTitle="Cancel Exam Session"
        dialogContentText={
          `Are you sure you want to continue and cancel the current exam session for student ${sessionToManage?.user?.firstName}?`
        }
        dialogContentDynamic
        dialogContent={(
          <Box mt={2}>
            <FormControl fullWidth variant="outlined">
              <TextField
                fullWidth
                placeholder="Reason for exam session cancellation"
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            </FormControl>
          </Box>
        )}
        cancelButton="No"
        confirmButton={{color: "error", text: "Yes, CANCEL SESSION"}}
      />
      <CustomModal open={modal === "PROCTOR_REPORT"} onClose={handleCancelModal}>
        <Box maxWidth="40vw" width="100%">
          <Box pt={3} px={3}>
            <Typography variant="h5">
              Proctoring report
            </Typography>
          </Box>
          <Box px={3} py={2}>
            {sessionToManage?.testingGroup?.proctor?._id !== user?.id && (
              <Typography variant="subtitle1" mb={1}>
                <span style={{fontWeight: "bold"}}>
                  Proctor:
                </span> {sessionToManage?.testingGroup?.proctor?.fullName || "Not found"}
              </Typography>
            )}
            <Typography variant="subtitle1" mb={1}>
              <span style={{fontWeight: "bold"}}>Approver:</span> {`${user?.firstName} ${user?.lastName}`}
            </Typography>
            <Typography variant="subtitle1" mb={1}>
              <span style={{fontWeight: "bold"}}>Student:</span> {
              `${sessionToManage?.user?.firstName || ""} ${sessionToManage?.user?.lastName || ""}`
            }
            </Typography>
            <Typography variant="subtitle1" mb={1}>
              <span style={{fontWeight: "bold"}}>Date:</span> {sessionToManage ? (
              formatDate(sessionToManage.createdAt, "full")
            ) : "Unknown"}
            </Typography>
            <Typography variant="subtitle1" mb={1}>
              <span style={{fontWeight: "bold"}}>Score:</span> {sessionToManage?.score || 0}%
            </Typography>
            {sessionToManage?.examus?.report?.archive && (
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="subtitle1" fontWeight="bold" mr={1}>
                  Recording:
                </Typography>
                <Link
                  target="_blank"
                  href={sessionToManage.examus?.report?.archive}>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      minWidth: "unset",
                      px: 1.5,
                      py: .75
                    }}>
                    <VideocamIcon fontSize="medium" />
                  </Button>
                </Link>
              </Box>
            )}
            <Box mt={2}>
              <Typography variant="subtitle1" mb={1}>
                <span style={{fontWeight: "bold"}}>
                  Proctor report:
                </span>
              </Typography>
              <FormControl fullWidth>
                <RadioGroup
                  defaultValue="NO_VIOLATION"
                  name="post-proctoring-radio-group"
                  value={violation}
                  onChange={(_, value) => setViolation(value as typeof violation)}>
                  <Paper variant="outlined" sx={{width: "100%"}}>
                    <Box pb={1.5} px={1.5}>
                      <FormControlLabel control={<Radio />} label="No Violation encountered" value="NO_VIOLATION" />
                      {!sessionToManage?.examus?.report?.archive ? (
                        <Typography variant="body1" mb={1} px={1} maxWidth="40vw">
                          I confirm that I proctored student {sessionToManage?.user?.firstName} and there were no examination violations during the exam session.
                        </Typography>
                      ) : (
                        <Typography variant="body1" mb={1} px={1} maxWidth="40vw">
                          I confirm that I visualized the recording for the student {sessionToManage?.user?.firstName} and there were no examination violations during the exam session.
                        </Typography>
                      )}
                    </Box>
                  </Paper>
                  <Paper variant="outlined" sx={{width: "100%", mt: 2}}>
                    <Box pb={1.5} px={1.5}>
                      <FormControlLabel control={<Radio />} label="Violation encountered" value="VIOLATION" />
                      <FormControl fullWidth variant="outlined" disabled={violation === "NO_VIOLATION"}>
                        <TextField
                          multiline
                          maxRows={4}
                          fullWidth
                          placeholder="Note*"
                          value={violationNote}
                          onChange={(e) => setViolationNote(e.target.value)}
                          disabled={violation === "NO_VIOLATION"}
                        />
                      </FormControl>
                    </Box>
                  </Paper>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={2} p={3}>
            <Button variant="outlined" onClick={handleCancelModal}>
              Cancel
            </Button>
            {violation === "VIOLATION" ? (
              <Button variant="contained" color="error" onClick={handleReportViolation} disabled={!violationNote}>
                Mark exam as failed
              </Button>
            ) : sessionToManage?.isPassed ? (
              <Button variant="contained" color="success" onClick={handleMarkReviewed}>
                I Agree and generate certificate
              </Button>
            ) : (
              <Button variant="contained" onClick={handleMarkReviewed}>
                Confirm
              </Button>
            )}
          </Box>
        </Box>
      </CustomModal>
      <CustomModal open={modal === "CERT_GENERATED"} onClose={handleCancelModal}>
        <Box maxWidth="40vw" width="100%">
          <Box px={3} pt={4} pb={1} overflow="hidden">
            <Typography variant="subtitle1">
              The certificate for student {`${sessionToManage?.user?.firstName} ${sessionToManage?.user?.lastName}`} was generated successfully.
            </Typography>
            {!!generatedCert && (
              <Typography
                variant="subtitle1"
                mt={2}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis">
                <span style={{fontWeight: "bold", marginRight: theme.spacing(1)}}>
                  Certificate:
                </span>
                <Link
                  target="_blank"
                  href={generatedCert}
                  underline="always"
                  variant="subtitle1">
                  {generatedCert}
                </Link>
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={2} p={3}>
            <Button variant="contained" color="success" onClick={handleCancelModal}>
              Confirm
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  )
}

export default function SessionsTable(props: Props) {
  return (
    <SessionsTableComponent
      key={`${props.type}-${props.classroomId || "0"}-${props.testingGroupId || "0"}`}
      {...props}
    />
  )
}
