import {gql} from "@apollo/client"

export default gql`
  query GetUserVouchers($status: VoucherStatus, $onlyAvailableForUse: Boolean, $schoolId: GraphQLObjectId) {
    me {
      vouchers(status: $status, onlyAvailableForUse: $onlyAvailableForUse, schoolId: $schoolId) {
        _id
        code
        expirationDate
        expired
        exam {
          displayName {
            value
          }
          displayCode
          _id
          code
        }
      }
    }
  }
`
