import {useLazyQuery} from "@apollo/client"
import GET_SCHOOL_CLASSROOMS from "api/apollo/queries/GET_SCHOOL_CLASSROOMS"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableSchema,
  DataTableActionButtons,
  DataTableFilters,
  DataTableQueryUpdate
} from "components/DataTable/types.t"
import {
  Classroom,
  GetSchoolClassroomsQuery,
  GetSchoolClassroomsQueryVariables
} from "generated/graphql"
import React, {useMemo, useState} from "react"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import {
  Box,
  Button,
  Typography,
  Chip,
  Link
} from "@mui/material"
import Plus from "assets/icons/Plus"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import useMainPageLink from "hooks/useMainPageLink"
import {useSelector} from "store"
import {LINKS} from "consts/links"
import {QueryDataType} from "types/typeUtils"
import formatDate from "utils/formatDate"

export default function ManageClassrooms() {
  const navigate = useNavigate()

  const {mainPageLink} = useMainPageLink()

  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)

  const [joinCodeToDisplay, setJoinCodeToDisplay] = useState<string | null>(null)
  const [classroomsData, setClassroomsData] = useState<QueryDataType<Partial<Classroom>>>(null)

  const [classroomsQueryFetch, classroomsQuery] = useLazyQuery<
    GetSchoolClassroomsQuery,
    GetSchoolClassroomsQueryVariables
  >(GET_SCHOOL_CLASSROOMS)

  const classroomsError = useMemo(() => {
    return !classroomsData && (classroomsQuery.error || null)
  }, [classroomsData, classroomsQuery.error])

  const tableSchema: DataTableSchema<Classroom> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Classroom Name",
        fieldName: "name",
        sort: "name"
      },
      {
        type: "custom",
        headerNoWrap: true,
        headerText: "Created On",
        content: data => {
          return "createdAt" in data ? formatDate(data.createdAt) : ""
        },
        sort: "createdAt"
      },
      // {
      //   type: "custom",
      //   headerText: "Programs",
      //   content: data => {
      //     return (
      //       <Box display="flex" alignItems="center">
      //         {data.joinCode}
      //         <Box ml={0.5}>
      //           <Button
      //             size="small"
      //             sx={{minWidth: 0}}
      //             onClick={() => setJoinCodeToDisplay(data.joinCode)}>
      //             <ContentCopyIcon fontSize="small"/>
      //           </Button>
      //         </Box>
      //       </Box>
      //     )
      //   }
      // },
      {
        type: "custom",
        headerText: "Programs",
        headerNoWrap: true,
        contentWrap: "pre-line",
        content: data => {
          return data.defaultExams?.map(i => {
            return i?.displayCode
          }).join("\n")
        }
      },
      // {
      //   type: "custom",
      //   headerText: "Join Code",
      //   content: data => {
      //     return (
      //       <Box display="flex" alignItems="center">
      //         {data.joinCode}
      //         <Box ml={0.5}>
      //           <Button
      //             size="small"
      //             sx={{minWidth: 0}}
      //             onClick={() => setJoinCodeToDisplay(data.joinCode)}>
      //             <ContentCopyIcon fontSize="small"/>
      //           </Button>
      //         </Box>
      //       </Box>
      //     )
      //   }
      // },
      {
        type: "custom",
        headerText: "Enrolled",
        content: data => {
          return String(data.students.total || 0)
        }
      },
      {
        type: "custom",
        headerText: "Pending",
        content: data => {
          return String(((data.waitingList?.length || 0) + (data.invitations?.length || 0)))
        }
      },
      {
        type: "custom",
        headerText: "Teachers",
        headerNoWrap: true,
        contentWrap: "pre-line",
        content: data => {
          return data.teachers.map(i => {
            return i.fullName
          }).join("\n")
        }
      },
      {
        type: "custom",
        headerText: "Status",
        content: data => {
          const statusData: [string, "error" | "warning" | "success"] = data.status === "closed" ? (
            ["Closed", "error"]
          ) : data.isArchived ? ["Archived", "warning"] : ["Open", "success"]

          return (
            <Chip label={statusData[0]} variant="outlined" size="small" color={statusData[1]}/>
          )
        }
      },
      {
        type: "custom",
        headerText: "",
        content: data => (
          <Link to={`${LINKS.classrooms}/${data._id}`} component={RouterLink} underline="none">
            <Button
              variant="outlined"
              color="secondary"
              size="small">
              View More
            </Button>
          </Link>
        )
      }
    ]
  }, [classroomsData, schoolId])

  const tableFilters: DataTableFilters = useMemo(() => {
    return {
      // main: [ // TODO: add Teacher filter
      //   {
      //     id: "teacher",
      //     type: "select-single",
      //     label: "Teacher",
      //     options: [
      //       {label: "All", value: ""}
      //     ]
      //   }
      // ],
      secondary: [
        {
          id: "includeArchived",
          type: "toggle",
          label: "Archived Classrooms",
          initialValue: false
        }
      ]
    }
  }, [])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "createClassroom",
        label: "Create New Classroom",
        icon: <Plus/>,
        variant: "outlined",
        color: "success",
        onClick: handleCreateClassroom
      }
    ]
  }, [])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, filters, searchQuery} = state

    classroomsQueryFetch({
      fetchPolicy,
      variables: {
        schoolId,
        take: itemsPerPage,
        offset: itemsPerPage * page,
        sortBy: sort?.key,
        order: sort?.order,
        includeArchived: filters?.find(i => i.id === "includeArchived")?.value || false,
        search: searchQuery
      }
    }).then(res => {
      setClassroomsData(res.data?.classrooms as any)
    })
  }

  function handleCreateClassroom() {
    navigate(LINKS.createClassroom)
  }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Classrooms"
      title="Classrooms"
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {text: "Classrooms"}
      ]}>
      <Box minHeight="40vh">
        <DataTable
          schema={tableSchema}
          data={classroomsData?.items}
          loading={classroomsQuery.loading}
          error={!!classroomsError}
          emptyDataMessage="No classrooms found"
          itemsTotalCount={classroomsData?.total}
          onQueryUpdate={state => handleQuery(state, {refetch: true})}
          search="Search Classrooms"
          filters={tableFilters}
          actionButtons={tableActionButtons}
          lastPage={!classroomsData?.hasMore}
        />
        <CustomModal
          open={!!joinCodeToDisplay}
          onClose={() => setJoinCodeToDisplay(null)}>
          <Box display="flex" justifyItems="center" alignItems="center" py={8} mx={20}>
            <Typography variant="h1" color="textPrimary">
              {joinCodeToDisplay || " "}
            </Typography>
          </Box>
        </CustomModal>
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
