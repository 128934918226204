import {Box, Button, Theme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cn from "classnames"
import useEditor from "../hooks/useEditor"
import {useEffect} from "react"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import CachedIcon from "@mui/icons-material/Cached"
import SaveIcon from "@mui/icons-material/Save"

export interface ControlledEditorProps {
  vertical?: boolean
  lang?: string
  template?: string
  input?: string
  runButtonLabel?: string
  onRun?: (data: string) => void
  submissionLoading?: boolean
  onRefresh?: (data: string) => void
  onSave?: (data: string) => void
  runDisabled?: boolean
  onChange?: (value: string) => void
  controlsOffset?: boolean
  noControls?: boolean
  inactive?: boolean
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateRows: "calc(100% - 4rem) 4rem",
    overflow: "hidden",
    "&.vertical": {
      gridTemplateRows: "4rem calc(100% - 4rem)"
    },
    "&.noControls": {
      display: "block",
      gridTemplateRows: "unset"
    }
  },
  layout: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "10px",
    "& .cm-focused": {
      outline: "none !important"
    }
  },
  controls: {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #353337",
    transition: ".3s",
    "&.controlsOffset": {
      paddingLeft: "56px",
      transition: ".3s"
    },
    "&.vertical": {
      borderTop: "none",
      borderBottom: "1px solid #353337"
    },
    "& > div": {
      position: "relative",
      height: "100%",
      display: "flex",
      alignItems: "center"
    },
    "& button": {
      borderRadius: 0,
      height: "100%",
      padding: "12px 32px"
    },
    "& .Mui-disabled": {
      color: "rgba(255, 255, 255, 0.4)",
      borderColor: "rgba(255, 255, 255, 0.4)"
    }
  }
}))

export default function ControlledEditor({
  vertical,
  lang = "python",
  template,
  input,
  runButtonLabel = "Compile",
  onRun,
  submissionLoading,
  onRefresh,
  onSave,
  runDisabled,
  onChange,
  controlsOffset,
  noControls,
  inactive
}: ControlledEditorProps) {
  const s = useStyles()

  const initialValue = input || template || "# Your code here"

  const {editorView, editorRef} = useEditor({
    lang,
    input: initialValue,
    onChange,
    editable: !inactive
  })

  useEffect(() => {
    if (editorView.current && input) {
      const {state} = editorView.current
      const currentSelection = state.selection.main

      editorView.current.dispatch({
        changes: {
          from: 0,
          to: editorView.current.state.doc.length,
          insert: input
        },
        selection: {
          anchor: currentSelection.anchor,
          head: currentSelection.head
        }
      })
    }
  }, [input])

  const handleRun = () => {
    if (onRun) {
      const codeJson = editorView.current.state.toJSON()

      onRun(codeJson.doc)
    }
  }

  const handleRefresh = () => {
    if (editorView.current) {
      editorView.current.dispatch({
        changes: {
          from: 0,
          to: editorView.current.state.doc.length,
          insert: initialValue
        }
      })

      if (onRefresh) {
        onRefresh(initialValue)
      }
    }
  }

  const handleSave = () => {
    if (onSave) {
      const codeJson = editorView.current.state.toJSON()

      onSave(codeJson.doc)
    }
  }

  return (
    <Box
      className={cn(s.root, {
        "vertical": vertical,
        "noControls": noControls
      })}>
      <Box
        ref={editorRef}
        className={s.layout}
        sx={{
          gridRow: vertical ? 2 : 1
        }}
      />
      {!noControls && (
        <Box
          className={cn(s.controls, {
            "controlsOffset": controlsOffset,
            "vertical": vertical
          })}
          sx={{
            gridRow: vertical ? 1 : 2
          }}>
          <Box>
            <Button
              color="success"
              sx={{
                "&:disabled": {
                  borderWidth: 2
                }
              }}
              onClick={handleRun}
              startIcon={<PlayArrowIcon/>}
              disabled={runDisabled || !onRun || submissionLoading}>
              {runButtonLabel}
            </Button>
            <Box
              sx={{
                width: "1px",
                height: "100%",
                background: "#353337"
              }}
            />
            <Button
              color="info"
              startIcon={<CachedIcon/>}
              onClick={handleRefresh}>
              Reset
            </Button>
            <Box
              sx={{
                width: "1px",
                height: "100%",
                background: "#353337"
              }}
            />
            {!!onSave && (
              <>
                <Button
                  color="info"
                  startIcon={<SaveIcon/>}
                  onClick={handleSave}>
                  Save
                </Button>
                <Box
                  sx={{
                    width: "1px",
                    height: "100%",
                    background: "#353337"
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
