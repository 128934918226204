import {gql} from "@apollo/client"

export default gql`
  query GetObjectives(
    $examCode: String
    $search: String
    $sortBy: String
    $order: SortOrder = asc
    $offset: Int = 0
    $take: Int = 100
  ) {
    objectives(
      examCode: $examCode
      search: $search
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
    ) {
      items {
        _id
        createdAt
        updatedAt
        title {
          en
          value
        }
        examCode
        objectiveCode
        tasksCount
        weight
      }
      total
      hasMore
    }
  }
`
