import {useLazyQuery} from "@apollo/client"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  Typography
} from "@mui/material"
import GET_TESTING_GROUP from "api/apollo/queries/GET_TESTING_GROUP"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import SessionsTable from "components/SessionsTable"
import {LINKS} from "consts/links"
import copyToClipboard from "copy-to-clipboard"
import {
  GetTestingGroupQuery,
  GetTestingGroupQueryVariables,
  TestingGroup,
  TestingGroupPaymentType,
  TestingGroupStatus
} from "generated/graphql"
import useMainPageLink from "hooks/useMainPageLink"
import General from "pages/TestingGroupDetails/components/General"
import React, {useEffect, useMemo, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {notifyUser} from "store/slices/notifier/notifier"
import {
  updateTestingGroupStatusThunk
} from "store/slices/schoolSlice/schoolSlice"

const tabs: Array<{
  label: string
  value: string
  disabled?: boolean
}> = [
  {label: "General", value: "general"},
  {label: "Exam sessions", value: "examSessions"}
]

export default function TestingGroupDetails() {
  const dispatch = useDispatch()
  const params = useParams()
  const {mainPageLink} = useMainPageLink()

  const schoolId = useSelector(store => store.schoolSlice.currentSchool)

  const [showDisplayJoinCodePopup, setShowDisplayJoinCodePopup] = useState(false)
  const [currentTab, setCurrentTab] = useState("general")
  const [joinCodePopupAnchor, setJoinCodePopupAnchor] = useState<null | HTMLElement>(null)
  const [testingGroup, setTestingGroup] = useState<DeepPartial<TestingGroup> | null>(null)

  const [testingGroupQueryFetch, {loading}] = useLazyQuery<
    GetTestingGroupQuery,
    GetTestingGroupQueryVariables
  >(GET_TESTING_GROUP)

  const joinCodePopupOpened = !!joinCodePopupAnchor

  const isActive = useMemo(() => {
    return testingGroup?.status === TestingGroupStatus.Inprogress
  }, [testingGroup])

  const pageTitle = useMemo(() => testingGroup?.name, [testingGroup])

  useEffect(() => {
    if (params?.groupId) {
      handleTestingGroupsQuery()
    }
  }, [])

  const handleTestingGroupsQuery = async () => {
    await testingGroupQueryFetch({
      fetchPolicy: "network-only",
      variables: {
        testingGroupId: params?.groupId
      }
    }).then(res => {
      setTestingGroup(res.data?.getTestingGroup)
    })
  }

  const handleOpenJoinCodePopup = (
    e: React.MouseEvent<HTMLElement>
  ) => {
    setJoinCodePopupAnchor(e.currentTarget)
  }

  const handleJoinCodePopupSelect = (action?: "display" | "copy") => {
    setJoinCodePopupAnchor(null)

    if (action === "display") {
      setShowDisplayJoinCodePopup(true)
    } else if (action === "copy") {
      copyToClipboard(testingGroup?.code)
      dispatch(notifyUser({
        message: "Copied",
        variant: "success"
      }))
    }
  }

  const handleTestingGroupStatusFunc = async () => {
    await dispatch(updateTestingGroupStatusThunk({
      id: testingGroup._id,
      status: isActive ? TestingGroupStatus.Done : TestingGroupStatus.Inprogress
    }))

    await handleTestingGroupsQuery()

    dispatch(notifyUser({
      message: "GROUP_UPDATE_SUCCESS",
      variant: "success"
    }))
  }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle={pageTitle}
      title={pageTitle}
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {
          path: LINKS.testingGroups,
          text: "Testing Groups"
        },
        {
          text: pageTitle
        }
      ]}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        gap="4rem">
        <Tabs
          indicatorColor="primary"
          onChange={(_e, value) => setCurrentTab(value)}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable">
          {tabs.map(i => (
            <Tab key={i.value} label={i.label} value={i.value} disabled={i.disabled}/>
          ))}
        </Tabs>
        {testingGroup && (
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Box position="relative" display="flex" alignItems="center" gap="1rem" mb={1}>
              {testingGroup?.paymentType === TestingGroupPaymentType.LicenseInventory && (
                <Typography variant="body2" fontWeight="600" color="warningText.main">
                  Available seats: {testingGroup?.license?.examVouchersRemain || 0}
                </Typography>
              )}
              <Typography variant="body2" fontWeight="600">
                Testing group entry code
              </Typography>
              <Box position="relative">
                <Button
                  id="join-code-popup-button"
                  aria-controls={joinCodePopupOpened ? "join-code-popup-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={joinCodePopupOpened ? "true" : undefined}
                  variant="outlined"
                  size="large"
                  endIcon={<KeyboardArrowDownIcon/>}
                  onClick={handleOpenJoinCodePopup}>
                  {testingGroup?.code}
                </Button>
                <Menu
                  id="join-code-popup-menu"
                  anchorEl={joinCodePopupAnchor}
                  MenuListProps={{
                    "aria-labelledby": "join-code-popup-button"
                  }}
                  open={joinCodePopupOpened}
                  onClose={() => handleJoinCodePopupSelect()}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}>
                  {["Display", "Copy"].map((i) => (
                    <MenuItem
                      key={i}
                      onClick={() =>
                        handleJoinCodePopupSelect(
                          i.toLowerCase() as Parameters<typeof handleJoinCodePopupSelect>[0]
                        )}
                      sx={{pr: 11}}>
                      {i}
                    </MenuItem>
                  ))}
                </Menu>
                <Dialog
                  open={showDisplayJoinCodePopup}
                  maxWidth="md"
                  onClose={() => setShowDisplayJoinCodePopup(false)}>
                  <DialogContent>
                    <Box
                      display="flex"
                      justifyItems="center"
                      alignItems="center"
                      py={8}
                      mx={10}>
                      <Typography
                        variant="h1"
                        color="textPrimary"
                        whiteSpace="pre">
                        {testingGroup?.code}
                      </Typography>
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
            </Box>
            <Box
              position="relative"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap="0.5rem"
              mb={1}>
              <Typography variant="body1" fontWeight="600">
                {isActive ? "Active" : "Closed"}
              </Typography>
              <Switch
                checked={isActive}
                color="success"
                edge="start"
                name="direction"
                onChange={handleTestingGroupStatusFunc}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Divider/>
      <Box mt={2}>
        {loading ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={24}>
            <CircularProgress/>
          </Box>
        ) : (
          testingGroup && (
            (currentTab === "general" ? (
              <General
                testingGroup={testingGroup}
                onUpdate={handleTestingGroupsQuery}
              />
            ) : (
              <SessionsTable
                type="exam"
                schoolId={schoolId}
                testingGroupId={testingGroup._id}
                detailsLink="/my-results/exam-details/"
              />
            ))
          )
        )}
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
