import {gql} from "@apollo/client"

export default gql`
  query GetClassroom($classroomId: GraphQLObjectId!) {
    getClassroom(classroomId: $classroomId) {
      _id
      createdAt
      updatedAt
      defaultExamFilter
      defaultExams {
        _id
        code
        displayCode
        displayName {
          value
        }
        enable
        language
        passingScoreRate
        programId
        sessionTimeoutSeconds
        tasksAmount
      }
      isArchived
      joinCode
      name
      status
      assignedTeachers
      waitingList {
        _id
        createdAt
        updatedAt
        status
        failedReason
        decidedAt
        studentId
        student {
          _id
          firstName
          lastName
          email
        }
      }
    }
  }
`
