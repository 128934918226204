import React, {useRef, useState} from "react"
import {Box, Button, ClickAwayListener, Divider, Fade, Paper, Popper, Typography} from "@mui/material"
import KPLogo from "assets/icons/KPLogo"
import Timer from "components/Timer"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import {Instance} from "generated/graphql"
import hexToRgba from "utils/hexToRgba"

interface Props {
  id: string
  name: string
  instance: Instance
  onFinish: () => void
  onTimerChange: (value: number) => void
  expirationDate: string
}

export default function Header({
  id,
  name,
  onFinish,
  instance,
  onTimerChange,
  expirationDate
}: Props) {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box position="relative" width="100%" height="100%">
      <Paper
        variant="outlined"
        sx={{
          height: "100%",
          position: "relative",
          px: 1.25,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderColor: theme => theme.palette.kpNeutralColors.light
        }}
      >
        <Box display="flex" flexDirection="column" gap={.5}>
          <Typography variant="body2">
            Student Name: <span style={{fontWeight: 700}}>{name}</span>
          </Typography>
          <Typography variant="body2">
            Session: <span style={{fontWeight: 700}}>{id}</span>
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate3d(-50%, -50%, 0)",
            display: "flex",
            flexDirection: "column",
            gap: .5
        }}
        >
          <Box display="flex" alignItems="center">
            <KPLogo/>
            <Typography sx={{ml: 1}} fontWeight={700} variant="h6">
              WordPress Practice Lab
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1" fontWeight="700">
              Time left:
            </Typography>
            <Typography
              variant="body1"
              color="primary.light"
              fontWeight="700"
            >
              <Timer
                expirationDate={expirationDate}
                onChange={onTimerChange}
              />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={.5}>
          <Button
            onClick={onFinish}
            color="error"
            variant="square"
            size="small"
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              px: 4
            }}
          >
            Save Progress And Close
          </Button>
          <Button
            ref={anchorRef}
            color="primary"
            variant="square"
            size="small"
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              px: 4
            }}
            endIcon={
              <ArrowDropDownIcon
                sx={{
                  transform: `rotate(${open ? -180 : 0}deg)`,
                  transition: ".3s"
                }}
              />
            }
            onClick={handleClick}
          >
            Login Credentials
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            transition
          >
            {({TransitionProps}) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={300}>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        mt: 1,
                        p: 2,
                        background: "white",
                        border: theme => `1px solid ${hexToRgba(theme.palette.primary.main, .4)}`
                      }}
                    >
                      <Typography variant="body1" sx={{mb: .5}}>
                        Username: <span style={{fontWeight: 700}}>{instance.username}</span>
                      </Typography>
                      <Typography variant="body1">
                        Password: <span style={{fontWeight: 700}}>{instance.password}</span>
                      </Typography>
                      <Divider sx={{my: 1.5}}/>
                      <Typography color="error" variant="body2">
                        Copy credentials into the empty fields
                      </Typography>
                    </Box>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </Box>
      </Paper>
    </Box>
  )
}
