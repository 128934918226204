import {gql} from "@apollo/client"

export default gql`
  mutation ConnectToUserLabSession($userId: GraphQLObjectId!) {
    connectToUserLabSession(userId: $userId) {
      _id
      createdAt
      districtId
      expirationDate
      finishedAt
      instance {
        errorMessage
        password
        status
        wordpressAdminUrl
        userId
        username
      }
      instanceId
      isExpired
      isFinished
      isInstanceReady
      schoolId
      progressOfUser {
        _id
        username
        totalTimeSpentInLabs
      }
    }
  }
`
