import {Box, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material"
import React, {useEffect, useMemo, useState} from "react"
import {
  Classroom,
  GetMyProgressQuery,
  GetMyProgressQueryVariables,
  GetUserVouchersQuery,
  GetUserVouchersQueryVariables, ObjectiveProgress, Voucher
} from "generated/graphql"
import KPLogo from "assets/icons/KPLogo"
import CollapseItem from "./components/CollapseItem"
import GET_MY_PROGRESS from "api/apollo/queries/GET_MY_PROGRESS"
import {useLazyQuery} from "@apollo/client"
import GET_USER_VOUCHERS from "api/apollo/queries/GET_USER_VOUCHERS"
import ExamsList from "components/ExamsList"
import {orderBy} from "lodash"
import ScoreLinear from "components/ScoreLinear"

interface Props {
  data: Partial<Classroom>
}

export default function DetailItem({data}: Props) {
  const [collapsedList, setCollapsedList] = useState<string[]>([])
  const [vouchers, setVouchers] = useState<DeepPartial<Voucher[]>>([])
  const [progressData, setProgressData] = useState<ObjectiveProgress[]>([])
  const [examCode, setExamCode] = useState<string | null>(null)

  const schoolId = useMemo(() => {
    return data?.school?._id || ""
  }, [data])

  const [progressQueryFetch, progressQuery] = useLazyQuery<
    GetMyProgressQuery,
    GetMyProgressQueryVariables
  >(GET_MY_PROGRESS)

  const [vouchersQueryFetch, vouchersQuery] = useLazyQuery<
    GetUserVouchersQuery,
    GetUserVouchersQueryVariables
  >(GET_USER_VOUCHERS)

  const headItemsData = useMemo(() => {
    return [
      {
        title: data?.school?.name,
        icon: data?.school?.logo
      },
      {
        title: data?.name
      },
      {
        title: data?.teachers?.map(i => i?.fullName).join("\n")
      },
      {
        title: data?.district?.name,
        icon: data?.district?.logo,
        withoutBorder: true
      }
    ]
  }, [data])

  const handleCollapseChange = (
    v: string,
    type: "progress" | "vouchers"
  ) => {
    if (type === "progress") {
      !progressQuery.called && handleQueryProgress(examCode)
    } else {
      !vouchersQuery.called &&  handleQueryVouchers()
    }

    setCollapsedList(c => c.includes(v) ? c.filter(i => i !== v) : [...c, v])
  }

  const handleQueryVouchers = () => {
    schoolId && vouchersQueryFetch({
      variables: {
        schoolId
      }
    }).then(res => {
      const vouchers = res.data?.me?.vouchers?.filter(i => i.expirationDate)
      let sortedVouchers = []

      if (vouchers) {
        sortedVouchers = orderBy(vouchers, v => new Date(v.expirationDate), "desc")
      }

      setVouchers(sortedVouchers)
    })
  }

  const handleQueryProgress = (examCode: string) => {
    schoolId && examCode && progressQueryFetch({
      variables: {
        examCode,
        schoolId
      }
    }).then(res => {
      const progress = res.data?.me?.studentProgress?.progress
      let sortedProgress = []

      if (progress) {
        sortedProgress = [...progress].sort((a, b) => {
          return a.objective.title.value.localeCompare(b.objective.title.value, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        })
      }

      setProgressData(sortedProgress)
    })
  }

  const handleChangeExamCode = (code: string) => {
    setExamCode(code)
    handleQueryProgress(code)
  }

  useEffect(() => {
    setExamCode(current => current || (data?.defaultExams[0]?.code || null))
  }, [data])

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {headItemsData.map((i, num) => (
          <Box
            key={i.title + `${num}`}
            position="relative"
            width="25%"
            p={2}
            display="flex"
            alignItems="center"
            justifyContent={"icon" in i ? "space-between" : "center"}>
            <Typography variant="body1" whiteSpace="pre-line">
              {i.title}
            </Typography>
            {i.title && "icon" in i && (
              i.icon ? (
                <Box width={24} height={24} borderRadius="8px" overflow="hidden">
                  <img
                    src={`${process.env.REACT_APP_AWS_S3_URL}${i.icon}`}
                    alt={i.title}
                    className="object-cover"
                  />
                </Box>
              ) : (
                <KPLogo/>
              )
            )}
            {!i?.withoutBorder && (
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "1px",
                  borderRight: theme => `1px solid ${theme.palette.lightGrey.main}`,
                  height: "50%"
                }}
              />
            )}
          </Box>
        ))}
      </Box>
      <Box px={2} pb={2} display="flex" flexDirection="column" gap={2}>
        <Divider/>
        <CollapseItem
          title="Assigned Resources"
          show={collapsedList.includes("Assigned Resources")}
          onChange={(v) => handleCollapseChange(v, "vouchers")}>
          {vouchersQuery.loading ? (
            <Box display="flex" alignItems="center" justifyContent="center" py={5}>
              <CircularProgress color="primary"/>
            </Box>
          ) : vouchers.length ? (
            <ExamsList vouchers={vouchers} variant="secondary"/>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" py={2}>
              <Typography variant="h6" fontWeight={700}>
                You have no assigned resources
              </Typography>
            </Box>
          )}
        </CollapseItem>
        <CollapseItem
          title="Stats"
          show={collapsedList.includes("Stats")}
          onChange={(v) => handleCollapseChange(v, "progress")}>
          {progressQuery.loading && !progressData.length ? (
            <Box display="flex" alignItems="center" justifyContent="center" py={5}>
              <CircularProgress color="primary"/>
            </Box>
          ) : progressData.length ? (
            <Box mt={2}>
              <FormControl>
                <InputLabel id="examCode">
                  Exam code
                </InputLabel>
                <Select
                  labelId="examCode"
                  label="Exam code"
                  sx={{background: "white"}}
                  value={examCode}
                  onChange={({target}) => handleChangeExamCode(target.value)}>
                  {data.defaultExams.map((i) => (
                    <MenuItem key={i._id} value={i.code}>
                      {`${i.displayCode} - ${i.displayName.value}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {progressQuery.loading ? (
                <Box display="flex" alignItems="center" justifyContent="center" py={5}>
                  <CircularProgress color="primary"/>
                </Box>
              ) : (
                <Grid container spacing="12px" mt={1}>
                  {progressData.map((i, num) => (
                    <Grid
                      key={i.objective._id + `${num}`}
                      item
                      xs={12}
                      sm={6}
                      md={3}>
                      <Box
                        bgcolor="white.main"
                        borderRadius="8px"
                        p="12px"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between">
                        <Typography variant="body1" mb="12px">
                          {i.objective.title.value}
                        </Typography>
                        <Box display="flex" flexDirection="column" gap={1}>
                          <ScoreLinear label="Coverage" value={i.answerRate * 100}/>
                          <ScoreLinear label="Score" value={i.successRate * 100}/>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" py={2}>
              <Typography variant="h6" fontWeight={700}>
                You don't have statistics yet
              </Typography>
            </Box>
          )}
        </CollapseItem>
      </Box>
    </Box>
  )
}
