import {useMutation} from "@apollo/client"
import {Box, Button, Paper, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material"
import ARCHIVE_TESTING_GROUP from "api/apollo/mutations/ARCHIVE_TESTING_GROUP"
import DELETE_TESTING_GROUP from "api/apollo/mutations/DELETE_TESTING_GROUP"
import ConfirmationAlert from "components/ConfirmationAlert"
import {LINKS} from "consts/links"
import {
  ArchiveTestingGroupMutation,
  ArchiveTestingGroupMutationVariables,
  DeleteTestingGroupMutation,
  DeleteTestingGroupMutationVariables,
  TestingGroup,
  TestingGroupPaymentType
} from "generated/graphql"
import {useMemo, useState} from "react"
import {useNavigate} from "react-router-dom"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import formatDate from "utils/formatDate"
import getExamCodeName from "utils/getExamCodeName"
import getProctoringTypeName from "utils/getProctoringTypeName"
import getTestingGroupPaymentTypeName from "utils/getTestingGroupPaymentTypeName"

interface Props {
  testingGroup: DeepPartial<TestingGroup>
  onUpdate: () => void
}

export default function General({
  testingGroup,
  onUpdate
}: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showArchiveAlert, setShowArchiveAlert] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  const [archiveTestingGroup, {loading: archiveTestingGroupLoading}] = useMutation<
    ArchiveTestingGroupMutation,
    ArchiveTestingGroupMutationVariables
  >(ARCHIVE_TESTING_GROUP)

  const [deleteTestingGroup, {loading: deleteTestingGroupLoading}] = useMutation<
    DeleteTestingGroupMutation,
    DeleteTestingGroupMutationVariables
  >(DELETE_TESTING_GROUP)

  const fields = useMemo(() => {
    if (!testingGroup) return []

    const getDateFormat = (date) => {
      if (!date) return

      return formatDate(date, "full")
    }

    let details = [
      {
        label: "Testing Group Name",
        value: testingGroup?.name
      },
      {
        label: "Created at:",
        value: getDateFormat(testingGroup.createdAt)
      },
      {
        label: "Exam Code",
        value: `${(testingGroup?.examCode || "").toUpperCase()} - ${getExamCodeName(testingGroup?.examCode)}`
      },
      {
        label: "Testing group entry code",
        value: testingGroup?.code
      },
      {
        label: "Proctoring mode",
        value: getProctoringTypeName(testingGroup?.proctoring)
      },
      {
        label: "Teacher",
        value: testingGroup?.teacher?.fullName || ""
      },
      {
        label: "Proctor",
        value: testingGroup?.proctor?.fullName || ""
      },
      {
        label: "Payment Type",
        value: getTestingGroupPaymentTypeName(testingGroup?.paymentType)
      }
    ]

    if (testingGroup?.paymentType === TestingGroupPaymentType.LicenseInventory) {
      details.push({
        label: "License ID",
        value: testingGroup?.licenseId
      }, {
        label: "License Name",
        value: testingGroup?.license.name
      }, {
        label: "Exam Vouchers",
        value: testingGroup?.license?.examVouchersRemain || 0
      })
    }

    return details
  }, [testingGroup])

  const handleArchive = async () => {
    archiveTestingGroup({
      variables: {
        testingGroupId: testingGroup?._id,
        archived: !testingGroup.isArchived
      }
    }).then(() => {
      onUpdate()
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleDelete = async () => {
    deleteTestingGroup({
      variables: {
        testingGroupId: testingGroup?._id
      }
    }).then(() => {
      navigate(LINKS.testingGroups)
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleEditGroup = (id: string) => {
    navigate(`${LINKS.testingGroups}/${id}/edit`)
  }

  return (
    <Box position="relative">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          Testing Group Details
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleEditGroup(testingGroup?._id)}>
          Edit Testing Group
        </Button>
      </Box>
      <Box mt={2}>
        <Paper>
          <Table>
            <TableBody>
              {fields.map(({label, value}, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      {label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} mt={3}>
        <Button
          variant="outlined"
          size="large"
          color={testingGroup.isArchived ? "warning" : "success"}
          onClick={() => setShowArchiveAlert(true)}
          disabled={archiveTestingGroupLoading}>
          {testingGroup.isArchived ? "Unarchive" : "Archive"}
        </Button>
        <ConfirmationAlert
          isOpen={showArchiveAlert}
          setOpen={setShowArchiveAlert}
          handleConfirm={handleArchive}
          handleCancel={() => setShowArchiveAlert(false)}
          dialogTitle={
            `Are you sure you want to ${testingGroup.isArchived ? "unarchive" : "archive"} testing group ${testingGroup?.name}?`
          }
          cancelButton="Cancel"
          confirmButton={{
            color: testingGroup.isArchived ? "success" : "warning",
            text: testingGroup.isArchived ? "Unarchive" : "Archive"
          }}
        />
        <Button
          variant="outlined"
          size="large"
          color="error"
          onClick={() => setShowDeleteAlert(true)}
          disabled={deleteTestingGroupLoading}>
          Delete
        </Button>
        <ConfirmationAlert
          isOpen={showDeleteAlert}
          setOpen={setShowDeleteAlert}
          handleConfirm={handleDelete}
          handleCancel={() => setShowDeleteAlert(false)}
          dialogTitle={`Are you sure you want to delete testing group ${testingGroup?.name}?`}
          cancelButton="Cancel"
          confirmButton={{color: "error", text: "Delete"}}
        />
      </Box>
    </Box>
  )
}
