import {Box, Collapse, Typography} from "@mui/material"
import React, {PropsWithChildren} from "react"
import {kpPrimaryColors} from "consts"

interface Props {
  title: string
  show: boolean
  onChange: (v: string) => void
}

export default function CollapseItem({
  title,
  show,
  onChange,
  children
}: PropsWithChildren<Props>) {
  return (
    <Box bgcolor={kpPrimaryColors.paper} borderRadius="8px" p="12px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => onChange(title)}
      >
        <Typography variant="body1" fontWeight={700}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          color={show ? "primary" : "secondary"}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            textUnderlineOffset: 3
          }}
        >
          {!show ? "Show" : "Hide"}
        </Typography>
      </Box>
      <Collapse in={show}>
        {children}
      </Collapse>
    </Box>
  )
}
