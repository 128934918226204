import {useEffect, useState} from "react"

interface Props {
  expirationDate: string
  onChange?: (value: number) => void
}

export default function Timer({
  expirationDate,
  onChange
}: Props) {
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    if (!expirationDate) return

    getTimeUntil(expirationDate)

    const interval = setInterval(() => {
      return getTimeUntil(expirationDate)
    }, 1000)

    return () => clearInterval(interval)
  }, [expirationDate])

  const convertMilliseconds = (value: number) => {
    const seconds = Math.floor((value / 1000) % 60)
    const minutes = Math.floor((value / 1000 / 60) % 60)
    const hours = Math.floor((value / (1000 * 60 * 60)) % 24)
    const days = Math.floor(value / (1000 * 60 * 60 * 24))

    return {days, hours, minutes, seconds}
  }

  const format = (value: number) => {
    return value < 10 ? "0" + value : value
  }

  const getTimeUntil = (deadline: string) => {
    if (!deadline) return

    const time = Date.parse(deadline) - Date.parse(new Date().toString())

    if (onChange) {
      onChange(time)
    }

    if (time < 0) {
      setState({days: 0, hours: 0, minutes: 0, seconds: 0})
    } else {
      setState(convertMilliseconds(time))
    }
  }

  return (
    <>
      {format(state.hours)}:{format(state.minutes)}:{format(state.seconds)}
    </>
  )
}
